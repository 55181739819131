/**
 * @module ClickDropdown
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { applyRef } from "core";
import {
  ErrorMessage as FormikErrorMessage,
  Field as FormikField,
} from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { createEvent } from ".";
import ClickTooltip from "../ClickTooltip";
import styles from "./ClickDropdown.module.scss";

/**
 * @description ---------------
 * @param {Object} props - All props of this Component
 * @param {string} props.id - DOM id of the dropdown container <div>. Needed for acessibility.
 * @param {string} props.name - The form name of this input. (used only)
 * @param {} [props.value] - the value of the currently selected element. If undefined, there will be no selected element.
 * @param {Array} [props.options] - Array of objects containing {label, value}.
 *
 * @param {function} [props.onChange] - callback called when a new element is selected. receives the selected option as param.
 * @param {function} [props.onBlur] - callback called when the focus moves out of this component
 *
 * @param {string} [props.label] - A label for this input
 * @param {string} [props.ariaLabel] - The aria label to be displayed when the button and the list is focused
 * @param {string} [props.placeholder] - String rendered as a placeholder
 * @param {string} [props.helpText] - A help text with instructions for the user on how to input the data.
 * @param {object} [props.tooltip] - TODO: A custom tooltip to this input
 *
 * @param {string} [props.className] - CSS classes for the dropdown container
 * @param {bool} [props.noStyle=false] - If true, renders the button without styling (as a <span>)
 * @param {bool} [props.dark=false] - If true, renders the input with light foreground, to be used in dark backgrounds
 *
 * @param {function} [props.selectedItemRender] - Function that receives the selected option object, and must return what will be rendered when the dropdown is closed.
 * @param {function} [props.optionRender] - Function that receives a option object, and must return what will be rendered on the options list for that option.
 *
 * @param {bool} [props.withFormik=false] - If true, use data and callbacks from formik to control this input.
 * @param {function} [props.validate] - Callback used to validate this form field: val => errorMessage.
 *
 * @memberof module:ClickDropdown
 */

const WithFormikDropdown = React.forwardRef(
  ({ name, validate, withFormik, onChange, ...otherProps }, ref) => {
    let inputElement;
    if (withFormik) {
      inputElement = (
        <div>
          <FormikField
            name={name}
            validate={validate}
            render={({ field /*, form*/ }) => (
              <ClickDropdown
                {...otherProps}
                ref={ref}
                {...field}
                onChange={(evt) => {
                  field.onChange(evt);
                  field.onBlur(evt);
                  onChange && onChange(evt);
                }}
              />
            )}
          />
          <div className={styles.errorMessage}>
            <FormikErrorMessage name={name} />
          </div>
        </div>
      );
    } else {
      inputElement = (
        <ClickDropdown
          name={name}
          onChange={onChange}
          {...otherProps}
          ref={ref}
        />
      );
    }
    return inputElement;
  },
);

WithFormikDropdown.displayName = "ClickDropdownWithFormik";

const ClickDropdown = React.forwardRef(
  (
    {
      id,
      value,
      options,
      onChange,
      onBlur,
      label,
      ariaLabel,
      placeholder,
      helpText,
      tooltip,
      className,
      noStyle,
      dark,
      selectedItemRender,
      optionRender,
      name,
    },
    fowardedRef,
  ) => {
    const [expanded, setExpanded] = useState(false);
    const [focusedItem, setFocusedItem] = useState(undefined);
    const [timeoutId, setTimeoutId] = useState(undefined);
    const [filter, setFilter] = useState("");

    const buttonRef = useRef();
    const listRef = useRef();
    const optionsRef = useRef([]);

    // set focus on the list when it expands or collapse
    useEffect(() => {
      if (expanded) {
        listRef.current.focus();
      } else {
        if (document.activeElement === listRef.current) {
          buttonRef.current.focus();
        }
      }
    }, [expanded]);

    // when focused item changes
    useEffect(() => {
      // scroll the element into view automatically if necessary
      if (
        optionsRef.current[focusedItem] &&
        optionsRef.current[focusedItem].scrollIntoView
      )
        optionsRef.current[focusedItem].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
    }, [focusedItem]);

    const expandDropdown = () => {
      setExpanded(true);
      if (focusedItem === undefined) {
        setFocusedItem(0);
      }
    };

    const collapseDropdown = () => setExpanded(false);

    const selectItemAndAndClose = ({ value: newValue }, index) => {
      setFocusedItem(index);
      onChange && onChange(createEvent(name, newValue));
      collapseDropdown();
    };

    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowDown": {
          event.preventDefault();
          event.stopPropagation();
          setFocusedItem((prevState) =>
            focusedItem < options.length - 1 ? prevState + 1 : prevState,
          );
          break;
        }
        case "ArrowUp": {
          event.preventDefault();
          event.stopPropagation();
          setFocusedItem((prevState) =>
            focusedItem > 0 ? prevState - 1 : prevState,
          );
          break;
        }
        case "Enter": {
          event.preventDefault();
          event.stopPropagation();
          selectItemAndAndClose(options[focusedItem], focusedItem);
          break;
        }
        case "Escape": {
          collapseDropdown();
          break;
        }
        case "Home": {
          setFocusedItem(0);
          break;
        }
        case "End": {
          setFocusedItem(options.length - 1);
          break;
        }
        default: {
          // if this is a character key
          // search and select the first matching element
          if (event.key.length === 1) {
            // after 1sec resets the filter typing
            const newTimeoutId = setTimeout(() => {
              setFilter("");
              setTimeoutId(undefined);
            }, 1000);
            const newFilter = filter + event.key.toLowerCase();
            clearTimeout(timeoutId);
            setTimeoutId(newTimeoutId);
            setFilter(newFilter);

            // find the first matching option ignoring case and accent
            for (const index in options) {
              if (
                (typeof options[index].label === "string"
                  ? options[index].label
                  : options[index].plaintextLabel || ""
                )
                  .substring(0, newFilter.length)
                  .localeCompare(newFilter, undefined, {
                    sensitivity: "base",
                  }) === 0
              ) {
                setFocusedItem(parseInt(index));
                break;
              }
            }
          }
          break;
        }
      }
    };

    const handleListBlur = (event) => {
      if (event.relatedTarget !== buttonRef.current) {
        collapseDropdown();
        if (onBlur) {
          onBlur(createEvent(name, value, event));
        }
      }
    };

    // based on the value, gets the full option object
    let selectedOption =
      value !== undefined && value !== null
        ? options.filter(
            (option) =>
              value === option.value ||
              (value.toLowerCase &&
                option.value.toLowerCase &&
                value.toLowerCase() === option.value.toLowerCase()),
          )
        : [];
    // if it is an invalid option, renders a placeholder element
    selectedOption = selectedOption.length > 0 ? selectedOption[0] : undefined;

    const inputElement = (tooltipRef) => (
      <button
        id={`${id}-button`}
        className={classNames({
          [styles.noStyle]: !!noStyle,
          [styles.expanded]: expanded,
        })}
        onClick={expanded ? collapseDropdown : expandDropdown}
        onKeyDown={(event) => {
          if (event.key === "ArrowDown" || event.key === "ArrowUp") {
            event.preventDefault();
            event.stopPropagation();
            if (!expanded) {
              expandDropdown();
            } else {
              listRef.current.focus();
            }
          }
        }}
        onBlur={(event) => {
          if (expanded && event.relatedTarget !== listRef.current) {
            collapseDropdown();
          }
          if (
            event.relatedTarget !== buttonRef.current &&
            event.relatedTarget !== listRef.current
          ) {
            if (onBlur) {
              onBlur(createEvent(name, value, event));
            }
          }
        }}
        ref={(element) => {
          applyRef(element, buttonRef);
          applyRef(element, tooltipRef);
          applyRef(element, fowardedRef);
        }}
        aria-label={
          (ariaLabel || " Escolha uma opção:") +
          `${selectedOption ? selectedOption.label : ""}`
        }
        aria-haspopup="listbox"
        aria-expanded={expanded ? "true" : undefined}
        type="button"
        disabled={!options || options.length === 0}
      >
        {selectedItemRender ? (
          selectedItemRender(selectedOption)
        ) : (
          <>
            <span>
              {!!selectedOption
                ? selectedOption.label
                : placeholder || "Selecione..."}
            </span>
            <span className={styles.icon}>
              <FontAwesomeIcon
                icon={`chevron-${expanded ? "up" : "down"}`}
                aria-hidden="true"
              />
            </span>
          </>
        )}
      </button>
    );

    const inputElementWithTooltip = !!tooltip && (
      <ClickTooltip content={ariaLabel} {...tooltip}>
        {(tooltipRef) => inputElement(tooltipRef)}
      </ClickTooltip>
    );

    return (
      <div
        className={classNames(styles.ClickDropdown, className, {
          [styles.dark]: dark,
        })}
        id={`${id}-wrapper`}
      >
        {label && (
          <label htmlFor={`${id}-button`} className={styles.label}>
            {label}
          </label>
        )}
        {!!helpText && (
          <p id={`${id}-helptext`} className={styles.helpText}>
            {helpText}
          </p>
        )}
        {tooltip ? inputElementWithTooltip : inputElement(undefined)}
        {/* eslint-disable jsx-a11y/aria-activedescendant-has-tabindex */}
        <ul
          className={classNames({ [styles.expanded]: expanded })}
          onKeyDown={handleKeyDown}
          onBlur={handleListBlur}
          ref={listRef}
          tabIndex="-1"
          role="listbox"
          aria-label={ariaLabel || `Escolha uma opção:`}
          aria-activedescendant={
            expanded ? `${id}-option-${focusedItem}` : undefined
          }
        >
          {/* eslint-enable jsx-a11y/aria-activedescendant-has-tabindex */}
          {!!options &&
            options.map((option, index) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <li
                onClick={() => selectItemAndAndClose(option, index)}
                id={`${id}-option-${index}`}
                key={option.value}
                ref={(liRef) => (optionsRef.current[index] = liRef)}
                className={classNames({
                  [styles.focus]:
                    focusedItem !== undefined ? focusedItem === index : false,
                })}
                role="option"
                aria-selected={
                  (focusedItem !== undefined ? focusedItem === index : false)
                    ? "true"
                    : undefined
                }
              >
                {optionRender ? optionRender(option) : option.label}
              </li>
            ))}
        </ul>
      </div>
    );
  },
);

ClickDropdown.displayName = "ClickDropdown";

WithFormikDropdown.defaultProps = {
  withFormik: false,
};

WithFormikDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      plaintextLabel: PropTypes.string,
      value: PropTypes.any.isRequired,
    }),
  ),

  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  tooltip: PropTypes.object,

  className: PropTypes.string,
  noStyle: PropTypes.bool,

  withFormik: PropTypes.bool.isRequired,
  validate: PropTypes.func,

  selectedItemRender: PropTypes.func,
  optionRender: PropTypes.func,
};

export default WithFormikDropdown;
