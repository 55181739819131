//import ClickLink from "components/ClickLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClickButton from "components/ClickButton";
import ClickContainer from "components/ClickContainer";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { BreadcrumbContext } from "views/Breadcrumb.context";
import styles from "./Breadcrumbs.module.scss";

const getState = (state) => state;

const getBreadcrumbs = (breadcrumb, pathname, learningRoadmap) => {
  if (breadcrumb) return breadcrumb;
  let ret = [];
  const pathList = pathname.split("/");
  switch (pathList[1]) {
    case "RoteiroDeAprendizagem": {
      ret.push({
        label: "Roteiro de Aprendizagem",
        url: "/RoteiroDeAprendizagem",
      });
      switch (pathList[2]) {
        case "Listagem": {
          // don't add any breadcrumb.
          break;
        }
        case "Tarefa": {
          if (pathList[3]) {
            if (learningRoadmap.viewRoadmap.allocationFetchStatus === "ready") {
              ret.push({
                label: learningRoadmap.viewRoadmap.title,
                url: `/RoteiroDeAprendizagem/Tarefa/${pathList[3]}`,
              });
            } else {
              ret.push({
                label: "Carregando ... ",
                url: `/RoteiroDeAprendizagem/Tarefa/${pathList[3]}`,
              });
            }
          }

          break;
        }
        default: {
          if (!isNaN(pathList[2])) {
            if (learningRoadmap.viewRoadmap.fetchStatus === "ready") {
              ret.push({
                label: learningRoadmap.viewRoadmap.title,
                url: `/RoteiroDeAprendizagem/${pathList[2]}`,
              });
              if (pathList[3] === "Tarefas") {
                ret.push({
                  label: "Tarefas",
                  url: `/RoteiroDeAprendizagem/${pathList[2]}/Tarefas`,
                });
                if (!isNaN(pathList[4])) {
                  if (
                    learningRoadmap.viewRoadmap.allocationGroupsFetchStatus ===
                      "ready" ||
                    learningRoadmap.viewRoadmap.allocationGroupFetchStatus ===
                      "ready"
                  ) {
                    ret.push({
                      label: learningRoadmap.viewRoadmap.allocationGroup.title,
                      url: `/RoteiroDeAprendizagem/${pathList[2]}/Tarefas/${pathList[4]}`,
                    });
                    if (!isNaN(pathList[5])) {
                      if (
                        learningRoadmap.viewRoadmap.allocationFetchStatus ===
                        "ready"
                      ) {
                        ret.push({
                          label: "Correção",
                          url: `/RoteiroDeAprendizagem/${pathList[2]}/Tarefas/${pathList[4]}/${pathList[5]}`,
                        });
                      } else {
                        ret.push({
                          label: "Carregando ... ",
                          url: `/RoteiroDeAprendizagem/${pathList[2]}/Tarefas/${pathList[4]}/${pathList[5]}`,
                        });
                      }
                    }
                  } else {
                    ret.push({
                      label: "Carregando ... ",
                      url: `/RoteiroDeAprendizagem/${pathList[2]}/Tarefas/${pathList[4]}`,
                    });
                  }
                } else if (pathList[4] === "Nova") {
                  ret.push({
                    label: "Nova tarefa",
                    url: `/RoteiroDeAprendizagem/${pathList[2]}/Tarefas/Nova`,
                  });
                }
              } else if (pathList[3] === "Etapas") {
                ret.push({
                  label: "Etapas",
                  url: `/RoteiroDeAprendizagem/${pathList[2]}/Etapas`,
                });
              }
            } else {
              ret.push({
                label: "Carregando ... ",
                url: `/RoteiroDeAprendizagem/${pathList[2]}`,
              });
            }
          }
          break;
        }
      }
      break;
    }
    default: {
      break;
    }
  }
  if (ret.length > 0) ret[ret.length - 1].current = true;
  return ret;
};

const mapStateToProps = createSelector([getState], (state) => ({
  learningRoadmap: state.view.learningRoadmap,
}));

const Breadcrumbs = ({ learningRoadmap }) => {
  let { breadcrumb: contextBreadcrumb } = useContext(BreadcrumbContext);
  const [breadcrumb, setBreadcrumb] = useState(contextBreadcrumb);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!contextBreadcrumb)
      setBreadcrumb(
        getBreadcrumbs(contextBreadcrumb, pathname, learningRoadmap),
      );
    else setBreadcrumb(contextBreadcrumb);
  }, [contextBreadcrumb, learningRoadmap, pathname]);

  return (
    <ClickContainer className={styles.breadcrumbs}>
      <nav aria-label="Breadcrumbs">
        <ol>
          {breadcrumb?.map(({ label, url, current }, i) => (
            <li key={url}>
              {i !== 0 && (
                <FontAwesomeIcon
                  icon="angle-right"
                  className={styles.separator}
                />
              )}
              <ClickButton
                background="dark"
                size="small"
                link={url}
                aria-current={current ? "page" : undefined}
              >
                {label}
              </ClickButton>
            </li>
          ))}
        </ol>
      </nav>
    </ClickContainer>
  );
};
export default connect(mapStateToProps)(Breadcrumbs);
