import classnames from "classnames";
import ClickFilterableTable from "components/ClickFilterableTable";
import Panel from "components/Panel";
import { queryToObj } from "core/";
import { sanitizeWord } from "core/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { BreadcrumbContext } from "views/Breadcrumb.context";
import PortalTemplate from "views/Template";
import styles from "./ActivitiesReports.module.scss";
import useActivitiesReportsModel from "./models";

const SchoolsReport = () => {
  const [activities, setActivities] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { getSchoolsReport } = useActivitiesReportsModel();
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [filteredData, setFilteredData] = useState();
  const { search } = useLocation();
  const { school: schoolName, orderBy, orderDirection } = queryToObj(search);

  const reportData = useMemo(() => {
    if (!activities) return;
    return Object.entries(activities)
      .map(([id, data]) => ({ id, ...data }))
      .sort(({ name: n1 }, { name: n2 }) => n1.localeCompare(n2));
  }, [activities]);

  useEffect(() => {
    if (reportData) {
      let filtered = [...reportData];
      if (schoolName) {
        filtered = filtered.filter((school) =>
          sanitizeWord(school.name.toUpperCase()).includes(
            sanitizeWord(schoolName.toUpperCase())
          )
        );
      }
      if (orderBy) {
        const multiplier = orderDirection === "asc" ? 1 : -1;
        filtered = filtered.sort((school1, school2) => {
          if (orderBy === "school") {
            return multiplier * school1.name.localeCompare(school2.name);
          }
          if (!school1[orderBy] && !school2[orderBy]) {
            return 0;
          }
          if (!school1[orderBy]) {
            return -multiplier;
          }
          return school1[orderBy] < school2[orderBy] ? -multiplier : multiplier;
        });
      }
      setFilteredData(filtered);
    }
  }, [schoolName, reportData, orderBy, orderDirection]);

  useEffect(() => {
    setBreadcrumb([
      {
        label: "Escolas",
        url: `Relatorios/`,
        current: true,
      },
    ]);
    return () => setBreadcrumb(undefined);
  }, [setBreadcrumb]);

  useEffect(() => {
    setIsLoading(true);
    getSchoolsReport()
      .then((data) => setActivities(data))
      .finally(() => setIsLoading(false));
    return () => setActivities(undefined);
  }, [getSchoolsReport]);

  return (
    <PortalTemplate>
      <main id="mainContent">
        <section className={classnames("content", styles.header)}>
          <h1>Relatório de atividades</h1>
        </section>
        <Panel>
          <div className="content">
            <ClickFilterableTable
              className="content"
              filterProps={{
                id: "schoolsReportsFilter",
                initialValues: {
                  school: "",
                },
                mainInput: {
                  id: "schoolNameReportsInput",
                  name: "school",
                  placeholder: "Buscar por escola...",
                  withFormik: true,
                },
              }}
              tableProps={{
                isLoading,
                data: filteredData || [],
                emptyRender: () => "Nenhuma escola encontrada.",
                initialValues: {
                  school: "",
                },
                columns: [
                  {
                    label: "Escola",
                    render: (row) => row.name,
                    size: 5,
                    link: (row) => `/Relatorios/${row.id}`,
                    sort: "school",
                  },
                  {
                    label: "Total de atividades",
                    render: (row) => row.total || 0,
                    size: 2,
                    align: "end",
                    sort: "total",
                  },
                  {
                    label: <span title="Exercícios Online">EO</span>,
                    render: (row) => row["exercicios_online"] || "-",
                    align: "end",
                    sort: "exercicios_online",
                  },
                  {
                    label: <span title="Roteiro de Aprendizagem">RA</span>,
                    render: (row) => row["learning_roadmap"] || "-",
                    align: "end",
                    sort: "learning_roadmap",
                  },
                  {
                    label: <span title="WebQuests">WQ</span>,
                    render: (row) => row.webquest || "-",
                    align: "end",
                    sort: "webquest",
                  },
                  {
                    label: <span title="Palavras Cruzadas">PC</span>,
                    render: (row) => row.crossword || "-",
                    align: "end",
                    sort: "crossword",
                  },
                  {
                    label: <span title="Editor de Jornal">EJ</span>,
                    render: (row) => row.newspaper || "-",
                    align: "end",
                    sort: "newspaper",
                  },
                  {
                    label: <span title="Escrita Colaborativa">EC</span>,
                    render: (row) => row["collab_writing"] || "-",
                    align: "end",
                    sort: "collab_writing",
                  },
                ],
              }}
            />
          </div>
        </Panel>
      </main>
    </PortalTemplate>
  );
};

export default SchoolsReport;
