/**
 * @file
 * Main file of Clickideia Portal Web App
 */

import "normalize.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "wicg-inert";
import AppCore from "./core";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
// import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <StrictMode>{AppCore}</StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: (registration: any) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event: any) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});
// reportWebVitals(console.log);
