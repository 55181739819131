import A11yMenu from "./A11yMenu";
import Breadcrumbs from "./Breadcrumbs";
import styles from "./Header.module.scss";
import Menu from "./Menu";

const Header = () => {
  return (
    <header className={styles.header}>
      <A11yMenu />
      <Menu />
      <Breadcrumbs />
    </header>
  );
};

export default Header;
