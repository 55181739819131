import { useContext, useState } from "react";
import { ProjectBulkUpdateContext } from "./ProjectBulkUpdate.context";

const FileUploader = () => {
  const [file, setFile] = useState({});
  const [hasFile, setHasFile] = useState(false);
  const { loadSSData: loadSpreadSheetData } = useContext(
    ProjectBulkUpdateContext,
  );

  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setHasFile(true);
      setFile(files[0]);
    } else {
      setHasFile(false);
    }
  };

  const handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const isBinaryString = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const binaryString = e.target.result;
      loadSpreadSheetData(binaryString, isBinaryString ? "binary" : "array");
      return;
    };

    if (isBinaryString) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <input
        type="file"
        className="form-control"
        id="file"
        accept=".xlsx"
        onChange={handleChange}
      />
      <br />

      {hasFile ? (
        <input type="submit" value="Processar arquivo" onClick={handleFile} />
      ) : (
        ""
      )}
    </>
  );
};

export default FileUploader;
