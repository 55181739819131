/**
 * @module ClickCombobox
 */

import classnames from "classnames";
import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import ClickTooltip from "components/ClickTooltip";
import { Manager, Popper, Reference } from "react-popper";
import { createEvent } from ".";
import styles from "./ClickCombobox.module.scss";
import {
  Field as FormikField,
  ErrorMessage as FormikErrorMessage
} from "formik";
import PropTypes from "prop-types";
import { applyRef } from "core";

/**
 * @description Accessible component of combobox
 * @see {@link https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.1pattern/listbox-combo.html}
 *
 * @param {Object} props - All props of this Component
 * @param {string} props.id - DOM id of the combobox container <div>. Needed for acessibility.
 * @param {string} props.name - The form name of this input. (used only)
 * @param {} [props.value] - the value of the currently selected element. If undefined, there will be no selected element.
 * @param {Array} [props.options] - Array of objects containing {label, value}.
 *
 * @param {function} [props.onChange] - callback called when a new element is selected. receives the selected option as param.
 * @param {function} [props.onBlur] - callback called when the focus moves out of this component
 *
 * @param {string} [props.label] - A label for this input
 * @param {string} [props.ariaLabel] - The aria label to be displayed when the button and the list is focused
 * @param {string} [props.placeholder] - String rendered as a placeholder
 * @param {string} [props.helpText] - A help text with instructions for the user on how to input the data.
 * @param {object} [props.tooltip] - TODO: A custom tooltip to this input
 *
 * @param {string} [props.className] - CSS classes for the combobox container
 * @param {bool} [props.dark=false] - If true, renders the input with light foreground, to be used in dark backgrounds
 *
 * @param {function} [props.optionRender] - Function that receives a option object, and must return what will be rendered on the options list for that option.
 *
 * @param {bool} [props.withFormik=false] - If true, use data and callbacks from formik to control this input.
 * @param {function} [props.validate] - Callback used to validate this form field: val => errorMessage.
 *
 * @memberof module:ClickCombobox
 */

const WithFormikCombobox = forwardRef(
  ({ name, validate, withFormik, onChange, ...otherProps }, ref) => {
    let inputElement;
    if (withFormik) {
      inputElement = (
        <div>
          <FormikField
            name={name}
            validate={validate}
            render={({ field /*, form */ }) => (
              <ClickCombobox
                {...otherProps}
                ref={ref}
                {...field}
                onChange={evt => {
                  field.onChange(evt);
                  onChange && onChange(evt);
                }}
              />
            )}
          />
          <div className={styles.errorMessage}>
            <FormikErrorMessage name={name} />
          </div>
        </div>
      );
    } else {
      inputElement = (
        <ClickCombobox
          name={name}
          onChange={onChange}
          {...otherProps}
          ref={ref}
        />
      );
    }
    return inputElement;
  }
);

const ClickCombobox = forwardRef(
  (
    {
      className,
      onChange,
      onBlur,
      placeholder,
      ariaLabel,
      name,
      value,
      dark,
      label,
      helpText,
      id,
      options,
      optionRender,
      tooltip
    },
    forwardRef
  ) => {
    const [listExpanded, setListExpanded] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [selectedOption, selectOption] = useState(null);
    const [availableOptions, setAvailableOptions] = useState([]);
    const [listboxWidth, setListboxWidth] = useState(0);

    const comboRef = useRef();
    const inputRef = useRef(forwardRef);
    const optionsRef = useRef([]);

    useEffect(() => {
      const values = options.map(({ value }) => value);
      const valueIndex = values.indexOf(value);
      if (valueIndex !== -1) {
        setInputValue(options[valueIndex].label);
        selectOption(valueIndex);
      } else {
        setInputValue("");
        selectOption(null);
      }
    }, [options, value]);

    useEffect(() => {
      const newAvailableOptions = [];
      if (inputValue) {
        for (const index in options) {
          if (
            options[index].label
              .substring(0, inputValue.length)
              .localeCompare(inputValue, "pt-br", {
                sensitivity: "base"
              }) === 0
          ) {
            newAvailableOptions.push(parseInt(index));
          }
        }
      }
      setAvailableOptions(newAvailableOptions);
    }, [inputValue, options]);

    const handleKeyDown = evt => {
      let prevSelectedIndex = null;
      if (availableOptions.length > 0) {
        prevSelectedIndex = availableOptions.indexOf(selectedOption);
      }

      switch (evt.key) {
        case "ArrowDown": {
          evt.preventDefault();
          evt.stopPropagation();
          if (prevSelectedIndex !== null) {
            selectOption(
              availableOptions[
                (prevSelectedIndex + 1) % availableOptions.length
              ]
            );
          }
          setListExpanded(true);
          break;
        }
        case "ArrowUp": {
          evt.preventDefault();
          evt.stopPropagation();
          if (prevSelectedIndex !== null) {
            selectOption(
              availableOptions[
                (prevSelectedIndex - 1) % availableOptions.length
              ]
            );
          }
          setListExpanded(true);
          break;
        }
        case "Enter": {
          evt.preventDefault();
          evt.stopPropagation();
          if (listExpanded) {
            setInputValue(options[selectedOption].label);
            setListExpanded(false);
          }
          break;
        }
        case "Escape": {
          evt.preventDefault();
          evt.stopPropagation();
          setInputValue("");
          selectOption(null);
          setListExpanded(false);
          break;
        }
        default: {
          break;
        }
      }
    };

    useLayoutEffect(() => {
      if (inputRef.current && inputRef.current.offsetWidth) {
        setListboxWidth(inputRef.current.offsetWidth);
      }
    }, []);

    const handleBlur = evt => {
      if (!evt.relatedTarget || evt.relatedTarget.dataset.comboboxId !== id) {
        setListExpanded(false);
        if (selectedOption !== null) {
          const { label: optionLabel, value: optionValue } = options[
            selectedOption
          ];
          setInputValue(optionLabel);
          onChange && onChange(createEvent(name, optionValue));
        } else {
          setInputValue("");
          onChange && onChange(createEvent(name, null));
        }
        onBlur && onBlur(evt);
      }
    };

    const handleChange = evt => {
      const newInputValue = evt.target.value;
      let newAvailableOptions = [];
      if (newInputValue) {
        for (const index in options) {
          if (
            options[index].label
              .substring(0, newInputValue.length)
              .localeCompare(newInputValue, "pt-br", {
                sensitivity: "base"
              }) === 0
          ) {
            newAvailableOptions.push(parseInt(index));
          }
        }
      }
      if (newAvailableOptions.length > 0) {
        setListExpanded(true);
        selectOption(newAvailableOptions[0]);
      } else {
        setListExpanded(false);
        selectOption(null);
      }
      setAvailableOptions(newAvailableOptions);
      setInputValue(newInputValue);
    };

    useEffect(() => {
      if (selectedOption !== null && listExpanded) {
        optionsRef.current[selectedOption].scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    }, [listExpanded, selectedOption]);

    let inputElement = tooltipRef => (
      <Manager>
        <div
          className={styles.wrapper}
          /* Combobox has different rules in ARIA 1.0 and 1.1
           *  This one follows ARIA 1.1 specifications */
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          aria-haspopup="listbox"
          aria-expanded={listExpanded ? "true" : "false"}
          aria-owns={`${id}-listbox`}
          ref={comboRef}
          onBlur={handleBlur}
        >
          <Reference>
            {({ ref }) => (
              <div ref={ref}>
                <input
                  id={id}
                  name={name}
                  value={inputValue}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  type="text"
                  placeholder={placeholder}
                  aria-label={ariaLabel}
                  className={styles.textInput}
                  aria-describedby={!!helpText ? `${id}-helptext` : undefined}
                  aria-controls={`${id}-listbox`}
                  aria-activedescendant={
                    selectedOption === null || !listExpanded
                      ? undefined
                      : `${id}-option-${selectedOption}`
                  }
                  ref={element => {
                    applyRef(element, inputRef);
                    applyRef(element, tooltipRef);
                  }}
                  spellCheck={false}
                  /* this should avoid browser to popup text input suggestions list
                   *  over combobox listbox.
                   * (autoComplete IS DIFFERENT OF aria-autocomplete)
                   */
                  autoComplete="off"
                  /** @todo implement aria-autocomplete="both" */
                  aria-autocomplete="list"
                />
              </div>
            )}
          </Reference>

          {listExpanded && availableOptions.length > 0 && (
            <Popper placement="bottom-start">
              {({ ref, style, placement, scheduleUpdate }) =>
                scheduleUpdate() || (
                  <ul
                    className={classnames(styles.listbox, styles.expanded)}
                    ref={ref}
                    style={{ ...style, width: listboxWidth }}
                    data-placement={placement}
                    role="listbox"
                    id={`${id}-listbox`}
                  >
                    {options
                      .map((option, index) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <li
                          id={`${id}-option-${index}`}
                          key={option.value}
                          role="option"
                          aria-selected={index === selectedOption}
                          /* This data- attribute is used to prevent onBlur if textbox
                             was blured because option was clicked. Option is removed from 
                             DOM when clicked, so combobox won't contain it anymore */
                          data-combobox-id={id}
                          className={classnames({
                            [styles.focus]: index === selectedOption
                          })}
                          tabIndex={-1}
                          ref={el => (optionsRef.current[index] = el)}
                          onClick={evt => {
                            evt.preventDefault();
                            selectOption(index);
                            setInputValue(option.label);
                            onChange(createEvent(name, option.value));
                            setListExpanded(false);
                          }}
                        >
                          {optionRender ? optionRender(option) : option.label}
                        </li>
                      ))
                      .filter(
                        (_, index) => availableOptions.indexOf(index) !== -1
                      )}
                  </ul>
                )
              }
            </Popper>
          )}
        </div>
      </Manager>
    );
    return (
      <div
        className={classnames(styles.container, className, {
          [styles.dark]: dark
        })}
      >
        {!!label && <label htmlFor={id}>{label}</label>}
        {!!helpText && (
          <p id={`${id}-helptext`} className={styles.helpText}>
            {helpText}
          </p>
        )}
        {tooltip || (ariaLabel && !label) ? (
          <ClickTooltip content={ariaLabel} {...tooltip}>
            {tooltipRef => inputElement(tooltipRef)}
          </ClickTooltip>
        ) : (
          inputElement(undefined)
        )}
      </div>
    );
  }
);

WithFormikCombobox.defaultProps = {
  withFormik: false
};

WithFormikCombobox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.any.isRequired
    })
  ),

  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  tooltip: PropTypes.object,

  className: PropTypes.string,

  withFormik: PropTypes.bool.isRequired,
  validate: PropTypes.func,

  optionRender: PropTypes.func
};

export default WithFormikCombobox;
