/**
 * @module ClickCheckbox
 */
import classnames from "classnames";
import ClickTooltip from "components/ClickTooltip";
import "focus-visible";
import {
  ErrorMessage as FormikErrorMessage,
  Field as FormikField,
} from "formik";
import React, { forwardRef } from "react";
import styles from "./ClickCheckbox.module.scss";
import PropTypes from "prop-types";

/**
 * @description Component used to render checkbox and on/off swichs
 * @param  {Object} props All props of the component
 * @param  {string} props.id Id of the input, to link the input to the
 * @param  {string} props.className Additional classes for mainly styling
 * @param  {name} props.name The HTML name for the form field
 * @param  {string} props.label String to label the form field
 * @param  {string} props.title Title for the component. Main name for the field
 * @param  {string} props.helpText Short text informing the purpose of the form field
 * @param  {bool} props.dark Change the colors scheme for the dark background if it's true
 * @param  {bool} props.isSwitch Change common checkbox for an on/off switch
 * @param  {function} props.onBlur Handles the blur events of the component
 *
 * @param  {function} props.onChange Handles changes events of the component
 *
 * @param  {bool} props.withFormik If true integrates the component with Formiks components. The use is documented in Formik website
 * @param  {Object} props.tooltip Secundary help text rendered as tooltip. The use is documented in ClickTooltip component
 *
 * @memberof module:ClickCheckbox
 *
 **/

const ClickCheckbox = ({ name, withFormik, ...otherProps }) => {
  return !withFormik ? (
    <RenderWithTooltip name={name} {...otherProps} />
  ) : (
    <>
      <FormikField
        name={name}
        render={({ field /*, form */ }) => (
          <RenderWithTooltip
            name={name}
            {...otherProps}
            {...field}
            checked={field.value}
          />
        )}
      />
      <div className={styles.errorMessage}>
        <FormikErrorMessage name={name} />
      </div>
    </>
  );
};

const RenderWithTooltip = ({ tooltip, ariaLabel, isSwitch, ...props }) => {
  if (!tooltip) {
    return (
      <RenderClickCheckbox
        isSwitch={isSwitch}
        ariaLabel={ariaLabel}
        {...props}
      />
    );
  }

  return (
    <ClickTooltip
      content={ariaLabel}
      placement={!isSwitch ? "right" : undefined}
      {...tooltip}
    >
      {(ref) => (
        <RenderClickCheckbox
          ariaLabel={ariaLabel}
          isSwitch={isSwitch}
          {...props}
          ref={ref}
        />
      )}
    </ClickTooltip>
  );
};

const RenderClickCheckbox = forwardRef(
  (
    {
      id,
      className,
      name,
      label,
      ariaLabel,
      checked,
      defaultChecked,
      dark,
      title,
      helpText,
      isSwitch,
      onBlur,
      onChange,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={classnames(
          { [styles.switch]: isSwitch },
          { [styles.dark]: dark },
          styles.container,
          className,
        )}
        {...props}
        id={id}
      >
        {!!title && <p className={styles.title}>{title}</p>}
        {!!helpText && <p className={styles.helpText}>{helpText}</p>}
        <div className={styles.checkable}>
          <input
            type="checkbox"
            id={`${id}-chk-in`}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            checked={checked}
            defaultChecked={defaultChecked}
          />

          <label
            ref={ref}
            id={`${id}-chk-lb`}
            aria-label={ariaLabel}
            htmlFor={`${id}-chk-in`}
          >
            {!isSwitch ? (
              label
            ) : (
              <>
                <div className={styles.switch} />
                <p>{label}</p>
              </>
            )}
          </label>
        </div>
      </div>
    );
  },
);

ClickCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: labelType,
  ariaLabel: ariaLabelType,
  className: PropTypes.string,
  helpText: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  dark: PropTypes.bool,
  isSwitch: PropTypes.bool,
  withFormik: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  tooltip: PropTypes.object,
};

function ariaLabelType(props, _, componentName) {
  if (props.isSwitch && !props.ariaLabel)
    return new Error(
      `Missing prop. The ${componentName} needs either not to be switch or have an ariaLabel`,
    );
  return null;
}

function labelType(props, _, componentName) {
  if (!props.isSwitch && !props.label)
    return new Error(
      `Missing prop. The ${componentName} needs either not to be a switch or have a label`,
    );
  return null;
}

export default ClickCheckbox;
