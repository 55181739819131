import CKEditorInspector from "@ckeditor/ckeditor5-inspector";
import CKEditor from "@ckeditor/ckeditor5-react";
import CKEDITOR from "click-editor";
import { FeedbackContext } from "components/Feedback";
import { api } from "core";
import {
  ErrorMessage as FormikErrorMessage,
  Field as FormikField,
} from "formik";
import PropTypes from "prop-types";
import React, { forwardRef, useCallback, useContext } from "react";
import { createEvent } from ".";
import styles from "./ClickRichText.module.scss";

const WithFormikRichText = forwardRef(
  ({ name, validate, withFormik, onChange = () => {}, ...otherProps }, ref) => {
    let inputElement;
    if (withFormik) {
      inputElement = (
        <FormikField
          name={name}
          validate={validate}
          render={({ field /*, form*/ }) => (
            <ClickRichText
              {...otherProps}
              ref={ref}
              {...field}
              onChange={(evt) => {
                field.onChange(evt);
                field.onBlur(evt);
                onChange(evt);
              }}
              errorMessage={
                <FormikErrorMessage name={name} render={(message) => message} />
              }
            />
          )}
        />
      );
    } else {
      inputElement = (
        <ClickRichText
          name={name}
          onChange={onChange}
          {...otherProps}
          ref={ref}
        />
      );
    }
    return inputElement;
  },
);

const ClickRichText = forwardRef(
  (
    {
      id,
      label,
      name,
      onChange,
      value,
      className,
      errorMessage,
      editorName,
      config,
      disabled,
      showInspector,
      onFocus,
      onInit,
      onEditorChange,
    },
    ref,
  ) => {
    const addFeedback = useContext(FeedbackContext).addFeedback;

    const customUpload = useCallback(
      (image) => {
        let error = "";
        if (!image) {
          error = "Nenhuma imagem selecionada";
          return Promise.reject(error);
        }
        return api
          .uploadImage(image)
          .then((res) => res.data)
          .catch((err) => {
            console.error(err);
            if (err.response === undefined) {
              addFeedback(
                "Não foi possível inserir a imagem. Verifique a conexão com a internet e tente novamente mais tarde.",
                "error",
              );
            } else if (err.response.status === 413) {
              addFeedback(
                "O tamanho de imagem é muito grande e não foi possível inserí-la.",
                "error",
              );
            } else if (err.response.status === 422) {
              addFeedback(
                "O arquivo não é uma imagem ou está corrompido.",
                "error",
              );
            } else {
              addFeedback(
                "Não foi possível inserir a imagem. Tente novamente mais tarde.",
                "error",
              );
            }
            throw err;
          });
      },
      [addFeedback],
    );

    React.useEffect(() => {
      if (showInspector) {
        console.table(
          CKEDITOR[editorName].builtinPlugins.map((plugin) => [
            plugin,
            plugin.pluginName,
          ]),
        );
      }
    }, [editorName, showInspector]);

    return (
      <div className={className}>
        {label && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        <CKEditor
          id={id}
          disabled={disabled}
          ref={ref}
          editor={CKEDITOR[editorName]}
          data={value}
          onInit={(editor) => {
            if (process.env.NODE_ENV === "development" && showInspector) {
              CKEditorInspector.attach("click-editor", editor);
            }
            onInit(editor);
          }}
          config={{
            customUpload,
            ...config,
          }}
          onFocus={(_evt, editor) =>
            onFocus(createEvent(name, editor.getData()), editor)
          }
          onChange={(_evt, editor) =>
            onEditorChange(createEvent(name, editor.getData()), editor)
          }
          onBlur={(_evt, editor) => {
            /** onChange(...) call was moved to blur listener because updating it
             * on every change was causing multiple calls to imgs srcs.
             * @todo maybe there's a better way to do it  */
            onChange(createEvent(name, editor.getData()), editor);
          }}
        />
        <span className={styles.error}>{errorMessage}</span>
      </div>
    );
  },
);

WithFormikRichText.defaultProps = {
  editorName: "ClassicEditor",
  showInspector: false,
  onChange: () => undefined,
  onFocus: () => undefined,
  onInit: () => undefined,
  onEditorChange: () => undefined,
  value: "",
};

WithFormikRichText.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
  withFormik: PropTypes.bool,
  onEditorChange: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.node,
  editorName: PropTypes.oneOf(Object.keys(CKEDITOR)),
  config: PropTypes.object,
  onInit: PropTypes.func,
  showInspector: PropTypes.bool,
};

export default WithFormikRichText;
