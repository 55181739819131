import { logout } from "core/user.actions";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);

export const AuthContext = React.createContext();

const AuthProvider = ({ children, logout }) => {
  return (
    <AuthContext.Provider value={{ logout }}>{children}</AuthContext.Provider>
  );
};

export default connect(null, mapDispatchToProps)(AuthProvider);
