import { ReactNode, forwardRef, useEffect, useRef } from "react";
import classnames from "classnames";
import { applyRef } from "core";

import styles from "./ClickButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ClickLink from "components/ClickLink/ClickLink";
import ClickTooltip from "components/ClickTooltip/ClickTooltip";

interface ClickButtonProps {
  background?: "light" | "dark";
  children: ReactNode;
  className?: string;
  color: "success" | "danger";
  disabled?: boolean;
  fill?: "ghost" | "simple";
  icon?: IconProp;
  isLoading?: boolean;
  label?: string;
  link?: string;
  query?: any /** @todo */;
  size?: "small" | "big";
  submit?: boolean;
  tooltip?: TooltipProps;
}

interface TooltipProps {
  content: ReactNode;
  placement: "top" | "bottom" | "left" | "right";
  strategy: "absolute" | "fixed";
  className: string;
  triggerRef: string;
  children: ReactNode;
}

/** @todo type of generic ref */
const ClickButton = forwardRef<any, ClickButtonProps>(
  (
    {
      background,
      children,
      className,
      color,
      disabled,
      fill,
      icon,
      isLoading,
      label,
      link,
      query,
      size,
      submit,
      tooltip,
      ...props
    },
    ref,
  ) => {
    const buttonRef = useRef<HTMLButtonElement>();

    useEffect(() => {
      // called when isLoading has changed, and isLoading had changed to true
      if (isLoading) {
        // when the focus changes, does nothing
        let focusChanged = false;

        /** @todo type of _event or remove it */
        const focusListener = (_event: any) => {
          focusChanged = true;
        };
        // must keep this reference to the document, so the removeEventListener
        // can be called on the same document, even if the button have been unmounted
        const currentRef = buttonRef.current;
        if (currentRef) {
          currentRef.ownerDocument.addEventListener("focus", focusListener, {
            capture: true,
            once: true,
            passive: true,
          });
        }

        // as here isLoading is true, this will be called when it changes (to false)
        const btnRef = buttonRef.current;
        return () => {
          if (!focusChanged) {
            // just focus if the button is still rendered on the document
            if (buttonRef && btnRef) {
              btnRef.focus();
            }
          }
          if (currentRef) {
            currentRef.ownerDocument.removeEventListener(
              "focus",
              focusListener,
              {
                capture: true,

                /** @todo these options doesn't exist */
                // once: true,
                // passive: true,
              },
            );
          }
        };
      }
    }, [isLoading]);

    const classname = classnames(styles.button, className, {
      [styles.isLoading]: isLoading,
      [styles[color]]: color,
      [styles[size!]]: size /** @todo handle undefined */,
      [styles[fill!]]: fill /** @todo handle undefined */,
      [styles[background!]]: background /** @todo handle undefined */,
    });

    const content = (
      <>
        {!!icon && (
          <span className={styles.icon} aria-hidden="true">
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
        {!!children && <span className={styles.label}>{children}</span>}
        {isLoading !== undefined && (
          <span aria-hidden={true} className={styles.loading}>
            <FontAwesomeIcon icon="circle-notch" spin />
          </span>
        )}
      </>
    );

    /** @todo type of refToApply */
    const contentButton = (refToApply: any) =>
      /* tests if should render a button or a tag */
      disabled || (link === undefined && query === undefined) ? (
        <button
          {...(label ? { "aria-label": label } : {})}
          disabled={disabled || isLoading}
          aria-busy={isLoading}
          className={classname}
          type={submit ? "submit" : "button"}
          ref={(element) => {
            applyRef(element, ref);
            applyRef(element, refToApply);
          }}
          {...props}
        >
          {content}
        </button>
      ) : (
        /** @todo add typescript to this */
        <ClickLink
          {...props}
          aria-label={label}
          ref={(element) => {
            applyRef(element, ref);
            applyRef(element, refToApply);
          }}
          query={query}
          link={link!}
          className={classname}
        >
          {content}
        </ClickLink>
      );

    /*
      tests if should render a tooltip: explicited declared tooltip
        or icon-only render (no children).
        Default content for tooltip is label, but can be
        overwritten by tooltip prop
    */
    return (tooltip || (label && !children)) && !disabled ? (
      <ClickTooltip
        {...tooltip}
        content={label}
        triggerRef={buttonRef}
        key={`${(tooltip && tooltip.content) || label}-enabled`}
      >
        {(triggerRef) => contentButton(triggerRef)}
      </ClickTooltip>
    ) : tooltip || (label && !children) ? (
      <ClickTooltip
        {...tooltip}
        content={<i>Desabilitado: {label}</i>}
        triggerRef={buttonRef}
        key={`${(tooltip && tooltip.content) || label}-disabled`}
      >
        {(triggerRef) => (
          <span ref={triggerRef} className={styles.tooltipTrigger}>
            {contentButton(ref)}
          </span>
        )}
      </ClickTooltip>
    ) : (
      contentButton(ref)
    );
  },
);

export default ClickButton;
