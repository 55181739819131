import React, { useState } from "react";
import Form from "./Form";

const CreateProject = () => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  return (
    <>
      <button onClick={toggle}>{show ? "Fechar" : "Criar novo projeto"}</button>
      <Form visible={show} />
    </>
  );
};

export default CreateProject;
