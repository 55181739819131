import React, { createContext, useState } from "react";

export const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  const [reduced, setReduced] = useState(true);
  return (
    <MenuContext.Provider value={[reduced, setReduced]}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;
