import { Record } from "immutable";

const ListExercisesRecord = Record({ displayedExercise: "none" });

/** Reducer for ListExercise component.
 * displayedExercise: "none" || "loading" || "failed" || {
 * ...exercise structure
 * }
 */
const ListExercisesReducer = (state, action) => {
  switch (action.type) {
    case "view.listExercises.exerciseDisplayLoading":
      return state.set("displayedExercise", "loading");
    case "view.listExercises.exerciseDisplayLoaded":
      return state.set("displayedExercise", action.payload);
    case "view.listExercises.exerciseDisplayFail":
      return state.set("displayedExercise", "failed");
    case "view.listExercises.exerciseDisplayClose":
      return state.set("displayedExercise", "none");
    default:
      return state;
  }
};

ListExercisesReducer.defaultState = ListExercisesRecord;

export default ListExercisesReducer;
