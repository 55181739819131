/**
 * @module ClickFilter
 */

import React, { useEffect, useState, useRef } from "react";
import styles from "./ClickFilter.module.scss";
import ClickButton from "components/ClickButton";
import { ClickTextInput } from "components/Form";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Scrollbars } from "react-custom-scrollbars";
import PropTypes from "prop-types";
import "animate.css";
import { objToQuery, queryToObj, compareObject } from "core/";

/**
 * @description Filter component with that apply filter fields to url as query params.
 * Has a main text input and a collapsible container with other filters.
 * @param { string } props.id - id of filter dropdown, required for a11y purposes
 * @param { object } props.mainInput - props to be forwarded to main text input
 * @param { object } props.initialValues - initial values as used in formik. object keys
 *                                         must match form elements names
 * @param { node } props.children - form components withFormik=true
 * @param { string } [props.className] - className for filter container
 * @memberof module:ClickFilter
 */
const ClickFilter = ({
  id,
  mainInput,
  initialValues,
  children,
  className,
  //router props
  history,
  location: { search }
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleKeyDown = evt => {
    switch (evt.key) {
      case "Escape": {
        setOpen(false);
        break;
      }
      default: {
        break;
      }
    }
  };

  // Closes filter when user clicks outside this component
  const filterRef = useRef();
  useEffect(() => {
    const handleClick = evt => {
      if (isOpen && filterRef.current) {
        if (!filterRef.current.contains(evt.target)) setOpen(false);
      }
    };
    document.addEventListener("click", handleClick, {
      passive: true
    });
    return () => {
      document.removeEventListener("click", handleClick, {
        passive: true
      });
    };
  }, [isOpen]);

  // Focus first focusable item of dropdown when filter opens
  const dropdownRef = useRef();
  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      dropdownRef.current
        .querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )
        .focus();
    }
  }, [isOpen]);

  const queryObj = search ? queryToObj(search) : undefined;

  const mergedInitialValues = { ...initialValues, ...queryObj };

  return (
    <Formik
      initialValues={mergedInitialValues}
      onSubmit={query => {
        // Avoid inserting initial values on url, clear currentPage
        const queryParams = query
          ? objToQuery(
              Object.entries(query)
                .filter(([key]) => key !== "currentPage")
                .reduce(
                  (obj, [key, value]) =>
                    initialValues[key] !== undefined &&
                    value === initialValues[key]
                      ? obj
                      : { ...obj, [key]: value },
                  {}
                )
            )
          : undefined;
        // Close filter dropdown
        setOpen(false);
        // Push query to url
        if (!compareObject(query, mergedInitialValues))
          history.push({
            search: queryParams
          });
      }}
      render={({ values }) => {
        const disabled = compareObject(values, mergedInitialValues);
        return (
          <Form>
            <div
              className={classnames(styles.container, className)}
              ref={filterRef}
            >
              { children && (
                <>
                  <ClickButton
                    background="dark"
                    icon="filter"
                    fill="simple"
                    onClick={() => setOpen(prevState => !prevState)}
                    aria-label={`${isOpen ? "Esconder" : "Exibir"} filtros`}
                    aria-controls={id}
                    aria-expanded={isOpen}
                    aria-haspopup="true"
                  >
                    Filtros
                  </ClickButton>
                  {isOpen && (
                    <div
                      className={classnames(
                        styles.dropdownWrap,
                        "animated",
                        "slideInLeft"
                      )}
                    >
                      <Scrollbars
                        hideTracksWhenNotNeeded
                        renderThumbVertical={props => (
                          <div {...props} className={styles.scrollbarThumb} />
                        )}
                      >
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <div
                          id={id}
                          onKeyDown={handleKeyDown}
                          tabIndex={-1}
                          aria-label="Filtros"
                          className={styles.dropdown}
                          ref={dropdownRef}
                        >
                          {children}
                          <ClickButton
                            background="dark"
                            fill="ghost"
                            disabled={disabled}
                            submit
                          >
                            Aplicar filtros
                          </ClickButton>
                        </div>
                      </Scrollbars>
                    </div>
                  )}
                </>
              )}
              <div className={styles.mainInputWrap}>
                <div className={styles.mainInput}>
                  <ClickTextInput {...mainInput} />
                </div>
                <ClickButton
                  background="dark"
                  label="Buscar"
                  icon="search"
                  fill="simple"
                  disabled={disabled}
                  submit
                />
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

ClickFilter.propTypes = {
  id: PropTypes.string.isRequired,
  mainInput: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default withRouter(ClickFilter);
