import { useRef, useContext } from "react";
import { UserContext } from "views/User.context";
import getSchoolsReport from "./getSchoolsReport";
import getTeachersFromSchoolReport from "./getTeachersFromSchoolReport";

const useActivitiesReportsModel = () => {
  const modelRef = useRef();
  const { school } = useContext(UserContext);

  if (!modelRef.current) {
    modelRef.current = {
      getSchoolsReport: () => getSchoolsReport(school),
      getTeachersFromSchoolReport: (projectSchool) =>
        getTeachersFromSchoolReport(school, projectSchool),
    };
  }
  return modelRef.current;
};

export default useActivitiesReportsModel;