import { Record } from "immutable";
import { combineReducers } from "redux-immutable";
import viewRoadmap from "./ViewRoadmap/ViewRoadmap.reducer";

const LearningRoadmapRecord = Record({
  viewRoadmap: new viewRoadmap.defaultState()
});

const LearningRoadmapReducer = combineReducers(
  {
    viewRoadmap
  },
  LearningRoadmapRecord
);

LearningRoadmapReducer.defaultState = LearningRoadmapRecord;

export default LearningRoadmapReducer;
