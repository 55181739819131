import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { A11yContext } from "contexts/A11y.context";
import { useContext } from "react";
import styles from "./F1Theme.module.scss";

const Clouds = () => (
  <div className={styles.clouds}>
    {Array(5)
      .fill()
      .map((_, i) => (
        <FontAwesomeIcon key={i} icon="cloud" />
      ))}
  </div>
);

const F1Theme = () => {
  const { nightMode } = useContext(A11yContext);
  return (
    <>
      <div
        className={classnames(styles.sun, "animated", {
          fadeInUpBig: !nightMode,
          fadeOutDownBig: nightMode,
        })}
      />
      <div className={styles.background}>
        <Clouds />
        <Clouds />
      </div>
      <div className={styles.foreground}>
        <Clouds />
        <Clouds />
      </div>
      <div
        className={classnames(styles.moon, "animated", {
          fadeInUpBig: nightMode,
          fadeOutDownBig: !nightMode,
        })}
      >
        <FontAwesomeIcon icon="moon" />
      </div>
    </>
  );
};

export default F1Theme;
