import { api } from "core";
import React, { useEffect, useRef, useState } from "react";
import fuzzysearch from "./fuzzysearch";
import {
  defaultData,
  defaultMetadata,
  getMetadata,
  getParsedData,
} from "./SpreadsheetParser";

export const ProjectBulkUpdateContext = React.createContext();

const ProjectBulkUpdateProvider = (props) => {
  const defaultFuzzyData = {
    ss: { schools: {}, teachers: {}, students: {} },
    db: { schools: {}, teachers: {}, students: {} },
  };

  const [spreadsheetData, setSpreadsheetData] = useState({ ...defaultData });
  const [databaseData, setDatabaseData] = useState({ ...defaultData });
  const [fuzzyData, setFuzzyData] = useState({ ...defaultFuzzyData });
  const [fuzzyLoading, setFuzzyLoading] = useState(false);
  const [metadata, setMetadata] = useState({ ...defaultMetadata });
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [safeMode, setSafeMode] = useState(true);

  const [fuzzySSSchoolsReady, setFuzzySSSchoolsReady] = useState(false);
  const [fuzzyDBSchoolsReady, setFuzzyDBSchoolsReady] = useState(false);
  const [fuzzySSTeachersReady, setFuzzySSTeachersReady] = useState(false);
  const [fuzzyDBTeachersReady, setFuzzyDBTeachersReady] = useState(false);
  const [fuzzySSStudentsReady, setFuzzySSStudentsReady] = useState(false);
  const [fuzzyDBStudentsReady, setFuzzyDBStudentsReady] = useState(false);
  const projectId = props.projectId;

  const stopWorkers = useRef(false);

  useEffect(() => {
    if (JSON.stringify(databaseData) === JSON.stringify(defaultData)) return;

    setDataIsLoaded(true);
    setDataIsReady(true);

    let unsubSSSchools;
    let unsubSSTeachers;
    let unsubSSStudents;
    let unsubDBSchools;
    let unsubDBTeachers;
    let unsubDBStudents;

    if (!fuzzyLoading || !updateFuzzyData.is.completelyLoaded()) {
      setFuzzyLoading(true);
      unsubSSSchools = updateFuzzyData.run("ss", "schools");
      unsubSSTeachers = updateFuzzyData.run("ss", "teachers");
      unsubSSStudents = updateFuzzyData.run("ss", "students");
      unsubDBSchools = updateFuzzyData.run("db", "schools");
      unsubDBTeachers = updateFuzzyData.run("db", "teachers");
      unsubDBStudents = updateFuzzyData.run("db", "students");
    }

    return () => {
      stopWorkers.current = true;
      if (unsubSSSchools) unsubSSSchools();
      if (unsubSSTeachers) unsubSSTeachers();
      if (unsubSSStudents) unsubSSStudents();
      if (unsubDBSchools) unsubDBSchools();
      if (unsubDBTeachers) unsubDBTeachers();
      if (unsubDBStudents) unsubDBStudents();
    };
  }, [databaseData]);

  const updateDatabaseData = (userType) => {
    api.instance
      .get(`/projectData/${projectId}`, {
        params: { userType },
      })
      .then((r) => {
        setDatabaseData(r.data);
      });
  };

  const loadSSData = (bstr, fileType) => {
    const metadata = getMetadata(bstr, fileType);
    setMetadata({ ...metadata, goal: "insert" });

    try {
      const spreadSheetData = getParsedData(
        bstr,
        fileType,
        metadata.userType,
        metadata.school,
      );
      setSpreadsheetData(spreadSheetData);
      updateDatabaseData(metadata.userType);
    } catch (err) {
      alert(err);
    }
  };

  const toggleSafeMode = () => setSafeMode(!safeMode);

  const changeGoal = (newGoal) => setMetadata({ ...metadata, goal: newGoal });

  /**
   * updateFuzzyData
   *
   *
   */
  const updateFuzzyData = {
    is: {
      completelyLoaded: () =>
        updateFuzzyData.is.schoolsLoaded() &&
        updateFuzzyData.is.teachersLoaded() &&
        updateFuzzyData.is.studentsLoaded(),

      schoolsLoaded: () => fuzzySSSchoolsReady && fuzzyDBSchoolsReady,
      teachersLoaded: () => fuzzySSTeachersReady && fuzzyDBTeachersReady,
      studentsLoaded: () => fuzzySSStudentsReady && fuzzyDBStudentsReady,
    },

    set: {
      loaded: {
        ss: {
          schools: () => setFuzzySSSchoolsReady(true),
          teachers: () => setFuzzySSTeachersReady(true),
          students: () => setFuzzySSStudentsReady(true),
        },

        db: {
          schools: () => setFuzzyDBSchoolsReady(true),
          teachers: () => setFuzzyDBTeachersReady(true),
          students: () => setFuzzyDBStudentsReady(true),
        },
      },
    },

    // Select only the students from the schools that are being modified
    studentsDBSet: () => {
      const schools = Object.keys(spreadsheetData.schools).map((schoolSSId) =>
        parseInt(pbuSearch.dup.onDBSet("schools", schoolSSId)),
      );
      const classes = databaseData.classes;
      const classesId = Object.keys(classes || {})
        .filter((classId) => schools.includes(classes[classId].school))
        .map((a) => parseInt(a));
      const studentClasses = databaseData.studentClasses;
      const studentClassesId = Object.keys(studentClasses || {})
        .filter((studentClassId) =>
          classesId.includes(studentClasses[studentClassId].class),
        )
        .map((a) => parseInt(a));
      const students = databaseData.students;
      const filteredStudents = Object.keys(students || {})
        .filter((studentId) => studentClassesId.includes(parseInt(studentId)))
        .reduce((acc, dbId) => {
          return Object.assign(acc, {
            [dbId]: pbuSearch.get.students.name("db", dbId),
          });
        }, {});
      return filteredStudents;
    },

    run: (dataSet, dataSub) => {
      if (fuzzyLoading || updateFuzzyData.is.completelyLoaded()) return;

      const ssSet = spreadsheetData[dataSub];
      const set =
        dataSet === "db" && dataSub === "students"
          ? updateFuzzyData.studentsDBSet()
          : pbuSearch.dataSet(dataSet)[dataSub];
      const skipSameId = dataSet === "ss";
      const [promise, workerTerminate] = fuzzysearch(ssSet, set, skipSameId);

      promise.then((results) => {
        if (stopWorkers.current) return;

        let tempSubSet = {};
        results.forEach((result) => Object.assign(tempSubSet, result));

        const newSubSet = Object.keys(tempSubSet)
          .filter((key) => tempSubSet[key].length > 0)
          .reduce((acc, ssId) => {
            return Object.assign(acc, {
              [ssId]: tempSubSet[ssId].map((key) => {
                let school = undefined;
                if (dataSub === "teachers") {
                  school = pbuSearch.get.teachers.school(dataSet, key);
                } else if (dataSub === "students") {
                  school = pbuSearch.get.students.school(dataSet, key);
                }
                return { name: set[key], school: school };
              }),
            });
          }, {});

        let newFuzzyData = { ...fuzzyData };
        Object.assign(newFuzzyData[dataSet][dataSub], newSubSet);
        setFuzzyData(newFuzzyData);
        updateFuzzyData.set.loaded[dataSet][dataSub]();
      });

      return workerTerminate;
    },

    runForProject: (dataSet, dataSub) => {
      if (fuzzyLoading || updateFuzzyData.is.completelyLoaded()) return;

      const ssSet = spreadsheetData[dataSub];
      const set = pbuSearch.dataSet(dataSet)[dataSub];
      const skipSameId = dataSet === "ss";
      const [promise, workerTerminate] = fuzzysearch(ssSet, set, skipSameId);

      promise.then((results) => {
        if (stopWorkers.current) return;

        let tempSubSet = {};
        results.forEach((result) => Object.assign(tempSubSet, result));

        const newSubSet = Object.keys(tempSubSet)
          .filter((key) => tempSubSet[key].length > 0)
          .reduce((acc, ssId) => {
            return Object.assign(acc, {
              [ssId]: tempSubSet[ssId].map((key) => {
                let school = undefined;
                if (dataSub === "teachers") {
                  school = pbuSearch.get.teachers.school(dataSet, key);
                } else if (dataSub === "students") {
                  school = pbuSearch.get.students.school(dataSet, key);
                }
                return { name: set[key], school: school };
              }),
            });
          }, {});

        let newFuzzyData = { ...fuzzyData };
        Object.assign(newFuzzyData[dataSet][dataSub], newSubSet);
        setFuzzyData(newFuzzyData);
        updateFuzzyData.set.loaded[dataSet][dataSub]();
      });

      return workerTerminate;
    },
  };

  /**
   * updateSSData
   *
   * Após cada atualização de um dos campos da árvore spreadsheetData, os campos
   * que referenciam a alteração também devem ser modificados
   *
   * schools -> (teacherSchools AND (classes -> studentClasses))
   * levels -> (teacherSchools AND series -> (classes -> studentClasses))
   * teacherTypes -> teacherSchools
   * teachers -> teacherSchools (caso de merge)
   * series -> classes -> studentClasses
   * classes -> studentClasses
   * students -> studentClasses (caso de merge)
   */
  const updateSSData = {
    run: (updateMethod) =>
      setSpreadsheetData(updateMethod({ ...spreadsheetData })),

    propagate: (
      data,
      oldId,
      newId,
      dataType,
      dataSub,
      rePropagate = (data, oldPId, newPId) => data,
    ) => {
      data = { ...data };
      oldId = parseInt(oldId);
      newId = parseInt(newId);

      Object.keys(data[dataSub]).forEach((key) => {
        const oldObj = data[dataSub][key];
        if (oldObj[dataType] === oldId) {
          const newObj = { ...oldObj, [dataType]: newId };
          const dupId = pbuSearch.dup.byValue("ss", dataSub, key, newObj);

          if (dupId) {
            delete data[dataSub][key];
            data = rePropagate(data, key, dupId);
          } else {
            data[dataSub][key] = newObj;
          }
        }
      });

      return data;
    },

    schools: {
      run: (id, newName) => {
        updateSSData.run((data) => {
          const dupId = pbuSearch.dup.byValue("ss", "schools", id, newName);

          if (dupId) {
            delete data.schools[id];
            data = updateSSData.teacherSchools.fromSchool(data, id, dupId);
            data = updateSSData.classes.fromSchool(data, id, dupId);
          } else {
            data.schools[id] = newName;
          }

          return data;
        });
      },
    },

    levels: {
      run: (id, newName) => {
        updateSSData.run((data) => {
          const dupId = pbuSearch.dup.byValue("ss", "levels", id, newName);

          if (dupId) {
            delete data.levels[id];
            data = updateSSData.teacherSchools.fromLevel(data, id, dupId);
            data = updateSSData.series.fromLevel(data, id, dupId);
          } else {
            data.levels[id] = newName;
          }

          return data;
        });
      },
    },

    teacherTypes: {
      run: (id, newName) => {
        updateSSData.run((data) => {
          const dupId = pbuSearch.dup.byValue(
            "ss",
            "teacherTypes",
            id,
            newName,
          );

          if (dupId) {
            delete data.teacherTypes[id];
            data = updateSSData.teacherSchools.fromTeacherType(data, id, dupId);
          } else {
            data.teacherTypes[id] = newName;
          }

          return data;
        });
      },
    },

    teachers: {
      run: (id, newName) => {
        let data = { ...spreadsheetData };
        data.teachers[id] = newName;
        setSpreadsheetData(data);
      },
    },

    teacherSchools: {
      propagate: (data, oldId, newId, dataType) =>
        updateSSData.propagate(data, oldId, newId, dataType, "teacherSchools"),

      fromSchool: (data, oldSchoolId, newSchoolId) =>
        updateSSData.teacherSchools.propagate(
          data,
          oldSchoolId,
          newSchoolId,
          "school",
        ),

      fromLevel: (data, oldLevelId, newLevelId) => {
        data = { ...data };
        oldLevelId = parseInt(oldLevelId);
        newLevelId = parseInt(newLevelId);

        Object.keys(spreadsheetData.teacherSchools).forEach((key) => {
          const levels = spreadsheetData.teacherSchools[key].levels;

          if (levels.includes(oldLevelId)) {
            spreadsheetData.teacherSchools[key].levels = levels
              .filter((fKey) => fKey !== oldLevelId)
              .concat(newLevelId);
          }
        });
        return data;
      },

      fromTeacherType: (data, oldTeacherType, newTeacherType) =>
        updateSSData.teacherSchools.propagate(
          data,
          oldTeacherType,
          newTeacherType,
          "type",
        ),

      fromTeacher: (data, oldTeacher, newTeacher) =>
        updateSSData.teacherSchools.propagate(
          data,
          oldTeacher,
          newTeacher,
          "teacher",
        ),
    },

    series: {
      run: (id, newName) => {
        updateSSData.run((data) => {
          const newObj = { ...spreadsheetData.series[id], label: newName };
          const dupId = pbuSearch.dup.byValue("ss", "series", id, newObj);

          if (dupId) {
            delete data.series[id];
            data = updateSSData.classes.fromSerie(data, id, dupId);
          } else {
            data.series[id].label = newName;
          }

          return data;
        });
      },

      propagate: (data, oldId, newId, dataType, propagate) =>
        updateSSData.propagate(
          data,
          oldId,
          newId,
          dataType,
          "series",
          propagate,
        ),

      fromLevel: (data, oldLevel, newLevel) =>
        updateSSData.series.propagate(
          data,
          oldLevel,
          newLevel,
          "level",
          (data, oldSerie, newSerie) =>
            updateSSData.classes.fromSerie(data, oldSerie, newSerie),
        ),
    },

    classes: {
      run: (id, newName) => {
        updateSSData.run((data) => {
          const newObj = { ...spreadsheetData.classes[id], label: newName };
          const dupId = pbuSearch.dup.byValue("ss", "classes", id, newObj);

          if (dupId) {
            delete data.classes[id];
            data = updateSSData.studentClasses.fromClass(data, id, dupId);
          } else {
            data.classes[id].label = newName;
          }

          return data;
        });
      },

      propagate: (data, oldId, newId, dataType, propagate) =>
        updateSSData.propagate(
          data,
          oldId,
          newId,
          dataType,
          "classes",
          propagate,
        ),

      fromSchool: (data, oldSchoolId, newSchoolId) =>
        updateSSData.classes.propagate(
          data,
          oldSchoolId,
          newSchoolId,
          "school",
          (data, oldClass, newClass) =>
            updateSSData.studentClasses.fromClass(data, oldClass, newClass),
        ),

      fromSerie: (data, oldSerie, newSerie) =>
        updateSSData.classes.propagate(
          data,
          oldSerie,
          newSerie,
          "serie",
          (data, oldClass, newClass) =>
            updateSSData.studentClasses.fromClass(data, oldClass, newClass),
        ),
    },

    students: {
      run: (id, newName) => {
        let data = { ...spreadsheetData };
        data.students[id] = newName;
        setSpreadsheetData(data);
      },
    },

    studentClasses: {
      propagate: (data, oldId, newId, dataType) =>
        updateSSData.propagate(data, oldId, newId, dataType, "studentClasses"),

      fromClass: (data, oldClassId, newClassId) =>
        updateSSData.studentClasses.propagate(
          data,
          oldClassId,
          newClassId,
          "class",
        ),

      fromStudent: (data, oldStudentId, newStudentId) =>
        updateSSData.studentClasses.propagate(
          data,
          oldStudentId,
          newStudentId,
          "student",
        ),
    },
  };

  /**
   * pbuSearch (Project Bulk Update Search)
   *
   * Métodos que acessam o spreadsheetData e databaseData para buscar os
   * valores requisitados, respeitando a arquitetura em base de
   * dados implementada no SpreadsheetParser
   */
  const pbuSearch = {
    /**
     * dataSet
     *
     * Retorna uma cópia do dataSet selecionado
     * "ss": dados recuperados da planilha
     * "db": dados recuperados do banco de dados
     */
    dataSet: (dataSetAlias) => {
      if (dataSetAlias === "db") {
        return { ...databaseData };
      } else if (dataSetAlias === "ss") {
        return { ...spreadsheetData };
      }
    },

    /**
     * dup
     *
     * Busca por valores duplicados, seja no mesmo dataset (ex: busca
     * de duplicação no spreadsheetData) ou em dois datasets diferentes (ex:
     * valor no spreadsheetData que também existe no databaseData)
     */
    dup: {
      byId: (dataSet, dataSub, id) => {
        const obj = pbuSearch.dataSet(dataSet)[dataSub];
        return Object.keys(obj).find(
          (key) => key !== id && pbuSearch.compare.byId(obj, id, key),
        );
      },

      byValue: (dataSet, dataSub, id, value) => {
        const obj = pbuSearch.dataSet(dataSet)[dataSub];
        return Object.keys(obj).find(
          (key) => key !== id && pbuSearch.compare.byValue(obj[key], value),
        );
      },

      onSSSet: (dataSub, dbId) =>
        Object.keys(spreadsheetData[dataSub]).find((key) =>
          pbuSearch.compare.diffDataSet[dataSub](key, dbId),
        ),

      onDBSet: (dataSub, ssId, sameSchool = false) =>
        Object.keys(databaseData[dataSub]).find(
          (key) =>
            pbuSearch.compare.diffDataSet[dataSub](ssId, key) &&
            (!sameSchool ||
              pbuSearch.get[dataSub].school("ss", ssId) ===
                pbuSearch.get[dataSub].school("db", key)),
        ),
    },

    /**
     * compare
     *
     * Compara os valores de um registro para os casos de estarem no mesmo
     * dataset ou em datasets diferentes. No caso de datasets diferentes,
     * deve levar em conta um registro { level: 1, serie: "A"} pode ser
     * idêntico a { level: 12, serie: "A"} no databaseData se o levelId 1
     * do spreadsheetData e levelId 12 do databaseData referenciarem o mesmo level)
     */
    compare: {
      byId: (set, id1, id2) => pbuSearch.compare.byValue(set[id1], set[id2]),

      byValue: (val1, val2) => {
        return typeof val1 === "object" && val1 !== null
          ? JSON.stringify(val1).toLowerCase() ===
              JSON.stringify(val2).toLowerCase()
          : val1.toLowerCase() === val2.toLowerCase();
      },

      diffDataSet: {
        schools: (ssId, dbId) =>
          spreadsheetData.schools[ssId].toLowerCase() ===
          databaseData.schools[dbId].toLowerCase(),

        levels: (ssId, dbId) =>
          spreadsheetData.levels[ssId].toLowerCase() ===
          databaseData.levels[dbId].toLowerCase(),

        teacherTypes: (ssId, dbId) =>
          spreadsheetData.teacherTypes[ssId].toLowerCase() ===
          databaseData.teacherTypes[dbId].toLowerCase(),

        teachers: (ssId, dbId) =>
          spreadsheetData.teachers[ssId].toLowerCase() ===
          databaseData.teachers[dbId].toLowerCase(),

        teacherSchools: (ssId, dbId) => {
          const ssObj = {
            teacher:
              spreadsheetData.teachers[
                spreadsheetData.teacherSchools[ssId].teacher
              ],
            school: parseInt(
              pbuSearch.dup.onDBSet(
                "schools",
                spreadsheetData.teacherSchools[ssId].school,
              ),
            ),
            levels: spreadsheetData.teacherSchools[ssId].levels
              .map((key) => parseInt(pbuSearch.dup.onDBSet("levels", key)))
              .sort(),
            type: parseInt(
              pbuSearch.dup.onDBSet(
                "teacherTypes",
                spreadsheetData.teacherSchools[ssId].type,
              ),
            ),
            deleted: false,
          };

          const dbObj = {
            teacher:
              databaseData.teachers[databaseData.teacherSchools[dbId].teacher],
            school: databaseData.teacherSchools[dbId].school,
            levels: databaseData.teacherSchools[dbId].levels.sort(),
            type: databaseData.teacherSchools[dbId].type,
            deleted: databaseData.teacherSchools[dbId].deleted,
          };

          return (
            JSON.stringify(ssObj).toLowerCase() ===
            JSON.stringify(dbObj).toLowerCase()
          );
        },

        teacherEmails: (spreadsheetEmail, databaseEmail) =>
          spreadsheetEmail === databaseEmail,

        series: (ssId, dbId) => {
          const ssObj = {
            ...spreadsheetData.series[ssId],
            level: parseInt(
              pbuSearch.dup.onDBSet(
                "levels",
                spreadsheetData.series[ssId].level,
              ),
            ),
          };
          const dbObj = databaseData.series[dbId];

          return (
            JSON.stringify(ssObj).toLowerCase() ===
            JSON.stringify(dbObj).toLowerCase()
          );
        },

        classes: (ssId, dbId) => {
          const ssObj = {
            ...spreadsheetData.classes[ssId],
            school: parseInt(
              pbuSearch.dup.onDBSet(
                "schools",
                spreadsheetData.classes[ssId].school,
              ),
            ),
            serie: parseInt(
              pbuSearch.dup.onDBSet(
                "series",
                spreadsheetData.classes[ssId].serie,
              ),
            ),
          };
          const dbObj = databaseData.classes[dbId];

          return (
            JSON.stringify(ssObj).toLowerCase() ===
            JSON.stringify(dbObj).toLowerCase()
          );
        },

        students: (ssId, dbId) =>
          spreadsheetData.students[ssId]
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") ===
          databaseData.students[dbId]
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),

        studentClasses: (ssId, dbId) => {
          const ssObj = {
            student:
              spreadsheetData.students[
                spreadsheetData.studentClasses[ssId].student
              ],
            class: parseInt(
              pbuSearch.dup.onDBSet(
                "classes",
                spreadsheetData.studentClasses[ssId].class,
              ),
            ),
            deleted: false,
          };

          const dbObj = {
            student:
              databaseData.students[databaseData.studentClasses[dbId].student],
            class: databaseData.studentClasses[dbId].class,
            deleted: databaseData.studentClasses[dbId].deleted,
          };

          return (
            JSON.stringify(ssObj).toLowerCase() ===
            JSON.stringify(dbObj).toLowerCase()
          );
        },

        studentEmails: (spreadsheetEmail, databaseEmail) =>
          spreadsheetEmail === databaseEmail,
      },
    },

    /**
     * get
     *
     * Faz a leitura do valor no dataSet escolhido ("db" ou "ss")
     */
    get: {
      schools: {
        name: (dataSet, id) => pbuSearch.dataSet(dataSet).schools[id],
      },

      levels: {
        name: (dataSet, id) => pbuSearch.dataSet(dataSet).levels[id],
      },

      teacherTypes: {
        name: (dataSet, id) => pbuSearch.dataSet(dataSet).teacherTypes[id],
      },

      teachers: {
        name: (dataSet, id) => pbuSearch.dataSet(dataSet).teachers[id],

        teacherSchoolId: (dataSet, id) => {
          const dataSub = pbuSearch.dataSet(dataSet).teacherSchools;
          return Object.keys(dataSub).find(
            (teacherSchoolId) =>
              dataSub[teacherSchoolId].teacher === parseInt(id),
          );
        },

        schoolId: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).teacherSchools[
            pbuSearch.get.teachers.teacherSchoolId(dataSet, id)
          ].school,

        school: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).schools[
            pbuSearch.get.teachers.schoolId(dataSet, id)
          ],

        levelIds: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).teacherSchools[
            pbuSearch.get.teachers.teacherSchoolId(dataSet, id)
          ].levels,

        levels: (dataSet, id) =>
          pbuSearch.get.teachers
            .levelIds(dataSet, id)
            .map((levelId) => pbuSearch.dataSet(dataSet).levels[levelId]),

        typeId: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).teacherSchools[
            pbuSearch.get.teachers.teacherSchoolId(dataSet, id)
          ].type,

        type: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).teacherTypes[
            pbuSearch.get.teachers.typeId(dataSet, id)
          ],

        deleted: (dataSet, id) =>
          dataSet === "db"
            ? pbuSearch.dataSet(dataSet).teacherSchools[
                pbuSearch.get.teachers.teacherSchoolId(dataSet, id)
              ].deleted
            : false,

        email: (dataSet, id) => pbuSearch.dataSet(dataSet).teacherEmails[id],
      },

      teacherSchools: {},

      series: {
        label: (dataSet, id) => pbuSearch.dataSet(dataSet).series[id].label,

        levelId: (dataSet, id) => pbuSearch.dataSet(dataSet).series[id].level,

        level: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).levels[
            pbuSearch.get.series.levelId(dataSet, id)
          ],
      },

      classes: {
        label: (dataSet, id) => pbuSearch.dataSet(dataSet).classes[id].label,

        studentClassId: (dataSet, id) => {
          const dataSub = pbuSearch.dataSet(dataSet).studentClasses;
          return Object.keys(dataSub).find(
            (studentClassId) => dataSub[studentClassId].class === parseInt(id),
          );
        },

        schoolId: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).classes[id].school,

        school: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).schools[
            pbuSearch.get.classes.schoolId(dataSet, id)
          ],

        levelId: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).series[
            pbuSearch.get.classes.serieId(dataSet, id)
          ].level,

        level: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).levels[
            pbuSearch.get.classes.levelId(dataSet, id)
          ],

        serieId: (dataSet, id) => pbuSearch.dataSet(dataSet).classes[id].serie,

        serie: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).series[
            pbuSearch.get.classes.serieId(dataSet, id)
          ].label,
      },

      students: {
        name: (dataSet, id) => pbuSearch.dataSet(dataSet).students[id],

        studentClassId: (dataSet, id) => {
          const dataSub = pbuSearch.dataSet(dataSet).studentClasses;
          return Object.keys(dataSub).find(
            (studentClassId) =>
              dataSub[studentClassId].student === parseInt(id),
          );
        },

        classId: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).studentClasses[
            pbuSearch.get.students.studentClassId(dataSet, id)
          ].class,

        class: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).classes[
            pbuSearch.get.students.classId(dataSet, id)
          ].label,

        schoolId: (dataSet, id) =>
          pbuSearch.dataSet(dataSet).classes[
            pbuSearch.get.students.classId(dataSet, id)
          ].school,

        school: (dataSet, id) =>
          pbuSearch.get.classes.school(
            dataSet,
            pbuSearch.get.students.classId(dataSet, id),
          ),

        levelId: (dataSet, id) =>
          pbuSearch.get.classes.levelId(
            dataSet,
            pbuSearch.dataSet(dataSet).studentClasses[
              pbuSearch.get.students.studentClassId(dataSet, id)
            ].class,
          ),

        level: (dataSet, id) =>
          pbuSearch.get.classes.level(
            dataSet,
            pbuSearch.get.students.classId(dataSet, id),
          ),

        serieId: (dataSet, id) =>
          pbuSearch.get.classes.serieId(
            dataSet,
            pbuSearch.dataSet(dataSet).studentClasses[
              pbuSearch.get.students.studentClassId(dataSet, id)
            ].class,
          ),

        serie: (dataSet, id) =>
          pbuSearch.get.classes.serie(
            dataSet,
            pbuSearch.get.students.classId(dataSet, id),
          ),

        deleted: (dataSet, id) =>
          dataSet === "db"
            ? pbuSearch.dataSet(dataSet).studentClasses[
                pbuSearch.get.students.studentClassId(dataSet, id)
              ].deleted
            : false,

        email: (dataSet, id) => pbuSearch.dataSet(dataSet).studentEmails[id],
      },

      studentClasses: {},
    },
  };

  /**
   * processedData
   *
   * Processa os dados do spreadsheetData para:
   * 1. Serem expostos no front-end (ProjectBulkUpdateWizard)
   * 2. Serem enviados para o back-end
   * Funciona como um single source of truth para que o usuário
   * consiga ver exatamente o que será enviado para o back-end
   * e corrigir se necessário.
   */
  const processedData = {
    getStatusColor: (elem) => {
      return ((action) => {
        switch (action) {
          case "ok":
            return { status: "OK", color: "limegreen" };
          case "problem":
            return { status: "Problema", color: "palevioletred" };
          case "duplicated":
            return { status: "Duplicado", color: "palevioletred" };
          case "update":
            return { status: "Atualizar", color: "gold" };
          case "insert":
            return { status: "Criar", color: "turquoise" };
          case "delete":
            return { status: "Apagar", color: "salmon" };
          default:
            return { status: "-", color: "white" };
        }
      })(elem.action);
    },

    hasProblems: {
      schools: () => false,

      levels: () =>
        processedData.levels().filter((a) => a.action === "problem").length > 0,

      teacherTypes: () =>
        processedData.teacherTypes().filter((a) => a.action === "problem")
          .length > 0,

      teachers: () =>
        processedData
          .teachers()
          .filter((a) => a.action === "problem" || a.action === "duplicated")
          .length > 0,

      series: () =>
        processedData.series().filter((a) => a.action === "problem").length > 0,

      classes: () => false,

      students: () =>
        processedData
          .students()
          .filter((a) => a.action === "problem" || a.action === "duplicated")
          .length > 0,
    },

    action: {
      schools: (ssId) =>
        pbuSearch.dup.onDBSet("schools", ssId) ? "ok" : "insert",

      levels: (ssId) =>
        pbuSearch.dup.onDBSet("levels", ssId) ? "ok" : "problem",

      teacherTypes: (ssId) =>
        pbuSearch.dup.onDBSet("teacherTypes", ssId) ? "ok" : "problem",

      teachers: (ssId) => {
        if (pbuSearch.get.teachers.name("ss", ssId).includes("�"))
          return ["problem", null];
        if (pbuSearch.dup.byId("ss", "teachers", ssId))
          return ["duplicated", null];

        const dbId = pbuSearch.dup.onDBSet("teachers", ssId, true);
        if (!dbId) return ["insert", null];

        const matchSchools = pbuSearch.compare.diffDataSet.teacherSchools(
          pbuSearch.get.teachers.teacherSchoolId("ss", ssId),
          pbuSearch.get.teachers.teacherSchoolId("db", dbId),
        );

        // ignore empty email
        const matchEmail =
          !pbuSearch.get.teachers.email("ss", ssId) ||
          pbuSearch.compare.diffDataSet.teacherEmails(
            pbuSearch.get.teachers.email("ss", ssId),
            pbuSearch.get.teachers.email("db", dbId),
          );

        const exactMatch = matchSchools && matchEmail;

        return [exactMatch ? "ok" : "update", parseInt(dbId)];
      },

      series: (ssId) =>
        pbuSearch.dup.onDBSet("series", ssId) ? "ok" : "problem",

      classes: (ssId) =>
        pbuSearch.dup.onDBSet("classes", ssId) ? "ok" : "insert",

      students: (ssId) => {
        if (pbuSearch.get.students.name("ss", ssId).includes("�"))
          return ["problem", null];
        if (pbuSearch.dup.byId("ss", "students", ssId))
          return ["duplicated", null];

        const dbId = pbuSearch.dup.onDBSet("students", ssId, true);
        if (!dbId) return ["insert", null];

        const matchClasses = pbuSearch.compare.diffDataSet.studentClasses(
          pbuSearch.get.students.studentClassId("ss", ssId),
          pbuSearch.get.students.studentClassId("db", dbId),
        );

        // ignore empty email
        const matchEmail =
          !pbuSearch.get.students.email("ss", ssId) ||
          pbuSearch.compare.diffDataSet.studentEmails(
            pbuSearch.get.students.email("ss", ssId),
            pbuSearch.get.students.email("db", dbId),
          );

        const exactMatch = matchClasses && matchEmail;

        return [exactMatch ? "ok" : "update", parseInt(dbId)];
      },
    },

    schools: () => {
      return Object.keys(spreadsheetData.schools).map((ssSchoolId) => {
        return {
          action: processedData.action.schools(ssSchoolId),
          key: ssSchoolId,
          name: pbuSearch.get.schools.name("ss", ssSchoolId),
        };
      });
    },

    levels: () => {
      return Object.keys(spreadsheetData.levels).map((ssLevelId) => {
        return {
          action: processedData.action.levels(ssLevelId),
          key: ssLevelId,
          name: pbuSearch.get.levels.name("ss", ssLevelId),
        };
      });
    },

    teacherTypes: () => {
      return Object.keys(spreadsheetData.teacherTypes).map(
        (ssTeacherTypeId) => {
          return {
            action: processedData.action.teacherTypes(ssTeacherTypeId),
            key: ssTeacherTypeId,
            name: pbuSearch.get.teacherTypes.name("ss", ssTeacherTypeId),
          };
        },
      );
    },

    teachers: () => {
      const list = Object.keys(spreadsheetData.teachers).map((ssTeacherId) => {
        const dbLevelIds = pbuSearch.get.teachers
          .levelIds("ss", ssTeacherId)
          .map((level) => pbuSearch.dup.onDBSet("levels", level));
        const dbTypeId = pbuSearch.dup.onDBSet(
          "teacherTypes",
          pbuSearch.get.teachers.typeId("ss", ssTeacherId),
        );

        const [action, actionId] = processedData.action.teachers(ssTeacherId);
        const teacher = {
          action: action,
          id: actionId,
          key: `ss.${ssTeacherId}`,
          name: pbuSearch.get.teachers.name("ss", ssTeacherId),
          levelIds: dbLevelIds,
          levels: dbLevelIds.map((dbLevelId) =>
            pbuSearch.get.levels.name("db", dbLevelId),
          ),
          typeId: dbTypeId,
          type: pbuSearch.get.teacherTypes.name("db", dbTypeId),
          email: pbuSearch.get.teachers.email("ss", ssTeacherId),
        };

        if (safeMode) {
          teacher.schoolId = pbuSearch.get.teachers.schoolId("ss", ssTeacherId);
          teacher.school = pbuSearch.get.teachers.school("ss", ssTeacherId);
        } else {
          const dbSchoolId = pbuSearch.dup.onDBSet(
            "schools",
            pbuSearch.get.teachers.schoolId("ss", ssTeacherId),
          );

          teacher.schoolId = dbSchoolId;
          teacher.school = pbuSearch.get.schools.name("db", dbSchoolId);
        }

        return teacher;
      });

      if (metadata.goal === "insert") return list;

      const isInSchool = (dbTeacherId) => {
        const schools =
          metadata.school === "all"
            ? spreadsheetData.schools
            : { 1: metadata.school };

        const schoolId = pbuSearch.get.teachers.schoolId("db", dbTeacherId);
        const teacherSchool = pbuSearch.get.schools.name("db", schoolId);

        return Object.keys(schools).some(
          (key) => schools[key] === teacherSchool,
        );
      };

      return list.concat(
        Object.keys(databaseData.teachers)
          .filter(
            (dbTeacherId) =>
              !pbuSearch.get.teachers.deleted("db", dbTeacherId) &&
              !pbuSearch.dup.onSSSet("teachers", dbTeacherId) &&
              isInSchool(dbTeacherId),
          )
          .map((dbTeacherId) => {
            return {
              action: "delete",
              id: dbTeacherId,
              key: `db.${dbTeacherId}`,
              name: pbuSearch.get.teachers.name("db", dbTeacherId),
              schoolId: pbuSearch.get.teachers.schoolId("db", dbTeacherId),
              school: pbuSearch.get.teachers.school("db", dbTeacherId),
              levelIds: pbuSearch.get.teachers.levelIds("db", dbTeacherId),
              levels: pbuSearch.get.teachers.levels("db", dbTeacherId),
              typeId: pbuSearch.get.teachers.typeId("db", dbTeacherId),
              type: pbuSearch.get.teachers.type("db", dbTeacherId),
              email: pbuSearch.get.teachers.email("db", dbTeacherId),
            };
          }),
      );
    },

    series: () => {
      return Object.keys(spreadsheetData.series).map((ssSerieId) => {
        const dbLevelId = pbuSearch.dup.onDBSet(
          "levels",
          pbuSearch.get.series.levelId("ss", ssSerieId),
        );

        return {
          action: processedData.action.series(ssSerieId),
          key: ssSerieId,
          label: pbuSearch.get.series.label("ss", ssSerieId),
          levelId: dbLevelId,
          level: pbuSearch.get.levels.name("db", dbLevelId),
        };
      });
    },

    classes: () => {
      return Object.keys(spreadsheetData.classes).map((ssClassId) => {
        const dbLevelId = pbuSearch.dup.onDBSet(
          "levels",
          pbuSearch.get.classes.levelId("ss", ssClassId),
        );
        const dbSerieId = pbuSearch.dup.onDBSet(
          "series",
          pbuSearch.get.classes.serieId("ss", ssClassId),
        );

        const klass = {
          action: processedData.action.classes(ssClassId),
          key: ssClassId,
          label: pbuSearch.get.classes.label("ss", ssClassId),
          levelId: dbLevelId,
          level: pbuSearch.get.levels.name("db", dbLevelId),
          serieId: dbSerieId,
          serie: pbuSearch.get.series.label("db", dbSerieId),
        };

        if (safeMode) {
          klass.schoolId = pbuSearch.get.classes.schoolId("ss", ssClassId);
          klass.school = pbuSearch.get.classes.school("ss", ssClassId);
        } else {
          const dbSchoolId = pbuSearch.dup.onDBSet(
            "schools",
            pbuSearch.get.classes.schoolId("ss", ssClassId),
          );

          klass.schoolId = dbSchoolId;
          klass.school = pbuSearch.get.schools.name("db", dbSchoolId);
        }

        return klass;
      });
    },

    students: () => {
      const list = Object.keys(spreadsheetData.students).map((ssStudentId) => {
        const dbLevelId = pbuSearch.dup.onDBSet(
          "levels",
          pbuSearch.get.students.levelId("ss", ssStudentId),
        );
        const dbSerieId = pbuSearch.dup.onDBSet(
          "series",
          pbuSearch.get.students.serieId("ss", ssStudentId),
        );

        const [action, actionId] = processedData.action.students(ssStudentId);
        const student = {
          action: action,
          id: actionId,
          key: `ss.${ssStudentId}`,
          name: pbuSearch.get.students.name("ss", ssStudentId),
          level: pbuSearch.get.levels.name("db", dbLevelId),
          serie: pbuSearch.get.series.label("db", dbSerieId),
          email: pbuSearch.get.students.email("ss", ssStudentId),
        };

        if (safeMode) {
          student.school = pbuSearch.get.students.school("ss", ssStudentId);
          student.classId = pbuSearch.get.students.classId("ss", ssStudentId);
          student.class = pbuSearch.get.students.class("ss", ssStudentId);
        } else {
          const dbSchoolId = pbuSearch.dup.onDBSet(
            "schools",
            pbuSearch.get.students.schoolId("ss", ssStudentId),
          );
          const dbClassId = pbuSearch.dup.onDBSet(
            "classes",
            pbuSearch.get.students.classId("ss", ssStudentId),
          );

          student.school = pbuSearch.get.schools.name("db", dbSchoolId);
          student.classId = dbClassId;
          student.class = pbuSearch.get.classes.label("db", dbClassId);
        }

        return student;
      });

      if (metadata.goal === "insert") return list;

      const isInSchool = (dbStudentId) => {
        let schools =
          metadata.school === "all"
            ? spreadsheetData.schools
            : { 1: metadata.school };

        const schoolId = pbuSearch.get.students.schoolId("db", dbStudentId);
        const studentSchool = pbuSearch.get.schools.name("db", schoolId);

        return Object.keys(schools).some(
          (key) => schools[key] === studentSchool,
        );
      };

      return list.concat(
        Object.keys(databaseData.students)
          .filter(
            (dbStudentId) =>
              !pbuSearch.get.students.deleted("db", dbStudentId) &&
              !pbuSearch.dup.onSSSet("students", dbStudentId) &&
              isInSchool(dbStudentId),
          )
          .map((dbStudentId) => {
            return {
              action: "delete",
              id: dbStudentId,
              key: `db.${dbStudentId}`,
              name: pbuSearch.get.students.name("db", dbStudentId),
              school: pbuSearch.get.students.school("db", dbStudentId),
              level: pbuSearch.get.students.level("db", dbStudentId),
              serie: pbuSearch.get.students.serie("db", dbStudentId),
              classId: pbuSearch.get.students.classId("db", dbStudentId),
              class: pbuSearch.get.students.class("db", dbStudentId),
              email: pbuSearch.get.students.email("db", dbStudentId),
            };
          }),
      );
    },
  };

  /**
   * updateDatabase
   *
   * Envia a requisição de atualização para o backend para realizar
   * as ações programadas.
   */
  const updateDatabase = {
    run: (dataSub, list, onSuccess, reloadData) => {
      if (list.length === 0) {
        onSuccess();
        return;
      }

      if (safeMode) {
        console.log(list);
        onSuccess();
        return;
      }

      setDataIsReady(false);
      api.instance
        .post(`insertData/${projectId}/${dataSub}`, list)
        .then((r) => {
          updateDatabaseData(metadata.userType);
          onSuccess();
          reloadData();
        })
        .catch((err) => {
          updateDatabaseData(metadata.userType);
          const errMsg =
            "Ocorreu um erro ao atualizar os seus dados. Entre em contato com a TI.";
          alert(`${errMsg} ${err}`);
          setDataIsReady(true);
        });
    },

    schools: (onSuccess = () => {}, reloadData = () => {}) => {
      const list = processedData
        .schools()
        .filter((elem) => elem.action === "insert");
      updateDatabase.run("schools", list, onSuccess, reloadData);
    },

    levels: (onSuccess = () => {}, reloadData = () => {}) =>
      updateDatabase.run("levels", [], onSuccess, reloadData),

    teacherTypes: (onSuccess = () => {}, reloadData = () => {}) =>
      updateDatabase.run("teacherTypes", [], onSuccess, reloadData),

    teachers: (onSuccess = () => {}, reloadData = () => {}) => {
      const list = processedData
        .teachers()
        .filter((elem) => elem.action !== "ok");
      updateDatabase.run("teachers", list, onSuccess, reloadData);
    },

    series: (onSuccess = () => {}, reloadData = () => {}) =>
      updateDatabase.run("series", [], onSuccess, reloadData),

    classes: (onSuccess = () => {}, reloadData = () => {}) => {
      const list = processedData
        .classes()
        .filter((elem) => elem.action !== "ok");
      updateDatabase.run("classes", list, onSuccess, reloadData);
    },

    students: (onSuccess = () => {}, reloadData = () => {}) => {
      const list = processedData
        .students()
        .filter((elem) => elem.action !== "ok");
      updateDatabase.run("students", list, onSuccess, reloadData);
    },
  };

  /**
   * wizardNextStep
   *
   * Configura a ordem a ser mostrada no Wizard com base nos metadados
   */
  const wizardNextStep = (currentStep) => {
    switch (currentStep) {
      case 0:
        return metadata.school === "all" ? 1 : 2;
      case 1:
        return 2;
      case 2:
        return metadata.userType === "Alunos" ? 5 : 3;
      case 3:
        return 4;
      case 4:
        return metadata.userType === "Educadores" ? 8 : 5;
      case 5:
        return 6;
      case 6:
        return 7;
      case 7:
        return 8;
      default:
        return 0;
    }
  };

  /**
   * removeData
   *
   *
   */
  const removeData = {
    teachers: (key) => {
      const [dataSet, id] = key.split(".");

      if (dataSet === "db") {
        const newData = { ...databaseData };
        newData.teacherSchools[
          pbuSearch.get.teachers.teacherSchoolId("db", id)
        ].deleted = true;
        setDatabaseData(newData);
      } else if (dataSet === "ss") {
        const newData = { ...spreadsheetData };
        delete newData.teachers[id];
        delete newData.teacherSchools[
          pbuSearch.get.teachers.teacherSchoolId("ss", id)
        ];
        setSpreadsheetData(newData);
      }
    },

    students: (key) => {
      const [dataSet, id] = key.split(".");

      if (dataSet === "db") {
        const newData = { ...databaseData };
        newData.studentClasses[
          pbuSearch.get.students.studentClassId("db", id)
        ].deleted = true;
        setDatabaseData(newData);
      } else if (dataSet === "ss") {
        const newData = { ...spreadsheetData };
        delete newData.students[id];
        delete newData.studentClasses[
          pbuSearch.get.students.studentClassId("ss", id)
        ];
        setSpreadsheetData(newData);
      }
    },
  };

  return (
    <ProjectBulkUpdateContext.Provider
      value={{
        loadSSData,
        updateSSData,
        updateDatabase,
        processedData,
        removeData,
        databaseData,
        dataIsLoaded,
        dataIsReady,
        wizardNextStep,
        safeMode,
        toggleSafeMode,
        goal: metadata.goal,
        changeGoal,
        fuzzyData,
        fuzzySSSchoolsReady,
        fuzzyDBSchoolsReady,
        fuzzySSTeachersReady,
        fuzzyDBTeachersReady,
        fuzzySSStudentsReady,
        fuzzyDBStudentsReady,
      }}
    >
      {props.children}
    </ProjectBulkUpdateContext.Provider>
  );
};

export default ProjectBulkUpdateProvider;
