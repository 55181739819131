import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ErrorMessage as FormikErrorMessage,
  Field as FormikField,
} from "formik";
import { forwardRef, useState } from "react";
import styles from "./ClickFileInput.module.scss";

const ClickFileInputWithFormik = forwardRef(
  ({ name, withFormik, validate, onChange, ...otherProps }, ref) => {
    if (withFormik) {
      return (
        <div>
          <FormikField name={name} validate={validate}>
            {({ field, form }) => (
              <ClickFileInput
                {...otherProps}
                {...field}
                onChange={(evt) => {
                  form.setFieldValue(name, evt.target.files[0]);
                  onChange && onChange(evt);
                }}
                onBlur={() => {
                  form.setFieldTouched(name, true);
                }}
              />
            )}
          </FormikField>
          <div className={styles.errorMessage}>
            <FormikErrorMessage name={name} />
          </div>
        </div>
      );
    }
    return <ClickFileInput {...otherProps} name={name} onChange={onChange} />;
  },
);

const ClickFileInput = forwardRef(
  ({ id, name, label, accept, onChange, onBlur }, ref) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [fileName, setFileName] = useState(null);
    return (
      <div className={styles.wrapper}>
        {imageSrc && <img className={styles.preview} alt="" src={imageSrc} />}
        {fileName && <span className={styles.name}>{fileName}</span>}
        <input
          value={undefined}
          name={name}
          ref={ref}
          id={id}
          type="file"
          accept={accept}
          onBlur={onBlur}
          onChange={(evt) => {
            const file = evt.target.files[0];
            if (file) {
              setFileName(file.name);
              if (accept === "image/*") {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener(
                  "load",
                  () => {
                    setImageSrc(reader.result);
                  },
                  false,
                );
              }
            }
            onChange(evt);
          }}
        />
        <label htmlFor={id}>
          <div className={styles.icon}>
            <FontAwesomeIcon icon="folder-open" />
          </div>
          <div className={styles.label}>{label}</div>
        </label>
      </div>
    );
  },
);

export default ClickFileInputWithFormik;
