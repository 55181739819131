import { Record } from "immutable";
import { combineReducers } from "redux-immutable";
import listExercises from "./List/ListExercises.reducer";
import createExerciseList from "./CreateList/CreateList.reducer";

const OnlineExercisesRecord = Record({
  listExercises: new listExercises.defaultState(),
  createExerciseList: new createExerciseList.defaultState()
});

const OnlineExercisesReducer = combineReducers(
  {
    listExercises,
    createExerciseList
  },
  OnlineExercisesRecord
);

OnlineExercisesReducer.defaultState = OnlineExercisesRecord;

export default OnlineExercisesReducer;
