/** @module Feedback */
import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback
} from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import Feedback from "./Feedback.view";

const FeedbackContext = createContext({
  feedbacks: [],
  addFeedback: () => {},
  removeFeedback: () => {}
});

/**
 *  Feedbacks Context provider component. You must wrap your app into this component to use it's
 *  context addFeedback function to create a feedback to be shown to the user.
 * @param {node} props.children This component will wrap the children on a ContextProvider
 * @memberof Feedback
 */
const FeedbackContainer = ({ children }) => {
  const [feedbacksList, setFeedbacksList] = useState([]);
  const addFeedback = useCallback((message, type, actions) => {
    setFeedbacksList(prevFeedbacksList => {
      let id = Math.floor(Date.now() + Math.random()).toString(36);
      const findCallback = ({ id: fbId }) => fbId === id;
      while (prevFeedbacksList.find(findCallback)) {
        id = Math.floor(Date.now() + Math.random()).toString(36);
      }
      return [
        ...prevFeedbacksList,
        {
          id,
          message,
          type,
          actions
        }
      ];
    });
  }, []);
  const removeFeedback = useCallback(
    id =>
      setFeedbacksList(feedbacksList.filter(feedback => feedback.id !== id)),
    [feedbacksList]
  );

  // begin alternative options to enable addFeedback to be called from redux's action creator
  const prevFeedbacksList = useRef([]);
  const memoizedContextValue = useRef({
    feedbacks: feedbacksList,
    addFeedback,
    removeFeedback
  });

  useEffect(() => {
    if (feedbacksList !== prevFeedbacksList.current) {
      memoizedContextValue.current = {
        feedbacksList,
        addFeedback,
        removeFeedback
      };
      prevFeedbacksList.current = feedbacksList;
      return () => (prevFeedbacksList.current = feedbacksList);
    }
  }, [addFeedback, feedbacksList, removeFeedback]);

  useEffect(() => {
    FeedbackContext.addFeedback = addFeedback;
  }, [addFeedback]);
  // end alternative options to enable addFeedback to be called from redux's action creator

  return (
    <FeedbackContext.Provider value={memoizedContextValue.current}>
      {createPortal(
        <div className="feedbackHolder">
          {feedbacksList
            ? feedbacksList.map(feedback => (
                <Feedback
                  {...feedback}
                  key={feedback.id}
                  removeFeedback={() => removeFeedback(feedback.id)}
                />
              ))
            : null}
        </div>,
        document.getElementById("feedbacks-root")
      )}
      {children}
    </FeedbackContext.Provider>
  );
};

FeedbackContainer.propTypes = {
  children: PropTypes.node
};

export { FeedbackContext };
export default FeedbackContainer;
