import api from "core/api";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "views/User.context";

const Trieduc = () => {
	const user = useContext(UserContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		if(user.id && user.school) {
			api.instance.get("trieduc/redirect")
			.then(({data}) => window.location = `https://dev-smartprova-v2.azurewebsites.net/login-integracao?token=${data}`)
			.catch((err) => {
				console.error(err);
				setError(err);
			});
		}
	}, [user.id, user.school]);

	return error ? "Houve um erro, atualize a página ou tente novamente mais tarde" : "Redirecionando login...";
};


export default Trieduc;