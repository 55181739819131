import { Record, List } from "immutable";

// const MultipleChoiceOptionRecord = Record({
//   text: "",
//   correct: false
// });

// const MultipleChoiceExerciseRecord = Record({
//   type: "multiple",
//   statement: "",
//   answers: new List()
// });

// const EssayExerciseRecord = Record({
//   type: "essay",
//   statement: "",
//   suggestedAnswer: ""
// });

const CreateRecord = Record({
  type: "list",
  educationalLevel: undefined, // used to filter the subjects
  subject: undefined,
  title: "",
  exercises: new List()
});

const CreateReducer = (state, action) => {
  switch (action.type) {
    case "view.onlineExercises.Create.FormUpdated":
      return state.merge(action.payload);
    // this code will be used in future implementation
    // case "onlineExercisesCreateExerciseAdded":
    //   return state.set(
    //     "exercises",
    //     state.exercises.push(
    //       new ExerciseRecord({
    //         statement: action.payload.statement,
    //         answers: new List(
    //           action.payload.answers.map(
    //             answer =>
    //               new AnswerRecord({
    //                 text: answer.text,
    //                 correct: answer.correct
    //               })
    //           )
    //         )
    //       })
    //     )
    //   );
    default:
      return state;
  }
};

CreateReducer.defaultState = CreateRecord;

export default CreateReducer;
