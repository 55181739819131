/**
 * @module ClickTagColor
 */

import React from "react";
import PropTypes from "prop-types";
import styles from "./ClickTagColorGroup.module.scss";
import ClickTagColor from ".";
import ClickTooltip from "../ClickTooltip";
import classnames from "classnames";
/**
 * @description Container for rendering a list of TagColors
 * @param {Object} props
 * @param {Array.Object} props.tags - object structure defined in propTypes.
 * @param {number} props.max - The maximum of TagColors to display
 * @memberof module:ClickTagColor
 */
const ClickTagColorGroup = ({ tags, max, className }) => (
  <div
    className={classnames(styles.tagColorContainer, className)}
    aria-label={tags.map(tag => tag.title).join(", ")}
  >
    {tags.slice(0, max).map((tag, i) => (
      <ClickTagColor key={i} colorClass={tag.colorClass} title={tag.title}>
        {tag.label}
      </ClickTagColor>
    ))}
    {tags.length > max && (
      <ClickTooltip
        content={
          <ul>
            {tags.slice(max).map((tag, index) => (
              <li key={index}>{tag.title}</li>
            ))}
          </ul>
        }
      >
        {ref => (
          <span ref={ref} className={styles.moreTags}>
            +{tags.length - max}
          </span>
        )}
      </ClickTooltip>
    )}
  </div>
);

ClickTagColorGroup.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      colorClass: PropTypes.string,
      title: PropTypes.string
    })
  ),
  max: PropTypes.number
};

export default ClickTagColorGroup;
