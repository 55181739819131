import React, { useEffect, useState, createContext, useCallback } from "react";

export const BreadcrumbContext = createContext();

const BreadcrumbProvider = ({ children }) => {
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState();
  const [contextValue, setContextValue] = useState({
    breadcrumb: undefined,
    setBreadcrumb: () => {}
  });

  const setBreadcrumb = useCallback(breadcrumb => {
    setCurrentBreadcrumb(breadcrumb);
  }, []);

  useEffect(() => {
    let title = "";
    if (currentBreadcrumb) {
      title = currentBreadcrumb.reduce(
        (title, { label, current }) =>
          current && label ? `${label} - ` : title,
        ""
      );
    }
    document.title = `${title}Clickideia`;

    setContextValue({
      breadcrumb: currentBreadcrumb,
      setBreadcrumb: setBreadcrumb
    });
  }, [currentBreadcrumb, setBreadcrumb]);
  return (
    <BreadcrumbContext.Provider value={contextValue}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export default BreadcrumbProvider;
