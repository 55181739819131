import FeedbackManager from "components/Feedback";
import A11yProvider from "contexts/A11y.context";
import AuthProvider from "contexts/Auth.context";
import MenuProvider from "contexts/Menu.context";
import ThemeProvider from "contexts/Theme.context";
import UserProvider from "contexts/User.context";
import NotificationsProvider from "contexts/Notifications.context";
import BreadcrumbProvider from "views/Breadcrumb.context";
import SubjectsProvider from "views/Subjects.context";

const Providers = ({ children }) => (
  <FeedbackManager>
    <A11yProvider>
      <ThemeProvider>
        <AuthProvider>
          <UserProvider>
            <MenuProvider>
              <NotificationsProvider>
                <BreadcrumbProvider>
                  <SubjectsProvider>{children}</SubjectsProvider>
                </BreadcrumbProvider>
              </NotificationsProvider>
            </MenuProvider>
          </UserProvider>
        </AuthProvider>
      </ThemeProvider>
    </A11yProvider>
  </FeedbackManager>
);

export default Providers;
