/**
 * @module ClickMultiselectDropdown
 */
import classnames from "classnames";
import ClickButton from "components/ClickButton";
import {
  ErrorMessage as FormikErrorMessage,
  Field as FormikField,
} from "formik";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ClickDropdown, createEvent } from ".";
import styles from "./ClickMultiselectDropdown.module.scss";

export const ClickMultiselectDropdownWithFormik = ({
  name,
  validate,
  withFormik,
  onChange,
  ...otherProps
}) => {
  if (withFormik) {
    return (
      <div>
        <FormikField
          name={name}
          validate={validate}
          render={({ field /*, form */ }) => (
            <ClickMultiselectDropdown
              {...otherProps}
              {...field}
              onChange={(evt) => {
                field.onChange(evt);
                onChange && onChange(evt);
              }}
            />
          )}
        />
        <div className={styles.errorMessage}>
          <FormikErrorMessage name={name} />
        </div>
      </div>
    );
  } else {
    return (
      <ClickMultiselectDropdown
        {...otherProps}
        name={name}
        onChange={onChange}
      />
    );
  }
};

/**
 * @description A component that given a list of options, is able to select multiple of them using a dropdown listbox
 * @param { Object } props - All props of ClickMultiselectDropdown component
 * @param { string } props.id - Base string for the id's of the html elements of this compoenent. Mandatory for acessibility
 * @param { string } props.name - onChange and onBlur events will me called with this value on evt.target.name. Mandatory for withFormik
 * @param { string } props.label - Form label to be rendered with this component
 * @param { string } props.dropdownLabel - Aria label to be applied on the dropdown
 * @param { string } props.helpText - Form helptext element to be rendered with this component
 * @param { Object } props.tooltip - Tooltip element to be applied when focusing the dropdown
 * @param { string } props.placeholder - String to be written inside the dropdown while closed.
 * @param { Array } props.options - Array of possible options for this component. Options must have a label and value
 * @param { Array } props.value - An array of strings matching the options' values
 * @param { function } props.onChange - Callback function to be called when an option is added or removed
 * @param { function } props.onBlur - Callback function to be called when the user leaves this component or adds a option
 * @param { bool } props.valuesAsTags - Boolean that indicates if should render the selected options as tags instead of the default table style
 * @param { bool } props.withFormik - Boolean that indicates if this component should implement a Formik integration
 * @param { function } props.renderSelectedOption - function that returns how to render a selected option
 * @param { function } props.renderOption - function that returns how to render an option inside the dropdown
 * @param { function } props.selectedItemClassName - function that returns the classname to be applied for a given option on the valuesAsTags mode
 * @param { string } props.noSelectedItemsMessage - Message to be displayed when there is no option selected
 *
 * @memberof module:ClickMultiselectDropdown
 */
const ClickMultiselectDropdown = ({
  id,
  name,
  label,
  dropdownLabel,
  helpText,
  tooltip,
  placeholder,
  options,
  value,
  onChange,
  onBlur,
  dark,
  valuesAsTags,
  renderSelectedOption,
  renderOption,
  selectedItemClassName,
  noSelectedItemsMessage,
}) => {
  const [focusedCell, setFocusedCell] = useState({ row: 0, column: 0 });

  // isolated calc of an array of selected and unselected options
  const [selectedOptions, availableOptions] = useMemo(() => {
    const valueSet = new Set();
    value.forEach((val) => valueSet.add(val));
    const available = [];
    const selected = [];
    options.forEach((option) => {
      if (valueSet.has(option.value)) {
        selected.push(option);
      } else {
        available.push(option);
      }
    });
    return [selected, available];
  }, [options, value]);

  const cellsRefs = useRef([]);
  const dropdownRef = useRef();
  const focusableRefs = useRef([]);

  // update cellRefs when necessary
  if (selectedOptions.length !== cellsRefs.current.length) {
    cellsRefs.current = Array.from({ length: selectedOptions.length }, () => [
      null,
      null,
    ]);
  }

  // update fosucableRefs
  useEffect(() => {
    focusableRefs.current = [];
    cellsRefs.current.forEach((row) => {
      if (row[0]) {
        focusableRefs.current.push(row[0]);
      }
      if (row[1]) {
        focusableRefs.current.push(row[1]);
      }
    });
    if (dropdownRef.current) {
      focusableRefs.current.push(dropdownRef.current);
    }
  });

  // reset focusedCell when options change
  useEffect(() => {
    // conditional just to proc the effect
    if (options.length >= 0) {
      setFocusedCell({ row: 0, column: 0 });
    }
  }, [options]);

  // focus when focusedCell changes
  useEffect(() => {
    let targetCell =
      cellsRefs.current[focusedCell.row] &&
      cellsRefs.current[focusedCell.row][focusedCell.column];
    if (targetCell !== undefined) {
      if (targetCell && typeof targetCell.focus === "function") {
        if (
          focusableRefs.current.includes(targetCell.ownerDocument.activeElement)
        ) {
          targetCell.focus();
        }
      }
    }
  }, [focusedCell]);

  // handler to select a new entry
  const addOption = useCallback(
    (value, values) => {
      onChange(createEvent(name, [...values, value]));
    },
    [name, onChange],
  );

  // handler to select a new entry
  const deleteOption = useCallback(
    (valueToDelete, values) => {
      onChange(
        createEvent(
          name,
          values.filter((el) => valueToDelete !== el),
        ),
      );
    },
    [name, onChange],
  );

  // keyDown handler on grid cells
  const handleCellKeyDown = useCallback(
    (evt, row, column, values, focusedValue) => {
      switch (evt.key) {
        case "Delete": {
          deleteOption(focusedValue, values);
          break;
        }
        case "ArrowLeft": {
          evt.preventDefault();
          // if need to line wrap
          if (column === 0) {
            if (row > 0) {
              setFocusedCell({ row: row - 1, column: 1 });
            }
          } else {
            setFocusedCell({ row: row, column: column - 1 });
          }
          break;
        }
        case "ArrowRight": {
          evt.preventDefault();
          // if need to line wrap
          if (column === 1) {
            if (row < values.length - 1) {
              setFocusedCell({ row: row + 1, column: 0 });
            }
          } else {
            setFocusedCell({ row: row, column: column + 1 });
          }
          break;
        }
        case "ArrowUp": {
          evt.preventDefault();
          row = Math.max(0, row - 1);
          setFocusedCell({ row, column });
          break;
        }
        case "ArrowDown": {
          evt.preventDefault();
          row = Math.min(values.length, row + 1);
          setFocusedCell({ row, column });
          break;
        }
        case "Home": {
          evt.preventDefault();
          column = 0;
          if (evt.ctrlKey) {
            row = 0;
          }
          setFocusedCell({ row, column });
          break;
        }
        case "End": {
          evt.preventDefault();
          column = 1;
          if (evt.ctrlKey) {
            row = values.length - 1;
          }
          setFocusedCell({ row, column });
          break;
        }
        default: {
          break;
        }
      }
    },
    [deleteOption],
  );

  // callback called when there is a blur on any focusable element
  const handleBlur = useCallback(
    (evt) => {
      // checks if the focus have moved to an external element
      if (!focusableRefs.current.includes(evt.relatedTarget)) {
        // override the target.name on the event
        onBlur(createEvent(name, value, evt));
      }
    },
    [name, onBlur, value],
  );

  return (
    <div
      className={classnames(styles.ClickMultiselectDropdown, {
        [styles.dark]: dark,
      })}
    >
     
      <ClickDropdown
        id={`${id}-dropdown`}
        ariaLabel={`${
          value.length === 0 ? noSelectedItemsMessage + " " : ""
        }${dropdownLabel}`}
        dark={dark}
        options={availableOptions}
        optionRender={renderOption}
        onBlur={handleBlur}
        placeholder={placeholder}
        onChange={(evt) => addOption(evt.target.value, value)}
        ref={dropdownRef}
        tooltip={{ placement: "bottom", ...tooltip }}
      />
      {!!label && (
        <label
          id={`${id}-label`}
          htmlFor={`${id}-dropdown-button`}
          className={styles.label}
        >
          {label}
        </label>
      )}
      {!!helpText && (
        <p id={`${id}-helptext`} className={styles.helpText}>
          {helpText}
        </p>
      )}
      {selectedOptions.length > 0 ? (
        <div
          role="grid"
          aria-labelledby={`${id}-label`}
          id={`${id}-selected-options-tags`}
          className={valuesAsTags ? styles.tagsMode : styles.tableMode}
        >
          {selectedOptions.map((opt, index) => (
            <div role="row" key={index} className={selectedItemClassName(opt)}>
              <span
                id={`${id}-${index}-label`}
                role="gridcell"
                ref={(el) => {
                  if (el) {
                    cellsRefs.current[index][0] = el;
                  }
                }}
                tabIndex={
                  focusedCell.row === index && focusedCell.column === 0 ? 0 : -1
                }
                onKeyDown={(evt) =>
                  handleCellKeyDown(evt, index, 0, value, opt.value)
                }
                onBlur={handleBlur}
              >
                {renderSelectedOption(opt)}
              </span>
              <span role="gridcell">
                <ClickButton
                  icon="trash"
                  size="small"
                  color={valuesAsTags ? undefined : "danger"}
                  background={dark || valuesAsTags ? "dark" : undefined}
                  fill="simple"
                  id={`${id}-${index}-remove`}
                  tabIndex={
                    focusedCell.row === index && focusedCell.column === 1
                      ? 0
                      : -1
                  }
                  onKeyDown={(evt) =>
                    handleCellKeyDown(evt, index, 1, value, opt.value)
                  }
                  onClick={() => {
                    deleteOption(opt.value, value);
                    onBlur(createEvent(name, value));
                  }}
                  onBlur={handleBlur}
                  ref={(el) => {
                    if (el) {
                      cellsRefs.current[index][1] = el;
                    }
                  }}
                  label="Remover"
                  tooltip={{ placement: valuesAsTags ? "bottom" : "right" }}
                  aria-labelledby={`${id}-${index}-remove ${id}-${index}-label`}
                />
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div>{noSelectedItemsMessage}</div>
      )}
    </div>
  );
};

ClickMultiselectDropdown.defaultProps = {
  dropdownLabel: "Adicionar uma opção ",
  onBlur: () => {},
  renderSelectedOption: (opt) => opt.label,
  selectedItemClassName: () => undefined,
  dark: false,
  valuesAsTags: false,
  withFormik: false,
  noSelectedItemsMessage: "Nenhum item selecionado.",
};

ClickMultiselectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  dropdownLabel: PropTypes.string,
  helpText: PropTypes.string,
  tooltip: PropTypes.object,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.string,
      plaintextLabel: PropTypes.string,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  valuesAsTags: PropTypes.bool,
  renderSelectedOption: PropTypes.func,
  renderOption: PropTypes.func,
  withFormik: PropTypes.bool,
  selectedItemClassName: PropTypes.func,
  noSelectedItemsMessage: PropTypes.node,
};

ClickMultiselectDropdownWithFormik.defaultProps =
  ClickMultiselectDropdown.defaultProps;
ClickMultiselectDropdownWithFormik.propTypes =
  ClickMultiselectDropdown.propTypes;

export default ClickMultiselectDropdownWithFormik;
