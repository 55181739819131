import ClickButton from "components/ClickButton";
import { UserContext } from "contexts/User.context";
import { useContext } from "react";
import styles from "./SearchBar.module.scss";

const SearchBar = () => {
  const user = useContext(UserContext);
  return !user.type ? (
    ""
  ) : (
    <form method="GET" action="/busca" className={styles.searchBar}>
      <input
        type="text"
        name="searchtext"
        aria-label="Pesquisar"
        placeholder="Pesquisar..."
        className={styles.searchInput}
      />
      <ClickButton
        icon="search"
        label="Pesquisar"
        type="submit"
        size="small"
        fill="simple"
        background="dark"
        className={styles.searchButton}
      />
    </form>
  );
};

export default SearchBar;
