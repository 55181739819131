import "animate.css";
import classnames from "classnames";
import { applyRef } from "core";
import "focus-visible";
import React, {
  CSSProperties,
  FocusEvent,
  ReactNode,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Popper } from "react-popper";
import styles from "./ClickTooltip.module.scss";
import { Placement } from "@popperjs/core";

interface TooltipProps {
  style: CSSProperties;
  placement: Placement;
  arrowProps: any /** @todo type */;
  children: ReactNode;
  tooltipRef: any /** @todo type */;
  className?: string;
}

interface ClickTooltipProps {
  content: ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  strategy?: "absolute" | "fixed";
  className?: string;
  triggerRef: RefObject<any>;
  children: (element: any) => void /** @todo type */;
}

/**
 * The rendered tooltip box with it's arrow pointer
 */
const Tooltip = forwardRef<any, TooltipProps>(
  (
    { style, placement, arrowProps, children, tooltipRef, className },
    popperRef,
  ) => {
    const containerClassnames = classnames(
      className,
      styles.tooltipContainer,
      "animated",
      "fadeIn",
      {
        [styles.vertical]: placement === "bottom" || placement === "top",
        [styles.horizontal]: placement === "left" || placement === "right",
      },
    );
    const arrowClassnames = classnames(styles.tooltipArrow, {
      [styles[placement]]: placement,
    });
    return (
      <div
        ref={(element) => {
          applyRef(element, tooltipRef);
          applyRef(element, popperRef);
        }}
        style={style}
        data-placement={placement}
        className={styles.tooltipWrap}
      >
        <div className={containerClassnames}>
          <div
            className={arrowClassnames}
            ref={arrowProps.ref}
            style={arrowProps.style}
          />
          {children}
        </div>
      </div>
    );
  },
);

/**
 * @description Generic Tooltip component. This shows the tooltip's content on hover and on focus by keyboard.
 * @param {node} [props.content] - Content of tooltip
 * @param {"top" | "bottom" | "left" | "right"} [props.placement="bottom"] - Placement of tooltip related to children
 * @param {"absolute" | "fixed"} [props.strategy="absolute"] - Placement strategy
 * @param {string} [props.className] - CSS class name to be forwarded to tooltip container
 * @param {string} [props.triggerRef] - Reference to be used on the element that procs the tooltip
 * @param {func} props.children - A function that receives a ref, and must return a JSX that attaches this ref to some html element
 * @memberof module:ClickTooltip
 */
const ClickTooltip = React.forwardRef<any, ClickTooltipProps>(
  (
    {
      children,
      className,
      placement = "bottom",
      strategy = "absolute",
      content,
      triggerRef,
    },
    tooltipRef,
  ) => {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const internalTriggerRef = useRef<any>();

    const handleMouseEnter = useCallback(() => {
      setIsMouseOver(true);
    }, []);
    const handleMouseLeave = useCallback(() => {
      setIsMouseOver(false);
    }, []);
    const handleFocus = useCallback((e: FocusEvent) => {
      if (e.target.classList.contains("focus-visible")) {
        setIsFocused(true);
      }
    }, []);
    const handleBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    useEffect(() => {
      if (
        internalTriggerRef.current &&
        internalTriggerRef.current.addEventListener
      ) {
        const element = internalTriggerRef.current;
        const options = { passive: false };

        element.addEventListener("mouseenter", handleMouseEnter, options);
        element.addEventListener("mouseleave", handleMouseLeave, options);
        let elementToFocus = element;
        if (element.nodeName === "LABEL") {
          elementToFocus = element.control;
        }
        elementToFocus.addEventListener("focus", handleFocus, options);
        elementToFocus.addEventListener("blur", handleBlur, options);

        return () => {
          element.removeEventListener("mouseenter", handleMouseEnter, options);
          element.removeEventListener("mouseleave", handleMouseLeave, options);
          elementToFocus.removeEventListener("focus", handleFocus, options);
          elementToFocus.removeEventListener("blur", handleBlur, options);
        };
      }
    }, [handleBlur, handleFocus, handleMouseEnter, handleMouseLeave]);

    return (
      <>
        {children((element: any) => {
          applyRef(element, internalTriggerRef);
          applyRef(element, triggerRef);
        })}
        {(isMouseOver || isFocused) &&
          internalTriggerRef.current &&
          !internalTriggerRef.current.disabled && (
            <Popper
              // positionFixed={true}
              placement={placement}
              strategy={strategy}
              referenceElement={internalTriggerRef.current}
            >
              {(props) => (
                <Tooltip
                  {...props}
                  className={className}
                  tooltipRef={tooltipRef}
                >
                  {content}
                </Tooltip>
              )}
            </Popper>
          )}
      </>
    );
  },
);

export default ClickTooltip;
