import React, { useState, useContext } from "react";
import { api } from "core";
import { UserManagementContext } from "views/UserManagement/UserManagement.context";

const DeactivateProject = ({ project }) => {
  const [deactivating, setDeactivating] = useState(false);
  const { reloadProjects } = useContext(UserManagementContext);

  if (deactivating) return "Desativando...";

  const deactivate = () => {
    const promptText = `Digite o nome do projeto para confirmar a exclusão: ${project.nome}`;
    if (prompt(promptText) !== project.nome) {
      alert("O nome do projeto não corresponde ao digitado");
      return;
    }

    setDeactivating(true);

    api.instance
      .put(`deactivate/${project.id}`)
      .then(r => {
        reloadProjects(() => setDeactivating(false));
      })
      .catch(err => {
        alert(
          `Ocorreu um erro ao criar o novo projeto. Entre em contato com a TI. ${err}`
        );
        setDeactivating(false);
      });
  };

  const nrUsers = project.educadores + project.funcionarios + project.alunos;
  return nrUsers !== 0 ? (
    <button onClick={deactivate}>Desativar projeto</button>
  ) : (
    "-"
  );
};

export default DeactivateProject;
