import React from "react";

import "./FormInput.scss";

import ClickButton from "components/ClickButton";
import ClickSelect from "components/ClickSelect/ClickSelect";

/** The button input is used to fire an function when clicked. The onChange
 * is called with the button value when it happens and the button value may change
 */
const ButtonInput = ({ onChange, data, submit }) => (
  <ClickButton
    onClick={onChange && onChange.bind(undefined, data.value)}
    submit={submit}
  >
    {data.label}
  </ClickButton>
);

/** The button group works the same as the Dropdown input,
 * but it renders as buttons
 */
const ButtonGroup = ({ onChange, data, selectedValue }) => (
  <div>
    {data.map((buttonData, index) => (
      <ClickButton
        key={index}
        className={selectedValue === buttonData.value ? " selected" : ""}
        onClick={onChange.bind(undefined, buttonData.value)}
      >
        {buttonData.label}
      </ClickButton>
    ))}
  </div>
);

/** Drop down input. must have a data array with all options {label, value}
 */
const Dropdown = ({ id, onChange, data, value }) => (
  <ClickSelect
    id={id}
    options={data}
    onChange={option => onChange(option.value)}
    value={value}
  />
);

/** The text input. the onchange event is fired at each character inserted.
 * TODO: create smart logic to fire the change only when user stops typing
 * or leaves the field
 */
const Text = ({ id, onChange, value, className, placeholder, ...props }) => (
  <input
    id={id}
    type="text"
    className={className || "FormTextInput"}
    value={value}
    onChange={event => onChange(event.target.value)}
    placeholder={placeholder}
    {...(props.autocapitalize ? null : { autoCapitalize: "none" })}
  />
);

/** The text input. the onchange event is fired at each character inserted.
 * TODO: create smart logic to fire the change only when user stops typing
 * or leaves the field
 */
const Password = ({ id, onChange, value }) => (
  <input
    id={id}
    type="password"
    className="FormTextInput"
    value={value}
    onChange={event => onChange(event.target.value)}
  />
);

/** Component that renders all form inputs. The props are:
 * label: label that goes with the input
 * type: type of input (button, buttonGroup, dropdown, text)
 * value: the current value of the input
 * onChange(value): this function will be called when the input value changes
 * data: Object or array of object with {label, value} as options for the input
 */
const FormInput = ({
  id,
  label: inputLabel,
  inline,
  type,
  value,
  ...props
}) => (
  <span className="FormInput">
    {!!inputLabel && (
      <label className="Inputlabel" htmlFor={id}>
        {inputLabel}
      </label>
    )}
    {(() => {
      switch (type) {
        case "button":
          return <ButtonInput id={id} {...props} />;
        case "buttonGroup":
          return <ButtonGroup id={id} selectedValue={value} {...props} />;
        case "dropdown":
          return <Dropdown id={id} value={value} {...props} />;
        case "text":
          return <Text id={id} value={value} {...props} />;
        case "password":
          return <Password id={id} value={value} {...props} />;
        case "submit":
          return <ButtonInput id={id} submit {...props} />;
        default:
          return "";
      }
    })()}
  </span>
);

export default FormInput;
