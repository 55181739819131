/**
 * @module ClickTable
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ClickLink from "components/ClickLink";
import { objToQuery, queryToObj } from "core/";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./ClickTable.module.scss";

const GridCell = forwardRef(
  (
    {
      children,
      setFocusedCell,
      link,
      tabIndex,
      withDetails,
      expandedRow,
      setExpandedRow,
      row,
      scrollOnFocus = true,
      ...props
    },
    ref
  ) => {
    const cellRef = useRef();
    useImperativeHandle(ref, () => ({
      focus: () => {
        cellRef.current.focus({ preventScroll: true });
      },
    }));

    const handleFocus = (evt) => {
      setFocusedCell();
      if (scrollOnFocus) {
        const scrollOptions = {
          behavior: "smooth",
          block: "nearest",
          inline: "end",
        };
        if (link === undefined) {
          evt.target.scrollIntoView(scrollOptions);
        } else {
          evt.target.parentNode.scrollIntoView(scrollOptions);
        }
      }
    };
    if (withDetails) {
      return (
        <span role="gridcell" {...props}>
          <button
            id={`detailsBtn${row}`}
            className={styles.toggleDetails}
            onClick={() => setExpandedRow(expandedRow !== row ? row : -1)}
            ref={cellRef}
            onFocus={handleFocus}
            tabIndex={tabIndex}
            aria-controls={`detaisPanel${row}`}
            type="button"
          >
            <FontAwesomeIcon
              icon={expandedRow === row ? "chevron-up" : "chevron-down"}
              className={styles.chevron}
            />{" "}
            {children}
          </button>
        </span>
      );
    }
    return link !== undefined ? (
      <span role="gridcell" {...props}>
        <ClickLink
          link={link}
          ref={cellRef}
          onFocus={handleFocus}
          tabIndex={tabIndex}
        >
          {children}
        </ClickLink>
      </span>
    ) : (
      <span
        role="gridcell"
        ref={cellRef}
        onFocus={handleFocus}
        tabIndex={tabIndex}
        {...props}
      >
        {children}
      </span>
    );
  }
);

/**
 * @description Table component for generic data
 * @param {Object} props - All props of this Component
 * @param { Array.<Object> } props.columns - List of columns settings and options
 * @param { string } props.columns.label - Column header label
 * @param { string } [props.columns.className] - Cell classname
 * @param { func } props.columns.render - Render function of each gridcell. Receive data object as param
 * @param { func } props.columns.link - URL generator for cell. Receive row as param
 * @param { "start" | "center" | "end" } [props.columns.align] - Column alignment
 * @param { number } [props.columns.size] - Column proportional size
 * @param { Array.<Object> } props.data - Data to be displayed
 * @param { string } props.label - Label of whole table
 * @param { bool } [props.isLoading] - if has loading content
 * @param { func } [props.emptyRender] - Message to show in case of empty data
 * @todo Change name, export and remove old table. Needs changes in learningRoadmaps
 * @memberof module:ClickTable
 */
const Table = ({ className, data, label, columns, isLoading, emptyRender }) => {
  const [focusedCell, setFocusedCell] = useState({ row: 0, column: 0 });
  const cellsRefs = useRef([]);

  // States and refs to deal with expand and colapse details
  const detailsRef = useRef([]);
  const [expandedRow, setExpandedRow] = useState(-1);
  const [detailsHeight, setDetailsHeight] = useState(0);
  const { search } = useLocation();
  const history = useHistory();
  const { orderBy, orderDirection } = queryToObj(search);

  const hasDetails = data.reduce(
    (acc, row) => acc || typeof row.details === "function",
    false
  );

  useLayoutEffect(() => {
    const currentRow = detailsRef.current[expandedRow];
    if (currentRow) {
      const finalHeight = `${currentRow.scrollHeight}px`;
      setDetailsHeight(finalHeight);
    }
  }, [expandedRow]);

  // Deal with height resize in the details row
  const handleDetailsHeightUpdate = useCallback(() => {
    if (
      expandedRow > -1 &&
      detailsHeight !== detailsRef.current[expandedRow].scrollHeight
    ) {
      setDetailsHeight(detailsRef.current[expandedRow].scrollHeight);
    }
  }, [detailsHeight, expandedRow]);

  useLayoutEffect(() => {
    window.addEventListener("resize", handleDetailsHeightUpdate);
    return () =>
      window.removeEventListener("resize", handleDetailsHeightUpdate);
  }, [handleDetailsHeightUpdate]);

  useEffect(() => {
    if (data && data.length > 0) setFocusedCell({ row: 0, column: 0 });
  }, [data]);

  const [firstColumnWidth, setFirstColumnWidth] = useState(0);
  const firstColumnHeaderRef = useRef();
  const [hasScrollLeft, setHasScrollLeft] = useState(false);

  const updateWidth = useCallback(() => {
    if (!isLoading && data.length > 0) {
      const width = firstColumnHeaderRef.current.getBoundingClientRect().width;
      if (width !== firstColumnWidth) setFirstColumnWidth(width);
    }
  }, [data.length, firstColumnWidth, isLoading]);
  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [updateWidth]);

  const handleScroll = (evt) => {
    updateWidth();
    const scrollLeft = evt.srcElement.scrollLeft;
    setHasScrollLeft(scrollLeft > 0 ? true : false);
  };

  const cellStyle = (size, noWidth) => {
    return {
      flexGrow: size,
      ...(noWidth
        ? { width: `${size}px`, minWidth: `${size}px` }
        : { width: `${4 * size}em`, minWidth: `${4 * size}em` }),
    };
  };

  const handleKeyDown = (evt) => {
    let r = focusedCell.row;
    let c = focusedCell.column;
    switch (evt.key) {
      case "ArrowLeft": {
        evt.preventDefault();
        c = Math.max(0, c - 1);
        cellsRefs.current[r][c].focus();
        break;
      }
      case "ArrowRight": {
        evt.preventDefault();
        c = Math.min(columns.length - 1, c + 1);
        cellsRefs.current[r][c].focus();
        break;
      }
      case "ArrowUp": {
        evt.preventDefault();
        r = Math.max(0, r - 1);
        cellsRefs.current[r][c].focus();
        break;
      }
      case "ArrowDown": {
        evt.preventDefault();
        r = Math.min(data.length - 1, r + 1);
        cellsRefs.current[r][c].focus();
        break;
      }
      case "Home": {
        evt.preventDefault();
        c = 0;
        if (evt.ctrlKey) r = 0;
        cellsRefs.current[r][c].focus();
        break;
      }
      case "End": {
        evt.preventDefault();
        c = columns.length - 1;
        if (evt.ctrlKey) r = data.length - 1;
        cellsRefs.current[r][c].focus();
        break;
      }
      default: {
        break;
      }
    }
  };

  const innerTable = (
    /* eslint-disable-next-line jsx-a11y/interactive-supports-focus */
    <div
      className={styles.table}
      aria-label={label}
      aria-rowcount={data.length}
      aria-colcount={columns.length}
      onKeyDown={handleKeyDown}
      role="grid"
    >
      <div role="rowgroup">
        <div role="row" className={styles.header}>
          {columns.map(
            (
              { label, className, align, size = 1, noWidth = false, sort },
              i
            ) => (
              <span
                key={i}
                ref={i === 0 ? firstColumnHeaderRef : undefined}
                role="columnheader"
                aria-colindex={i + 1}
                className={classnames(className, {
                  [styles[align]]: align !== undefined,
                })}
                style={cellStyle(size, noWidth)}
              >
                {!sort ? (
                  label
                ) : (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      history.push({
                        search: objToQuery({
                          ...queryToObj(search),
                          orderBy:
                            orderBy !== sort || orderDirection !== "asc"
                              ? sort
                              : undefined,
                          orderDirection:
                            orderBy !== sort
                              ? "desc"
                              : orderDirection === "desc"
                              ? "asc"
                              : undefined,
                        }),
                      })
                    }
                  >
                    {label}{" "}
                    {orderBy !== sort ? (
                      "-"
                    ) : (
                      <FontAwesomeIcon
                        icon={
                          orderDirection === "asc" ? "sort-up" : "sort-down"
                        }
                      />
                    )}
                  </div>
                )}
              </span>
            )
          )}
        </div>
      </div>
      <div role="rowgroup">
        {isLoading ? (
          <div className={styles.staticRow}>
            <FontAwesomeIcon
              className={styles.loadingIcon}
              icon="circle-notch"
              spin
            />
          </div>
        ) : data.length === 0 ? (
          <div className={styles.staticRow}>{emptyRender()}</div>
        ) : (
          data.map((row, r) => {
            return (
              <Fragment key={r}>
                <div role="row" key={r} aria-rowindex={r + 1}>
                  {columns.map(
                    (
                      {
                        render,
                        link,
                        className,
                        align,
                        size = 1,
                        noWidth = false,
                      },
                      c
                    ) => (
                      <GridCell
                        setFocusedCell={() =>
                          setFocusedCell({ row: r, column: c })
                        }
                        expandedRow={expandedRow}
                        setExpandedRow={setExpandedRow}
                        withDetails={row.details && c === 0}
                        key={c}
                        aria-colindex={c + 1}
                        className={classnames(
                          className,
                          {
                            [styles[align]]: align !== undefined,
                          },
                          { [styles.pressed]: expandedRow === r && c === 0 }
                        )}
                        ref={(element) => {
                          if (cellsRefs.current[r] === undefined)
                            cellsRefs.current[r] = [];
                          cellsRefs.current[r][c] = element;
                        }}
                        link={link ? link(row) : undefined}
                        tabIndex={
                          r === focusedCell.row && c === focusedCell.column
                            ? 0
                            : -1
                        }
                        style={cellStyle(size, noWidth)}
                        row={r}
                        // if any row has details, disable scroll
                        scrollOnFocus={!hasDetails}
                      >
                        {render(row, r)}
                      </GridCell>
                    )
                  )}
                </div>
                {row.details && (
                  <div
                    id={`detaisPanel${r}`}
                    className={classnames(
                      { [styles.expanded]: expandedRow === r },
                      styles.detailRow
                    )}
                    aria-labelledby={`detailsBtn${r}`}
                    aria-expanded={expandedRow === r}
                    aria-hidden={expandedRow !== r}
                    // role="region"
                    ref={(element) => {
                      if (detailsRef.current !== undefined) {
                        detailsRef.current[r] = element;
                      }
                    }}
                    onKeyDown={(evt) => {
                      if (
                        [
                          "ArrowUp",
                          "ArrowDown",
                          "ArrowRight",
                          "ArrowLeft",
                          "Home",
                          "End",
                        ].includes(evt.key)
                      ) {
                        evt.preventDefault();
                        evt.stopPropagation();
                      }
                    }}
                    inert={expandedRow !== r ? "true" : undefined}
                  >
                    {row.details()}
                  </div>
                )}
              </Fragment>
            );
          })
        )}
      </div>
    </div>
  );

  return (
    <div className={classnames(styles.wrap, className)}>
      <span
        role="log"
        aria-live="polite"
        aria-atomic
        aria-busy={isLoading}
        className={styles.log}
      >
        {!isLoading && `Carregamento de ${label} concluído.`}
      </span>
      {!hasDetails && !isLoading && data.length > 0 ? (
        <Scrollbars
          autoHide
          autoHeight
          autoHeightMax="unset"
          hideTracksWhenNotNeeded
          renderTrackHorizontal={(props) => (
            <div {...props} className={styles.scrollbarTrack} />
          )}
          renderThumbHorizontal={(props) => (
            <div {...props} className={styles.scrollbarThumb} />
          )}
          onScroll={handleScroll}
        >
          {innerTable}
        </Scrollbars>
      ) : (
        innerTable
      )}
      {
        // Floating first column
        !hasDetails && !isLoading && data.length > 0 && (
          <div
            className={classnames(styles.firstColumn, {
              [styles.hasScrollLeft]: hasScrollLeft,
            })}
            style={{
              width: firstColumnWidth,
            }}
            aria-hidden
          >
            <div role="rowgroup">
              <div role="row">
                <span role="columnheader">
                  {!columns[0].sort ? (
                    columns[0].label
                  ) : (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        history.push({
                          search: objToQuery({
                            ...queryToObj(search),
                            orderBy:
                              orderBy !== columns[0].sort ||
                              orderDirection !== "asc"
                                ? columns[0].sort
                                : undefined,
                            orderDirection:
                              orderBy !== columns[0].sort
                                ? "desc"
                                : orderDirection === "desc"
                                ? "asc"
                                : undefined,
                          }),
                        })
                      }
                    >
                      {columns[0].label}{" "}
                      {orderBy !== columns[0].sort ? (
                        "-"
                      ) : (
                        <FontAwesomeIcon
                          icon={
                            orderDirection === "asc" ? "sort-up" : "sort-down"
                          }
                        />
                      )}
                    </div>
                  )}
                </span>
              </div>
            </div>

            <div role="rowgroup">
              {data.map((row, r) => {
                const { render, className, align } = columns[0];
                return (
                  <div key={r} role="row">
                    {/* eslint-disable-next-line  jsx-a11y/click-events-have-key-events */}
                    <GridCell
                      setFocusedCell={() =>
                        setFocusedCell({ row: r, column: 0 })
                      }
                      className={classnames(
                        className,
                        {
                          [styles[align]]: align !== undefined,
                        },
                        { [styles.pressed]: expandedRow === r }
                      )}
                      // onClick here works for focus the real gridCell behind
                      onClick={() => {
                        cellsRefs.current[r][0].focus();
                      }}
                      link={columns[0].link ? columns[0].link(row) : undefined}
                      tabIndex={-1}
                      row={r}
                      expandedRow={expandedRow}
                      setExpandedRow={setExpandedRow}
                      withDetails={row.details}
                    >
                      {render(row, r)}
                    </GridCell>
                  </div>
                );
              })}
            </div>
          </div>
        )
      }
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      className: PropTypes.string,
      render: PropTypes.func.isRequired,
      link: PropTypes.func,
      align: PropTypes.oneOf(["start", "center", "end"]),
      size: PropTypes.number,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  emptyRender: PropTypes.func,
};

const WithLink = ({ linkGenerator, children, row }) =>
  linkGenerator ? (
    <ClickLink link={linkGenerator(row)} className="actualRow" noStyle>
      {children}
    </ClickLink>
  ) : (
    children
  );

/**
 * @description ClickTable.Column component used to define a column on the
 *  ClickTable component. It should be used only as a child of ClickTable
 *  to define it's columns.
 * @param {Object} props - All props of this Component
 * @param {func} props.render - how to render the
 * @param {string} props.title - The title of the column shown in it's header
 * @param {string} props.data - The property of the row's data object used to
 *                              render this columns content when no render method
 *                              is specified.
 * @param {string} props.type - Specify the type as text to apply the right css format
 * @param {string} props.align - Specify "right" to align the header and contents to right
 * @memberof module:ClickTable
 */
const Column = ({ title, type, align, shrink }) => (
  <span
    className={classnames({
      textCol: type === "text",
      rightCol: align === "right",
      leftCol: align === "left",
      shrinkColumn: shrink === true,
    })}
  >
    {title}
  </span>
);

Column.defaultProps = {
  type: "text",
  align: "center",
};

Column.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right"]),
};

/**
 * ClickTable - Component used to render tables of data with parametrized columns
 * To specify the columns of the table, use the ClickTable.Column component
 * @param {object} props          All props of this Component
 * @param {type}   props.children A list of ClickTable.Column components defining this table columns
 * @param {type}   props.data     An array of objects defining the data to be rendered on this table
 * @param {type}   props.linkGenerator A function that given the row data, returns the url of each table row link
 *
 * @memberof module:ClickTable
 */
const ClickTable = ({ children, data, linkGenerator, onClick }) => (
  <div className="ClickTable-wrap">
    <section className="ClickTable-list">
      <h1>{children}</h1>
      <ul>
        {data.map((row) => (
          <li
            key={row.id}
            className={classnames({
              danger: row.danger,
              selected: row.selected,
              disabled: row.disabled,
              actualRow: !linkGenerator,
            })}
            onClick={!!onClick ? () => onClick(row) : undefined}
          >
            <WithLink linkGenerator={linkGenerator} row={row}>
              {React.Children.map(children, (col) => {
                if (!col || !col.props) return null;
                return col.props.render ? (
                  <col.props.render
                    row={row}
                    key={row.id + col.props.title}
                    data={row[col.props.data]}
                  />
                ) : (
                  <span
                    key={row.id.toString() + col.props.title}
                    className={classnames({
                      textCol: col.props.type === "text",
                      rightCol: col.props.align === "right",
                      leftCol: col.props.align === "left",
                      shrinkColumn: col.props.shrink === true,
                    })}
                  >
                    <span>
                      {row[col.props.data] && row[col.props.data].toString()}
                    </span>
                  </span>
                );
              })}
            </WithLink>
          </li>
        ))}
      </ul>
    </section>
  </div>
);

ClickTable.Column = Column;
export { Table };
export default ClickTable;
