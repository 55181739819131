import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

const getUserData = (state) => state.user;

const mapStateToProps = createSelector([getUserData], (userData) => ({
  userData: userData.toJS(),
}));

export const UserContext = React.createContext();

const UserProvider = ({ userData, children }) => {
  return (
    <UserContext.Provider value={userData}>{children}</UserContext.Provider>
  );
};

export default connect(mapStateToProps)(UserProvider);
