import React, { useEffect, useState, createContext, useContext } from "react";
import { api } from "core";
import { UserContext } from "./User.context";

export const SubjectsContext = createContext();

const SubjectsProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState({
    defaultSubjects: [],
    compiledSubjects: []
  });
  const { school } = useContext(UserContext);

  useEffect(() => {
    // the school param isn't necessary for the api call, but it is
    // beign passed just to run the effect again
    if (!school) return;
    api.getDefaultSubjects(school).then(defaultSubjects => {
      defaultSubjects = defaultSubjects.map(level => ({
        ...level,
        subjects: level.subjects.sort(({ label: s1 }, { label: s2 }) =>
          s1.localeCompare(s2, undefined, { sensitivity: "base" })
        )
      }));

      let compiledSubjects = {};
      for (const level of defaultSubjects) {
        for (const subject of level.subjects) {
          compiledSubjects[subject.abbreviation.toLowerCase()] = {
            value: subject.abbreviation.toLowerCase(),
            label: subject.label
          };
        }
      }
      compiledSubjects = Object.values(compiledSubjects).sort(
        ({ label: s1 }, { label: s2 }) =>
          s1.localeCompare(s2, undefined, { sensitivity: "base" })
      );
      setContextValue({ defaultSubjects, compiledSubjects });
    });
  }, [school]);

  return (
    <SubjectsContext.Provider value={contextValue}>
      {children}
    </SubjectsContext.Provider>
  );
};

export default SubjectsProvider;
