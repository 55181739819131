/**
 * @module ClickPagination
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ClickLink from "components/ClickLink";
import { queryToObj } from "core/utils";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import "./ClickPagination.scss";

/**
 * @description Component for functional pagination
 * @param {Object} props
 * @param {string} props.location - Path of actual location, without any query param
 * @param {number} props.currentPage - Number of current page displayed
 * @param {number} props.totalPages - Total number of pages
 * @param {function} props.setCurrentPage - Callback function to set new page
 * @param {string} [props.pageParamName="currentPage"] - Name of query param for page
 * @todo Check pagination call when totalPages is undefined
 * @todo Missing "..." in some cases in mobile view e.g. currentPage = 1, totalPages = 4
 */
const ClickPagination = ({
  location,
  currentPage,
  totalPages,
  setCurrentPage,
  pageParamName,
}) => {
  if (!currentPage || !totalPages || totalPages <= 1) return null;
  // Cria array das páginas que ficarão visíveis
  const pages = [0, 1, 2]
    .reduce(
      (acc, i) => [
        ...acc,
        i < 1 ? i + 1 : null,
        currentPage - i,
        currentPage + i,
        i < 1 ? totalPages - i : null,
      ],
      [],
    )
    .reduce(
      (acc, page) =>
        acc.includes(page) || page < 1 || page > totalPages
          ? acc
          : [...acc, page],
      [],
    )
    .sort((a, b) => a - b);
  // Adiciona o método onClick aos botões se setCurrentPage estiver setado
  const callbackParam = (page) =>
    setCurrentPage ? { onClick: () => setCurrentPage(page) } : {};
  return (
    <nav className="ClickPagination">
      {currentPage > 1 ? (
        <ClickLink
          className="arrow arrow-left"
          query={{
            ...queryToObj(location.search),
            [pageParamName]: currentPage - 1,
          }}
          title="Ir para a página anterior"
          {...callbackParam(currentPage - 1)}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </ClickLink>
      ) : (
        <div className="arrow arrow-left disabled" aria-hidden>
          <FontAwesomeIcon icon="chevron-left" />
        </div>
      )}
      {pages.map((page, i) => {
        const isHidable =
          page !== 1 &&
          page !== totalPages &&
          (page === currentPage - 2 || page === currentPage + 2)
            ? true
            : false;
        const isCurrent = page === currentPage ? true : false;
        const params = {
          className: classnames("ClickPage", {
            current: isCurrent,
            hidable: isHidable,
          }),
          ...{ "aria-current": isCurrent ? "page" : null },
          query: {
            ...queryToObj(location.search),
            [pageParamName]: page,
          },
          ...{
            title: isCurrent
              ? `Você está na página ${page}`
              : `Ir para a página ${page}`,
          },
        };
        return i > 0 && pages[i - 1] < page - 1 ? (
          <React.Fragment key={page}>
            <span className="ClickPage jump">...</span>
            <ClickLink {...params} {...callbackParam(page)}>
              {page}
            </ClickLink>
          </React.Fragment>
        ) : (
          <ClickLink key={page} {...params} {...callbackParam(page)}>
            {page}
          </ClickLink>
        );
      })}
      {currentPage < totalPages ? (
        <ClickLink
          className="arrow arrow-right"
          query={{
            ...queryToObj(location.search),
            [pageParamName]: currentPage + 1,
          }}
          title="Ir para a próxima página"
          {...callbackParam(currentPage + 1)}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </ClickLink>
      ) : (
        <div className="arrow arrow-right disabled" aria-hidden>
          <FontAwesomeIcon icon="chevron-right" />
        </div>
      )}
    </nav>
  );
};

ClickPagination.defaultProps = {
  pageParamName: "currentPage",
};

ClickPagination.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default withRouter(ClickPagination);
