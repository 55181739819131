import ProtectedRoute from "components/ProtectedRoute";
import Providers from "providers/Providers";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Search from "views/Search";
import PortalTemplate from "views/Template";
import ActivitiesReports from "./ActivitiesReports";
import Login from "./Login";
import { SendRecoveryEmail } from "./SendRecoveryEmail";
import Trieduc from "./Trieduc";
import UserManagement from "./UserManagement";
import { PasswordReset } from "./PasswordReset";

const CreationTools = lazy(() => import("./CreationTools"));
const LearningRoadmap = lazy(() => import("./LearningRoadmap"));
const WebQuests = lazy(() => import("./WebQuests"));
const F1 = lazy(() => import("./F1"));
const UserLandingPage = lazy(() => import("./UserLandingPage"));

const Routes = () => (
  <Suspense fallback="Carregando...">
    <Providers>
      <PortalTemplate>
        <Switch>
          <Route path="/Login" render={() => <Login />} />
          <Route path='/esqueci-minha-senha' render={() => <SendRecoveryEmail />} />
          <Route path='/redefinir-minha-senha' render={() => <PasswordReset />} />
          <ProtectedRoute path="/Trieduc" render={() => <Trieduc />} />
          <ProtectedRoute
            path="/RoteiroDeAprendizagem"
            render={() => <LearningRoadmap />}
          />
          <ProtectedRoute path="/anosiniciais" render={() => <F1 />} />
          <ProtectedRoute path="/WebQuests" render={() => <WebQuests />} />
          <ProtectedRoute
            path={[
              "/EditorDeHQ",
              "/EditorDeJornal",
              "/PalavrasCruzadas",
              "/LinhaDoTempo",
              "/EscritaColaborativa",
            ]}
            render={() => <CreationTools />}
          />
          <ProtectedRoute
            path="/GestaoDeUsuarios"
            render={() => <UserManagement />}
          />
          <ProtectedRoute
            path="/Relatorios"
            render={() => <ActivitiesReports />}
          />
          <ProtectedRoute path="/busca">
            <Search />
          </ProtectedRoute>
          <Route path="/">
            <UserLandingPage />
          </Route>
        </Switch>
      </PortalTemplate>
    </Providers>
  </Suspense>
);

export default Routes;
