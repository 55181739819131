import React from "react";
import { Table as ClickTable } from "components/ClickTable";
import ClickFilter from "components/ClickFilter";
import styles from "./ClickFilterableTable.module.scss";

const ClickFilterableTable = ({ filterProps, tableProps, children }) => (
  <div className={styles.wrap}>
    <ClickFilter className={styles.filter} {...filterProps}>
      {children}
    </ClickFilter>
    <ClickTable className={styles.table} {...tableProps} />
  </div>
);

export default ClickFilterableTable;
