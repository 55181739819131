import React from "react";
import { Route, Switch } from "react-router-dom";
import Schools from "./Schools";
import Teachers from "./Teachers";

const AcitivitiesReportsRoutes = () => (
  <Switch>
    <Route
      path="/Relatorios/:schoolId(\d+)"
      render={({ match }) => <Teachers schoolId={match.params.schoolId} />}
    />
    <Route path="/Relatorios" render={() => <Schools />} />
  </Switch>
);

export default AcitivitiesReportsRoutes;
