/**
 * @module ClickImage
 */

import React from "react";
import PropTypes from "prop-types";

import _breakpoints from "core/_breakpoints";

/**
 * @description Component to render custom images for different breakpoints
 * @param {Object} props - All props of this Component
 * @param {string} props.alt - Image description
 * @param {string} props.defaultImg - Default image
 * @todo Provide example and documentation of the breakpoints
 */
const ClickImage = ({ alt, defaultImg, ...rest }) => (
  <picture className="ClickImage">
    {Object.entries(_breakpoints).map(
      ([key, value]) =>
        rest[key] ? <source key={key} media={value} srcSet={rest[key]} /> : null
    )}
    <img src={defaultImg} alt={alt} />
  </picture>
);

ClickImage.propTypes = {
  alt: PropTypes.string.isRequired,
  defaultImg: PropTypes.any.isRequired
};

export default ClickImage;
