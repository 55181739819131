import React from "react";
import { createStore, applyMiddleware, compose } from "redux";

import { Provider } from "react-redux";
import createBrowserHistory from "history/createBrowserHistory";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";

import { tryToLoadTokens } from "./user.actions";

import Routes from "views/Routes";

import coreReducer from "./core.reducer";

import api from "./api";

import { library as fontawesome } from "@fortawesome/fontawesome-svg-core";
import { fab as brands } from "@fortawesome/free-brands-svg-icons";
import { fas as solid } from "@fortawesome/free-solid-svg-icons";
import { far as regular } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";

export * from "./utils";

fontawesome.add(brands, solid, regular);

export { api };

// history to handle browser's navigation
export const history = createBrowserHistory();

// Middleware for when an redux navigation action is fired -> middleware fires a
//  history.push to make that action happens
const middlewares = [routerMiddleware(history), thunk];

// compose from redux or redux_devtools, to join middlewares
const compose_with_devtools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// createStore from redux, with the devtools enhancer
// here, the store initial state (second param) is ommited.
export const rootStore = createStore(
  coreReducer(history),
  compose_with_devtools(applyMiddleware(...middlewares))
);

export default (
  <>
    <Provider store={rootStore}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
    <ToastContainer />
  </>
);

rootStore.dispatch(tryToLoadTokens());
