import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "contexts/User.context.js";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LogoutModal from "../../components/LogoutModal";
/** @todo implement modal from login */
// import LoginModal from "../../components/LoginModal";
import Submenu from "../../components/Menu/Submenu";
import { useMenuButton, useMenuList } from "../../hooks";
import styles from "./UserMenu.module.scss";

const UserCard = ({ splittedName, name, type }) => {
  return (
    <div className={styles.userCard}>
      <div className={styles.userAvatar}>
        {`${splittedName[0].charAt(0)}${
          splittedName.length > 1 ? [...splittedName].pop().charAt(0) : ""
        }`.toUpperCase()}
      </div>
      <div className={styles.userName}>{name}</div>
      <div className={styles.userType}>
        {
          {
            student: "ALUNO(A)",
            teacher: "PROFESSOR(A)",
            employee: "FUNCIONÁRIO(A)",
            manager: "GESTOR(A)",
            admin: "ADMIN",
          }[type]
        }
      </div>
    </div>
  );
};

const UserBtn = ({ selectedItemIndex, setSelectedItemIndex }) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const user = useContext(UserContext);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   if (user.id) {
  //     setIsModalOpen(false);
  //   }
  // }, [user.id]);

  const item = "user";
  const { buttonProps, popperProps } = useMenuButton(
    item,
    selectedItemIndex,
    setSelectedItemIndex,
    "bottom-end",
  );

  if (!user.id) return "";
  // return (
  //   <>
  //     {/* {isModalOpen && <LoginModal onClose={() => setIsModalOpen(false)} />} */}
  //     {/* <ClickButton color="success" onClick={() => setIsModalOpen(true)}> */}
  //     <ClickButton color="success" link="/Login">
  //       Login
  //     </ClickButton>
  //   </>
  // );

  const splittedName = user?.name?.split(" ");
  const OLD_HOST = process.env.REACT_APP_PORTAL_URL;

  return (
    <>
      <button
        {...buttonProps}
        className={styles.userButton}
        aria-controls="menu-popup"
      >
        <div className={styles.info}>
          <div className={styles.name}>{splittedName[0]}</div>
          <div className={styles.type}>
            {
              {
                student: "ALUNO(A)",
                teacher: "PROFESSOR(A)",
                employee: "FUNCIONÁRIO(A)",
                manager: "GESTOR(A)",
                admin: "ADMIN",
              }[user.type]
            }
          </div>
        </div>
        <div className={styles.buttonMain}>
          <div className={styles.avatar}>
            {user.picture ? (
              <img alt="Avatar do usuário" src={user.picture} />
            ) : (
              <div>
                {`${splittedName[0].charAt(0)}${
                  splittedName.length > 1
                    ? [...splittedName].pop().charAt(0)
                    : ""
                }`.toUpperCase()}
              </div>
            )}
          </div>
          <FontAwesomeIcon className="test" icon="chevron-down" />
        </div>
      </button>
      <Submenu
        {...{
          ...popperProps,
          isSelected: selectedItemIndex === item,
          items: [
            { label: "Meu perfil", link: OLD_HOST },
            { label: "Notificações", link: `${OLD_HOST}/portal/notificacao` },
            { label: "Sair", onClick: () => setIsLogoutModalOpen(true) },
          ],
          label: "a",
          className: styles.userSubmenu,
        }}
      >
        <UserCard
          splittedName={splittedName}
          name={user?.name}
          type={user?.type}
        />
      </Submenu>

      {isLogoutModalOpen && (
        <LogoutModal setIsModalOpen={setIsLogoutModalOpen} />
      )}
    </>
  );
};

const UserMenu = () => {
  const { listProps, selectedItemIndex, setSelectedItemIndex } = useMenuList({
    label: "Menu do usuário",
  });

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname) {
      setSelectedItemIndex(null);
    }
  }, [pathname, setSelectedItemIndex]);

  return (
    <nav className={styles.userMenu}>
      <div {...listProps}>
        <UserBtn {...{ selectedItemIndex, setSelectedItemIndex }} />
      </div>
    </nav>
  );
};

export default UserMenu;
