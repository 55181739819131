import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import UserManagementProvider from "./UserManagement.context";
import UserManagementProjects from "./Projects";
import UserManagementProject from "./Projects/Project";

export default () => (
  <UserManagementProvider>
    <Switch>
      <Route
        path="/GestaoDeUsuarios/Projetos/:projectId(\d+)"
        render={({ match }) => (
          <UserManagementProject projectId={match.params.projectId} />
        )}
      />
      <Route
        path="/GestaoDeUsuarios/Projetos"
        render={() => <UserManagementProjects />}
      />
      <Route
        path="/GestaoDeUsuarios"
        render={() => (
          <Redirect from="/GestaoDeUsuarios" to="/GestaoDeUsuarios/Projetos" />
        )}
      />
    </Switch>
  </UserManagementProvider>
);
