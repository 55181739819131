import { Record } from "immutable";
import { combineReducers } from "redux-immutable";

import login from "views/Login/Login.reducer";
import onlineExercises from "./OnlineExercises/OnlineExercises.reducer";
import learningRoadmap from "./LearningRoadmap/LearningRoadmap.reducer";

const ViewRecord = Record({
  login: new login.defaultState(),
  onlineExercises: new onlineExercises.defaultState(),
  learningRoadmap: new learningRoadmap.defaultState()
});

const ViewReducer = combineReducers(
  {
    login,
    onlineExercises,
    learningRoadmap
  },
  ViewRecord
);

ViewReducer.defaultState = ViewRecord;

export default ViewReducer;
