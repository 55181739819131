import "animate.css";
import classnames from "classnames";
import ClickButton, { ClickButtonGroup } from "components/ClickButton";
import PropTypes from "prop-types";
import React, { useEffect, useCallback, useRef } from "react";
import { createPortal } from "react-dom";
import styles from "./ClickModal.module.scss";
import { Formik, Form } from "formik";

const ClickModalFormikWrapper = ({ formikProps, ...props }) => {
  return createPortal(
    formikProps ? (
      <Formik {...formikProps}>
        {formikBag => (
          <Form>
            <ClickModal {...props} formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    ) : (
      <ClickModal {...props} />
    ),
    document.getElementById("modal-root")
  );
};

/**
 * @description Generic Modal component.
 * @param {Object} props - All props of this Component
 * @param {function} props.onClose - Callback to be called at modal attempt to close
 * @param {node} props.children - Main content of modal to be displayed.
 * @param {Array.<object> | Array.<function> } [props.buttons] - Array of ClickButton props object, to be shown at modal's bottom or formikbag function
 * @param {function} [props.onKeyDown] - Custom keyboard event listener
 * @param {Object} props.formikProps - If set, wraps the modal element on a Formik with this props.
 * @memberof module:ClickModal
 */
const ClickModal = ({ onClose, children, buttons, onKeyDown, formikBag }) => {
  const activeRef = useRef();
  const rootRef = useRef();
  const wrapRef = useRef();

  useEffect(() => {
    if (formikBag && formikBag.dirty) {
      const beforeUnloadHandler = evt => {
        evt.preventDefault();
        evt.returnValue = "";
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () =>
        window.removeEventListener("beforeunload", beforeUnloadHandler);
    }
  }, [formikBag]);

  const handleClose = useCallback(
    formikBag => {
      if (!(formikBag && formikBag.dirty)) {
        onClose && onClose();
      } else {
        const shouldClose = window.confirm(
          "Você tem alterações não salvas. Tem certeza que deseja fechar?"
        );
        if (shouldClose) {
          onClose && onClose();
        }
      }
    },
    [onClose]
  );

  const handleKeyDown = useCallback(
    evt => {
      // closes modal with escape key
      switch (evt.key) {
        case "Escape": {
          handleClose(formikBag);
          break;
        }
        default: {
          if (onKeyDown) onKeyDown(evt);
        }
      }
    },
    [formikBag, handleClose, onKeyDown]
  );

  useEffect(() => {
    // removes overflow from body, add some effect to modal backdrop
    rootRef.current = document.getElementById("root");
    rootRef.current.classList.add(styles.rootBlur);
    document.body.classList.add(styles.bodyOverflow);

    // keep track of previously focused element
    activeRef.current = document.activeElement;

    // creates focus trap inside modal
    rootRef.current.inert = true;
    return () => {
      // restores application style and behavior
      rootRef.current.classList.remove(styles.rootBlur);
      document.body.classList.remove(styles.bodyOverflow);
      rootRef.current.inert = false;

      // restores focus to the focused element before modal opened
      if (activeRef.current) activeRef.current.focus();
    };
  }, []);

  useEffect(() => {
    // add/remove keyboard event listener to handle escape key
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classnames(styles.wrap, "animated", "fadeIn")}
      ref={wrapRef}
      onClick={evt => {
        evt.stopPropagation(); /* Stops event bubbling of React Portal */
        if (wrapRef.current === evt.target) handleClose(formikBag);
      }}
      role="presentation"
    >
      <div
        role="dialog"
        aria-modal="true"
        aria-labelledby="modalContent"
        className={classnames(styles.modal, "animated", "bounceIn")}
      >
        <ClickButton
          type="button"
          label="Fechar"
          icon="times"
          fill="simple"
          color="danger"
          tooltip={{ placement: "top" }}
          onClick={() => handleClose(formikBag)}
          className={styles.close}
        />
        <div id="modalContent" className={styles.content}>
          {!!formikBag && typeof children === "function"
            ? children(formikBag)
            : children}
        </div>
        {!!buttons && (
          <footer className={styles.footer}>
            <ClickButtonGroup>
              {(
                (typeof buttons === "function"
                  ? buttons(formikBag)
                  : buttons) || []
              ).map((buttonProps, i) => (
                <ClickButton key={i} {...buttonProps} />
              ))}
            </ClickButtonGroup>
          </footer>
        )}
      </div>
    </div>
  );
};

ClickModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  onKeyDown: PropTypes.func,
  formikProps: PropTypes.object
};

export default ClickModalFormikWrapper;
