import { ReactNode, forwardRef } from "react";
import { withRouter } from "react-router";
import classnames from "classnames";
import { Link } from "react-router-dom";

import styles from "./ClickLink.module.scss";

interface Path {
  pathname: string;
  search: string;
  hash: string;
}

interface ClickLinkProps {
  link: string;
  query?: any /** @todo check type */;
  children?: ReactNode;
  noStyle?: boolean /** @todo check type */;
  className?: string;
  location?: Partial<Path> /** @todo check type */;
  onClick?: () => void;
  title?: string;
  staticContext?: any /** @todo check type */;
  internalRef?: any /** @todo check type */;
}

/** Helper method to convert one object's properties that are objects to json
 */
const objPropsToJSON = (obj: { [key: string]: any }) =>
  Object.entries(obj).reduce((obj: { [key: string]: any }, value) => {
    if (typeof value[1] === "object") obj[value[0]] = JSON.stringify(value[1]);
    else obj[value[0]] = value[1];
    return obj;
  }, {});

/** Helper method to clear the unnecessary query parameters.
 * Those are undefined, null and "".
 */
const clearObject = (queryObj: { [key: string]: any }) =>
  Object.entries(queryObj).reduce(
    (previous, [key, value]) =>
      value !== undefined && value !== null && value !== ""
        ? { ...previous, [key]: value }
        : previous,
    {},
  );

/** @todo check ref type*/
/** Component that renders a <a> or <Link> from react router dom
 * Internal links (relative links) should use the react router dom <Link> Tag,
 * and external links (http://...../...) should use an <a>.
 *
 * To make it easier, this component decides on runtime if a <a> or <Link>
 * should be used
 */
function ClickLinkComponent(
  {
    link,
    query,
    children,
    noStyle,
    className,
    location,
    onClick,
    title,
    staticContext,
    internalRef,
    ...props
  }: ClickLinkProps,
  ref: any,
): JSX.Element {
  // if it is a external or empty link
  if ((!link && !query) || /^(?:[a-z]+:)?(\/\/)/i.test(link)) {
    return (
      <a
        href={link}
        className={classnames({
          [styles.noStyle]: noStyle,
          [className!]: className,
        })}
        onClick={onClick}
        title={title}
        {...props}
        ref={internalRef}
      >
        {children}
      </a>
    );
  } else {
    let queryParams = query
      ? new URLSearchParams(objPropsToJSON(clearObject(query))).toString()
      : undefined;
    return (
      <Link
        {...props}
        to={{
          pathname: link ? link : location?.pathname,
          search: queryParams,
        }}
        className={classnames({
          [styles.noStyle]: noStyle,
          [className!]: className,
        })}
        onClick={onClick}
        title={title}
        innerRef={internalRef}
      >
        {children}
      </Link>
    );
  }
}

/** @todo type */
const ClickLinkWithRouter = withRouter<any, any>(ClickLinkComponent);

/** @todo type */
const ClickLink = forwardRef<any, ClickLinkProps>((props, ref) => (
  <ClickLinkWithRouter {...props} internalRef={ref} />
));

export default ClickLink;
