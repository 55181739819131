/**
 * @module ClickFeedback
 */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import PropTypes from "prop-types";
import "animate.css";

import "./Feedback.scss";

/**
 * @description Card to provide feedback to user
 * @param {Object} props
 * @param {string} props.id - Feedback generated id
 * @param {string} props.type="success" - Type of feedback
 * @param {string} props.message - Message that will be displayed to user
 * @param {func} props.removeFeedback - Callback that closes feedback
 * @todo Create default type
 */
class Feedback extends React.Component {
  constructor(props) {
    super(props);
    ["bounceIn", "bounceOut", "countdown"].forEach(
      func => (this[func] = this[func].bind(this))
    );
    this.state = {
      animation: "",
      countdown: false,
      countdownTime: 10000,
      paused: false
    };
  }

  static defaultProps = {
    type: "success",
    hasProgressBar: false
  };

  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["error", "success", "warning"]).isRequired,
    message: PropTypes.string.isRequired,
    removeFeedback: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.bounceIn();
  }

  componentWillUnmount() {
    ["bounceInTimeout", "bounceOutTimeout"].forEach(timeout =>
      window.clearTimeout(this[timeout])
    );
    window.clearInterval(this.countdownInterval);
  }

  bounceIn() {
    this.setState({ animation: "bounceInRight" });
    this.bounceInTimeout = setTimeout(this.countdown, 300);
  }

  /**
   * @description test
   */
  countdown() {
    window.clearTimeout(this.bounceInTimeout);
    this.setState({ countdown: true });
    this.countdownInterval = setInterval(() => {
      if (!this.state.paused) {
        if (this.state.countdownTime <= 0) {
          this.bounceOut();
        } else
          this.setState(prevState => ({
            countdownTime: prevState.countdownTime - 100
          }));
      }
    }, 100);
  }

  bounceOut() {
    window.clearInterval(this.countdownInterval);
    this.setState({
      countdown: false,
      countdownTime: 0,
      animation: "zoomOut"
    });
    this.bounceOutTimeout = setTimeout(
      () => this.props.removeFeedback(this.props.id),
      300
    );
  }

  render = () => {
    const { type, message } = this.props;
    return (
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={classnames("Feedback animated", this.state.animation, type)}
        onClick={this.bounceOut}
        onMouseEnter={() => this.setState({ paused: true })}
        onMouseLeave={() => this.setState({ paused: false })}
      >
        {/* eslint-enable jsx-a11y/click-events-have-key-events */}
        <div role="alert" className="FeedbackMessage">
          <div>
            <h4 className="FeedbackType">
              {{ success: "SUCESSO", error: "ERRO", warning: "ALERTA" }[type]}!
            </h4>
            {message}
          </div>
          <div
            className="FeedbackProgress"
            style={{ width: `${this.state.countdownTime / 100.0}%` }}
            aria-hidden="true"
          />
        </div>
        <div className="FeedbackStatus">
          <FontAwesomeIcon
            icon={classnames(
              { times: type === "error" },
              { check: type === "success" },
              { exclamation: type === "warning" }
            )}
          />
        </div>
      </div>
    );
  };
}

export default Feedback;
