/**
 * @module ClickDateInput
 */

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { applyRef } from "core";
import { createEvent } from ".";
import styles from "./ClickDateInput.module.scss";
import ClickTooltip from "../ClickTooltip";
import {
  Field as FormikField,
  ErrorMessage as FormikErrorMessage
} from "formik";

/**
 * @description Component used in forms to render date inputs
 * @param {Object} props - All props of this Component
 * @param {string} [props.id] - DOM id of the text input element. Needed for acessibility.
 * @param {string} [props.name] - DOM name of the text input element. Needed if using withFormik.
 * @param {string} [props.value] - Current value of the input element.
 *
 * @param {function} [props.onChange] - callback called when the user inputs any data.
 * @param {function} [props.onBlur] - callback called when the user focus leaves the input.
 *
 * @param {string} [props.label] - the label that should be rendered with this input
 * @param {string} [props.ariaLabel] - DOM aria-label to be used on the input element.
 * @param {string} [props.helpText] - Text with instructions of how to input the data for this input.
 * @param {string} [props.tooltip] - Text shown when the user hovers this element.
 *
 * @param {string} [props.className] - CSS classes for this input container
 * @param {bool} [props.dark=false] - If true, renders the input with light foreground, to be used in dark backgrounds
 *
 * @param {bool} [props.withFormik=false] - If true, use data and callbacks from formik to control this input.
 * @param {bool} [props.withDateObject=false] - If true, overrides value pattern YYYY-MM-DD with a Date object.
 * @param {function} [props.validate] - Callback used to validate this form field: val => errorMessage.
 * @memberof module:ClickDateInput
 */
const ClickDateInput = React.forwardRef(
  ({ name, validate, withFormik, ...otherProps }, ref) => {
    let inputElement;
    if (withFormik) {
      inputElement = (
        <div>
          <FormikField
            name={name}
            validate={validate}
            render={({ field /*, form */ }) => (
              <RenderInput {...otherProps} ref={ref} {...field} />
            )}
          />
          <div className={styles.errorMessage}>
            <FormikErrorMessage name={name} />
          </div>
        </div>
      );
    } else {
      inputElement = <RenderInput name={name} {...otherProps} ref={ref} />;
    }
    return inputElement;
  }
);

const RenderInput = React.forwardRef(
  (
    {
      id,
      name,
      value,
      onChange,
      onBlur,
      label,
      ariaLabel,
      helpText,
      tooltip,
      className,
      withDateObject,
      dark
    },
    ref
  ) => {
    let inputElement = tooltipRef => (
      <div className={styles.dateInputWrapper}>
        <input
          id={id}
          name={name}
          value={
            withDateObject
              ? value
                ? `${value.getUTCFullYear()}-${(value.getUTCMonth() + 1)
                    .toString()
                    .replace(/^(\d)$/g, "0$1")}-${value
                    .getUTCDate()
                    .toString()
                    .replace(/^(\d)$/g, "0$1")}`
                : ""
              : value
          }
          onChange={
            withDateObject
              ? evt => onChange(createEvent(name, evt.target.valueAsDate))
              : onChange
          }
          onBlur={onBlur}
          type="date"
          aria-label={ariaLabel}
          className={styles.dateInput}
          aria-describedby={!!helpText ? `${id}-helptext` : undefined}
          ref={element => {
            applyRef(element, tooltipRef);
            applyRef(element, ref);
          }}
        />
      </div>
    );
    if (!tooltip && !label && !!ariaLabel) {
      tooltip = ariaLabel;
    }

    const inputElementWithTooltip = !!tooltip && (
      <ClickTooltip {...tooltip}>{ref => inputElement(ref)}</ClickTooltip>
    );

    return (
      <div
        className={classNames(styles.dateInputContainer, className, {
          [styles.dark]: dark
        })}
      >
        {!!label && <label htmlFor={id}>{label}</label>}
        {!!helpText && (
          <span id={`${id}-helptext`} className={styles.helpText}>
            {helpText}
          </span>
        )}
        {tooltip ? inputElementWithTooltip : inputElement(undefined)}
      </div>
    );
  }
);

ClickDateInput.defaultProps = {
  dark: false,
  withFormik: false,
  withDateObject: false
};

ClickDateInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  helpText: PropTypes.string,
  tooltip: PropTypes.shape({
    placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    content: PropTypes.node
  }),

  className: PropTypes.string,
  dark: PropTypes.bool.isRequired,

  withFormik: PropTypes.bool.isRequired,
  withDateObject: PropTypes.bool.isRequired,
  validate: PropTypes.func
};

export default ClickDateInput;
