import classnames from "classnames";
import ClickFilterableTable from "components/ClickFilterableTable";
import Panel from "components/Panel";
import { queryToObj, sanitizeWord } from "core/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { BreadcrumbContext } from "views/Breadcrumb.context";
import PortalTemplate from "views/Template";
import styles from "./ActivitiesReports.module.scss";
import useActivitiesReportsModel from "./models";

const TeachersReport = ({ schoolId }) => {
  const [activities, setActivities] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { getTeachersFromSchoolReport } = useActivitiesReportsModel();
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [filteredData, setFilteredData] = useState();
  const { search } = useLocation();
  const { teacher: teacherName, orderBy, orderDirection } = queryToObj(search);

  const reportData = useMemo(() => {
    if (!activities) return;
    return Object.entries(activities)
      .map(([id, data]) => ({ id, ...data }))
      .sort(({ name: n1 }, { name: n2 }) => n1.localeCompare(n2));
  }, [activities]);

  useEffect(() => {
    if (reportData) {
      let filtered = [...reportData];
      if (teacherName) {
        filtered = filtered.filter((teacher) =>
          sanitizeWord(teacher.name.toUpperCase()).includes(
            sanitizeWord(teacherName.toUpperCase())
          )
        );
      }
      if (orderBy) {
        const multiplier = orderDirection === "asc" ? 1 : -1;
        filtered = filtered.sort((teacher1, teacher2) => {
          if (orderBy === "teacher") {
            return multiplier * teacher1.name.localeCompare(teacher2.name);
          }
          if (!teacher1[orderBy] && !teacher2[orderBy]) {
            return 0;
          }
          if (!teacher1[orderBy]) {
            return -multiplier;
          }
          return teacher1[orderBy] < teacher2[orderBy]
            ? -multiplier
            : multiplier;
        });
      }
      setFilteredData(filtered);
    }
  }, [teacherName, reportData, orderBy, orderDirection]);

  useEffect(() => {
    setBreadcrumb([
      {
        label: "Escolas",
        url: `/Relatorios/`,
      },
      {
        label: "Professores",
        url: `/Relatorios/${schoolId}`,
        current: true,
      },
    ]);
    return () => setBreadcrumb(undefined);
  }, [setBreadcrumb, schoolId]);

  useEffect(() => {
    setIsLoading(true);
    getTeachersFromSchoolReport(schoolId)
      .then((data) => setActivities(data))
      .finally(() => setIsLoading(false));
    return () => setActivities(undefined);
  }, [getTeachersFromSchoolReport, schoolId]);

  return (
    <PortalTemplate>
      <main id="mainContent">
        <section className={classnames("content", styles.header)}>
          <h1>Relatório de atividades</h1>
        </section>
        <Panel>
          <div className="content">
            <ClickFilterableTable
              filterProps={{
                id: "schoolReportsFilter",
                initialValues: {
                  school: "",
                },
                mainInput: {
                  id: "teacherNameReportsInput",
                  name: "teacher",
                  placeholder: "Buscar por professor...",
                  withFormik: true,
                },
              }}
              tableProps={{
                isLoading,
                data: filteredData || [],
                emptyRender: () => "Não existem atividades para essa escola",
                columns: [
                  {
                    label: "Professor",
                    render: (row) => row.name,
                    size: 5,
                    sort: "teacher",
                  },
                  {
                    label: "Total de atividades",
                    render: (row) => row.total || 0,
                    size: 2,
                    align: "end",
                    sort: "total",
                  },
                  {
                    label: <span title="Exercícios Online">EO</span>,
                    render: (row) => row["exercicios_online"] || "-",
                    align: "end",
                    sort: "exercicios_online",
                  },
                  {
                    label: <span title="Roteiro de Aprendizagem">RA</span>,
                    render: (row) => row["learning_roadmap"] || "-",
                    align: "end",
                    sort: "learning_roadmap",
                  },
                  {
                    label: <span title="WebQuests">WQ</span>,
                    render: (row) => row.webquest || "-",
                    align: "end",
                    sort: "webquest",
                  },
                  {
                    label: <span title="Palavras Cruzadas">PC</span>,
                    render: (row) => row.crossword || "-",
                    align: "end",
                    sort: "crossword",
                  },
                  {
                    label: <span title="Editor de Jornal">EJ</span>,
                    render: (row) => row.newspaper || "-",
                    align: "end",
                    sort: "newspaper",
                  },
                  {
                    label: <span title="Escrita Colaborativa">EC</span>,
                    render: (row) => row["collab_writing"] || "-",
                    align: "end",
                    sort: "collab_writing",
                  },
                ],
              }}
            />
          </div>
        </Panel>
      </main>
    </PortalTemplate>
  );
};

export default TeachersReport;
