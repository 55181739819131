import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ClickButton from "components/ClickButton";
import ClickLink from "components/ClickLink";
import ClickTagColor from "components/ClickTagColor";
import { useNotifications } from "contexts/Notifications.context";
import { UserContext } from "contexts/User.context.js";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMenuButton, useMenuList } from "../../hooks";
import styles from "./NotificationsMenu.module.scss";

const typeId2Icon = {
  // Avaliação
  1: "list",
  // Lista de exercícios
  2: "list",
  // Simulado
  3: "list",
  // Podcast
  4: "microphone-alt",
  // Vídeo
  5: "film",
  // Jogo
  6: "gamepad",
  // Multimídia
  7: "tablet-alt",
  // Atualidades
  8: "newspaper",
};

const type2Icon = {
  // Roteiro de aprendizagem
  "App\\Notifications\\LearningRoadmap\\LearningRoadmapAllocated": "file-alt",
};

// Fix unicode and convert type from new notifications to proper labels
const type2Label = {
  "App\\Notifications\\LearningRoadmap\\LearningRoadmapAllocated":
    "Roteiro de Aprendizagem",
};

const NotificationCard = ({
  title,
  id,
  link,
  subject,
  type,
  type_id,
  index,
}) => {
  const linkRef = useRef();
  return (
    <li key={index} role="none">
      <div
        className={styles.card}
        onMouseDown={(evt) => {
          if (!linkRef.current.contains(evt.target)) {
            if (evt.button === 0 && !evt.ctrlKey) {
              linkRef.current.click();
            } else if (evt.button === 1 || evt.button === 0) {
              window.open(linkRef.current.href, "_blank");
            }
          }
        }}
      >
        <FontAwesomeIcon
          className={styles.icon}
          icon={type_id ? typeId2Icon[type_id] : type2Icon[type]}
          size="2x"
        />
        <div className={styles.type}>
          {subject && (
            <ClickTagColor colorClass={subject.abbreviation.toLowerCase()}>
              {subject.abbreviation}
            </ClickTagColor>
          )}
          {type2Label[type] ? type2Label[type] : type}
        </div>
        <ClickLink
          ref={linkRef}
          link={
            link
              ? link
              : type ===
                "App\\Notifications\\LearningRoadmap\\LearningRoadmapAllocated"
              ? `/RoteiroDeAprendizagem/Tarefa/${id}?share=aluno`
              : null
          }
        >
          {title}
        </ClickLink>
      </div>
    </li>
  );
};

const NotificationsSubmenu = ({
  setPopperElement,
  popperStyles,
  attributes,
  items,
  label,
  isSelected,
}) => {
  const OLD_HOST = process.env.REACT_APP_PORTAL_URL;
  const linkRef = useRef();
  return (
    <div
      ref={setPopperElement}
      style={popperStyles.popper}
      className={classnames("fadeIn", styles.notificationsSubmenu, {
        [styles.hidden]: !isSelected,
        animated: isSelected,
      })}
      {...attributes.popper}
    >
      <div className={styles.title}>{label}</div>
      {!(items && items.length > 0) ? (
        <div>Você não possui notificações</div>
      ) : (
        <ul>
          {items.slice(0, 4).map((item, index) => (
            <NotificationCard key={index} {...{ ...item, index }} />
          ))}
          <li>
            <div
              className={styles.bottomBtn}
              onMouseDown={(evt) => {
                if (!linkRef.current.contains(evt.target)) {
                  if (evt.button === 0 && !evt.ctrlKey) {
                    linkRef.current.click();
                  } else if (evt.button === 1 || evt.button === 0) {
                    window.open(linkRef.current.href, "_blank");
                  }
                }
              }}
            >
              <ClickLink ref={linkRef} link={`${OLD_HOST}/portal/notificacao`}>
                Ver todas
              </ClickLink>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

const NotificationsBtn = ({
  selectedItemIndex,
  setSelectedItemIndex,
  notificationsList,
}) => {
  const user = useContext(UserContext);
  const item = "notifications";

  const { buttonProps, popperProps } = useMenuButton(
    item,
    selectedItemIndex,
    setSelectedItemIndex,
    "bottom-end",
  );

  const hasUnreadNotifications = useMemo(
    () =>
      !!notificationsList &&
      notificationsList.filter(({ read_at }) => !read_at).length > 0,
    [notificationsList],
  );

  if (!user.id) return "";

  return (
    <>
      <ClickButton
        {...buttonProps}
        label="Notificações"
        tooltip
        fill="simple"
        background="dark"
        size="big"
        className={styles.notificationsBtn}
        aria-controls="menu-popup"
      >
        <span
          className={classnames(styles.icon, {
            "fa-layers": hasUnreadNotifications,
          })}
          aria-hidden="true"
        >
          <FontAwesomeIcon
            icon="bell"
            transform={item === selectedItemIndex ? { rotate: 30 } : undefined}
          />
          {hasUnreadNotifications && (
            <FontAwesomeIcon
              icon="circle"
              transform="shrink-9 down-4 right-6"
              className={styles.notification}
            />
          )}
        </span>
      </ClickButton>
      <NotificationsSubmenu
        isSelected={selectedItemIndex === item}
        items={notificationsList
          .filter(({ read_at }) => !read_at)
          .map(({ data, id, ...rest }) => ({ ...data, ...rest }))}
        label={"Notificações"}
        {...popperProps}
      />
    </>
  );
};

const NotificationsMenu = () => {
  const notifications = useNotifications();
  const { pathname } = useLocation();
  const { listProps, selectedItemIndex, setSelectedItemIndex } = useMenuList({
    label: "Menu de notificações",
  });

  useEffect(() => {
    if (pathname) {
      setSelectedItemIndex(null);
    }
  }, [pathname, setSelectedItemIndex]);

  return (
    <div className={styles.notificationsMenu} {...listProps}>
      <NotificationsBtn
        notificationsList={notifications}
        {...{ selectedItemIndex, setSelectedItemIndex }}
      />
    </div>
  );
};

export default NotificationsMenu;
