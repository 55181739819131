/**
 * @module ClickImageInput
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ClickButton from "components/ClickButton/ClickButton";
import { FeedbackContext } from "components/Feedback";
import { api } from "core";
import "focus-visible";
import {
  ErrorMessage as FormikErrorMessage,
  Field as FormikField,
} from "formik";
import propTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Popper } from "react-popper";
import Textarea from "react-textarea-autosize";
import { createEvent } from ".";
import styles from "./ClickImageInput.module.scss";

const ImgInput = ({
  id,
  className,
  name,
  value,
  maxLines,
  onChange,
  altPlaceholder,
  captionPlaceholder,
  isReadOnly,
  onFocus,
  onBlur,
  onCaptionOverflow,
}) => {
  const imgContainerRef = useRef();
  const altPopperRef = useRef();
  const [showAltPopper, setShowAltPopper] = useState(false);
  /* scss variables injected at .container div */
  const lineHeight = 1;
  const paddingBottomTop = 0.25;
  const borderWidth = 2;
  const maxHeight =
    (maxLines * lineHeight + 2 * paddingBottomTop) *
      parseInt(window.getComputedStyle(document.body).fontSize) +
    2 * borderWidth;

  /* onchange handlers and dependencies */

  const { addFeedback } = useContext(FeedbackContext);
  const [isLoading, setIsLoading] = useState(false);
  const [captionOverflowed, setCaptionOverflowed] = useState(false);

  useEffect(() => {
    // focus alttext field if it's visible
    if (showAltPopper && altPopperRef.current) {
      altPopperRef.current.focus();
    }
  }, [showAltPopper]);

  useEffect(() => {
    // allow component caller to check if caption is overflowing
    onCaptionOverflow(captionOverflowed);
  }, [captionOverflowed, onCaptionOverflow]);

  const handleIMGChange = async (evt) => {
    setIsLoading(true);
    try {
      const uploadResponse = await api.uploadImage(evt.target.files[0]);
      onChange(createEvent(name, { ...value, src: uploadResponse.data }));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      addFeedback("Não foi possível fazer o upload", "error");
      setIsLoading(false);
    }
  };
  const handleCaptionChange = (evt) => {
    onChange(createEvent(name, { ...value, caption: evt.target.value }));
  };
  const handleAltChange = (evt) => {
    onChange(createEvent(name, { ...value, alt: evt.target.value }));
  };
  const handleFocus = (evt) => {
    if (onFocus) {
      onFocus(createEvent(name, value, evt));
    }
  };
  const handleBlur = (evt) => {
    setShowAltPopper(false);
    if (onBlur) {
      onBlur(createEvent(name, value, evt));
    }
  };
  return !isReadOnly ? (
    <>
      <Popper
        positionFixed={true}
        placement="top"
        referenceElement={imgContainerRef.current}
      >
        {({ style, placement, ref, scheduleUpdate }) => {
          return (
            <div
              className={styles.altTxtWrappper}
              style={{
                ...style,
                display: showAltPopper ? "block" : "none",
              }}
              data-placement={placement}
              ref={ref}
            >
              <Textarea
                id={`alt-${id}`}
                value={value && value.alt}
                placeholder={altPlaceholder}
                onHeightChange={() => {
                  scheduleUpdate && scheduleUpdate();
                }}
                onChange={handleAltChange}
                className={classnames(styles.altTxt, {
                  [styles.hidden]: value && !value.src,
                })}
                onFocus={(evt) => handleFocus(evt)}
                onBlur={(evt) => handleBlur(evt)}
                inputRef={altPopperRef}
              />
            </div>
          );
        }}
      </Popper>
      <div
        className={classnames(styles.container, className)}
        ref={imgContainerRef}
        style={{
          "--maxLines": maxLines,
          "--lineHeight": `${lineHeight}em`,
          "--paddingBottomTop": `${paddingBottomTop}em`,
          "--borderWidth": `${borderWidth}px`,
        }}
      >
        <div
          className={classnames(styles.imgContainer, {
            [styles.hasImg]: value && value.src,
          })}
        >
          <input
            type="file"
            accept="image/*"
            id={id}
            value=""
            className={styles.imgInput}
            onChange={handleIMGChange}
            disabled={isLoading}
            onFocus={(evt) => handleFocus(evt)}
            onBlur={(evt) => handleBlur(evt)}
          />
          <label
            id={`${id}-label`}
            htmlFor={id}
            className={styles.imgInputLabel}
          >
            <FontAwesomeIcon icon="camera" />
            <p>{value && value.src ? "Trocar imagem" : "Adicionar imagem"}</p>
          </label>
          {isLoading ? (
            <FontAwesomeIcon icon="circle-notch" spin />
          ) : value && value.src ? (
            <>
              <div className={styles.altBtn}>
                <ClickButton
                  icon="eye-slash"
                  fill="simple"
                  background="dark"
                  onClick={() =>
                    setShowAltPopper((showAltPopper) => !showAltPopper)
                  }
                >
                  Texto Alternativo
                </ClickButton>
              </div>
              <img
                src={value && value.src}
                alt={value && value.alt}
                className={styles.img}
                crossOrigin="anonymous"
              />
            </>
          ) : (
            <FontAwesomeIcon icon="image" />
          )}
        </div>
        <div className={styles.captionWrapper}>
          <Textarea
            spellCheck={false}
            value={value && value.caption}
            className={classnames(styles.captionInput, {
              [styles.captionOverflowed]: captionOverflowed,
            })}
            onChange={handleCaptionChange}
            onHeightChange={(height) =>
              setCaptionOverflowed(height > maxHeight)
            }
            minRows={1}
            placeholder={captionPlaceholder}
            onFocus={(evt) => handleFocus(evt)}
            onBlur={(evt) => handleBlur(evt)}
          />
        </div>
      </div>
    </>
  ) : (
    <div
      className={classnames(styles.container, className)}
      ref={imgContainerRef}
      style={{
        "--maxLines": maxLines,
        "--lineHeight": `${lineHeight}rem`,
        "--paddingBottomTop": `${paddingBottomTop}rem`,
        "--borderWidth": `${borderWidth}px`,
      }}
    >
      <div
        className={classnames(styles.imgContainer, {
          [styles.hasImg]: value && value.src,
        })}
      >
        {value && value.src ? (
          <img
            src={value && value.src}
            alt={value && value.alt}
            className={styles.img}
            crossOrigin="anonymous"
          />
        ) : (
          <FontAwesomeIcon icon="image" />
        )}
      </div>
      <div className={styles.captionWrapper}>
        <figcaption
          className={classnames(styles.captionInput, {
            [styles.captionOverflowed]: captionOverflowed,
            [styles.readOnly]: isReadOnly,
          })}
        >
          {value.caption}
        </figcaption>
      </div>
    </div>
  );
};

/**
 * @description component to image upload in WYSIWYG
 * @param {string} name name of form field
 * @param {function} validate function to validate form
 * @param {bool} withFormik boolean to render properly when the input is children of  Formik
 * @param {bool} id id to relate label and input
 * @param {object} value object to set img, caption and alt text
 * @param {function} onChange function for deal with changes of path
 * @param {string} className class to restyle the component for specificities on
 * @param {number} maxLines max lines of caption field
 */

const ClickImageInput = (props) => {
  return props.withFormik ? (
    <>
      <FormikField
        name={props.name}
        render={({ field, _form }) => (
          <ImgInput
            {...props}
            {...field}
            onChange={(evt) => {
              field.onChange(evt);
              props.onChange && props.onChange(evt);
            }}
          />
        )}
      />
      <FormikErrorMessage name={props.name} />
    </>
  ) : (
    <ImgInput {...props} />
  );
};

ClickImageInput.defaultProps = {
  value: { src: "", caption: "", alt: "" },
  maxLines: 3,
  onCaptionOverflow: () => {},
};

ClickImageInput.propTypes = {
  name: propTypes.string.isRequired,
  validate: propTypes.func,
  withFormik: propTypes.bool,
  id: propTypes.string.isRequired,
  value: propTypes.shape({
    src: propTypes.string.isRequired,
    alt: propTypes.string.isRequired,
    caption: propTypes.string.isRequired,
  }).isRequired,
  maxLines: propTypes.number,
  onChange: propTypes.func,
  className: propTypes.string,
};
export default ClickImageInput;
