import { Record } from "immutable";

const ViewRoadmapRecord = Record({
  title: null,
  validationTitleError: null,
  level: null,
  subject: null,
  originalOwner: null,
  owner: null,
  coauthors: [],
  coauthorsToAdd: [],
  coauthorsToRemove: [],
  visibility: "private",
  createdAt: null,
  isEditing: null,
  allocated: null,
  submitted: null,
  pendingFeedback: null,
  description: null,
  validationDescriptionError: null,
  fetchStatus: "none", // ["none", "loading", "ready", "error"]
  fetchId: null,
  importStatus: "none", // ["none", "loading", "ready", "error"]
  allocationGroups: {},
  allocationGroupsFetchStatus: "none", // ["none", "loading", "ready", "error"]
  allocationGroup: {},
  allocationGroupFetchStatus: "none", // ["none", "loading", "ready", "error"]
  allocationFetchStatus: "none", // ["none", "loading", "ready", "error"]
  editAllocationGroup: {
    toAdd: [],
    toRemove: [],
    students: [],
    classes: [],
    displayItems: {},
    selectedItems: {},
    scope: "students",
  },
  addingCoauthors: false,
  hasChanges: false,
  steps: [],
  studentName: "",
  stepsTimestamp: null,
  stepsSaveStatus: "none", // ["none", "loading", "ready", "error"]
  currentPosition: 0,

  allocationState: null,

  stepsFetchStatus: "none", // ["none", "loading", "ready", "error"]
  isFinishAllocationDialogOpen: false,
  isSubmitForReviewDialogOpen: false,
  isSendBackDialogOpen: false,
});

const ViewRoadmapReducer = (state, action) => {
  switch (action.type) {
    case "view.learningRoadmap.viewRoadmap.fetchRoadmap.start": {
      return state
        .set("fetchStatus", "loading")
        .set("steps", [])
        .set("fetchId", action.payload.id);
    }
    case "view.learningRoadmap.viewRoadmap.fetchRoadmap.success": {
      return new ViewRoadmapRecord(action.payload).set("fetchStatus", "ready");
    }
    case "view.learningRoadmap.viewRoadmap.fetchRoadmap.error": {
      return state.set("fetchStatus", "error");
    }

    case "view.learningRoadmap.viewRoadmap.import.start": {
      return state.set("importStatus", "loading");
    }
    case "view.learningRoadmap.viewRoadmap.import.success": {
      return state.set("importStatus", "success");
    }
    case "view.learningRoadmap.viewRoadmap.import.error": {
      return state.set("importStatus", "error");
    }

    case "view.learningRoadmap.viewRoadmap.fetchRoadmapAllocationGroups.start": {
      return state.set("allocationGroupsFetchStatus", "loading");
    }
    case "view.learningRoadmap.viewRoadmap.fetchRoadmapAllocationGroups.success": {
      return state
        .set("allocationGroups", action.payload)
        .set(
          "allocated",
          Object.values(action.payload).reduce(
            (total, current) => total + current.total,
            0,
          ),
        )
        .set(
          "submitted",
          Object.values(action.payload).reduce(
            (total, current) => total + current.answered,
            0,
          ),
        )
        .set(
          "pendingFeedback",
          Object.values(action.payload).reduce(
            (total, current) => total + current.needCorrection,
            0,
          ),
        )
        .set("allocationGroupsFetchStatus", "ready");
    }
    case "view.learningRoadmap.viewRoadmap.fetchRoadmapAllocationGroups.error": {
      return state.set("allocationGroupsFetchStatus", "error");
    }

    case "view.learningRoadmap.viewRoadmap.fetchRoadmapAllocationGroup.start": {
      return state.set("allocationGroupFetchStatus", "loading");
    }
    case "view.learningRoadmap.viewRoadmap.fetchRoadmapAllocationGroup.success": {
      return state
        .set("allocationGroup", action.payload)
        .set("editAllocationGroup", {
          displayItems: {},
          scope: "students",
          selectedItems: {},
          toAdd: [],
          toRemove: [],
          students: [],
          classes: [],
        })
        .set("allocationGroupFetchStatus", "ready");
    }
    case "view.learningRoadmap.viewRoadmap.fetchRoadmapAllocationGroup.error": {
      return state.set("allocationGroupFetchStatus", "error");
    }

    case "view.learningRoadmap.viewRoadmap.editAllocationGroup.loadAllAllocations.success": {
      return state.set("editAllocationGroup", {
        ...state.editAllocationGroup,
        selectedItems: Object.entries(action.payload).reduce(
          (obj, [allocationId, allocation]) => {
            obj[allocation.studentId] = {
              ...allocation,
              selected: false,
              id: allocation.studentId,
            };
            return obj;
          },
          {},
        ),
      });
    }

    case "view.learningRoadmap.viewRoadmap.editAllocationGroup.toggleDisplayItemSelection": {
      return state.set("editAllocationGroup", {
        ...state.editAllocationGroup,
        displayItems: {
          ...state.editAllocationGroup.displayItems,
          [action.payload.id]: {
            ...state.editAllocationGroup.displayItems[action.payload.id],
            selected:
              !state.editAllocationGroup.displayItems[action.payload.id]
                .selected,
          },
        },
      });
    }

    case "view.learningRoadmap.viewRoadmap.editAllocationGroup.toggleSelectedItemSelection": {
      return state.set("editAllocationGroup", {
        ...state.editAllocationGroup,
        selectedItems: {
          ...state.editAllocationGroup.selectedItems,
          [action.payload.id]: {
            ...state.editAllocationGroup.selectedItems[action.payload.id],
            selected:
              !state.editAllocationGroup.selectedItems[action.payload.id]
                .selected,
          },
        },
      });
    }
    case "view.learningRoadmap.viewRoadmap.editAllocationGroup.onScopeChange": {
      const displayItems =
        action.payload === "students"
          ? state.editAllocationGroup.students
          : state.editAllocationGroup.classes;
      return state.set("editAllocationGroup", {
        ...state.editAllocationGroup,
        displayItems,
        scope: action.payload,
      });
    }
    case "view.learningRoadmap.viewRoadmap.editAllocationGroup.addStudents": {
      const toAdd = [...state.editAllocationGroup.toAdd];
      const toRemove = [...state.editAllocationGroup.toRemove];
      for (const student of action.payload) {
        const index = state.editAllocationGroup.toRemove.findIndex(
          (element) => element === student.id,
        );
        if (index >= 0) {
          toRemove.splice(index, 1);
        } else {
          toAdd.push(student.id);
        }
        student.selected = false;
        student.disabled = true;
      }
      return state.set("editAllocationGroup", {
        ...state.editAllocationGroup,
        toAdd,
        toRemove,
        selectedItems: {
          ...state.editAllocationGroup.selectedItems,
          ...action.payload.reduce((obj, student) => {
            obj[student.id] = {
              ...student,
              disabled: false,
              selected: false,
            };
            return obj;
          }, {}),
        },
      });
    }
    case "view.learningRoadmap.viewRoadmap.editAllocationGroup.removeStudents": {
      const toAdd = [...state.editAllocationGroup.toAdd];
      const toRemove = [...state.editAllocationGroup.toRemove];
      for (const student of action.payload) {
        const index = state.editAllocationGroup.toAdd.findIndex(
          (element) => element === student.id,
        );
        if (index >= 0) {
          toAdd.splice(index, 1);
        } else {
          toRemove.push(student.id);
        }
        student.selected = false;
      }
      const displayItems =
        state.editAllocationGroup.scope === "students"
          ? {
              ...state.editAllocationGroup.displayItems,
              ...action.payload.reduce((obj, student) => {
                obj[student.id] = {
                  ...student,
                  disabled: false,
                  selected: false,
                };
                return obj;
              }, {}),
            }
          : state.editAllocationGroup.displayItems;
      return state.set("editAllocationGroup", {
        ...state.editAllocationGroup,
        toAdd,
        toRemove,
        displayItems,
        selectedItems: {
          ...state.editAllocationGroup.selectedItems,
          ...action.payload.reduce((obj, student) => {
            obj[student.id] = undefined;
            return obj;
          }, {}),
        },
      });
    }
    case "view.learningRoadmap.viewRoadmap.editAllocationGroup.addClassStudents": {
      const toAdd = new Set(state.editAllocationGroup.toAdd);
      const toRemove = new Set(state.editAllocationGroup.toRemove);
      for (const student of action.payload) {
        if (toRemove.has(student.id)) {
          toRemove.delete(student.id);
        } else {
          if (
            state.editAllocationGroup.selectedItems[student.id] === undefined
          ) {
            toAdd.add(student.id);
          }
        }
        state.editAllocationGroup.students[student.id].selected = false;
        state.editAllocationGroup.students[student.id].disabled = true;
      }
      return state.set("editAllocationGroup", {
        ...state.editAllocationGroup,
        toAdd: Array.from(toAdd.values()),
        toRemove: Array.from(toRemove.values()),
        selectedItems: {
          ...state.editAllocationGroup.selectedItems,
          ...action.payload.reduce((obj, student) => {
            obj[student.id] = {
              ...student,
              disabled: false,
              selected: false,
            };
            return obj;
          }, {}),
        },
      });
    }

    case "view.learningRoadmap.viewRoadmap.header.changeRoadmapHeader": {
      if (action.payload.propName.indexOf(".") >= 0) {
        // recursive method to set a nested property on an object
        const setNestedProp = (propList, newValue, dataObj) => {
          if (propList.length <= 0) return newValue;
          if (propList.length === 1) {
            const ret = { ...dataObj };
            ret[propList[0]] = newValue;
            return ret;
          }
          const propListCpy = [...propList];
          const currentProp = propListCpy.pop();
          const ret = { ...dataObj };
          ret[currentProp] = setNestedProp(
            propListCpy,
            newValue,
            dataObj[currentProp],
          );
          return ret;
        };

        const propsList = action.payload.propName.split(".");
        const firstPropName = propsList.shift();
        let newState = state.set(
          firstPropName,
          setNestedProp(
            propsList,
            action.payload.newValue,
            state[firstPropName],
          ),
        );
        if (firstPropName === "allocationGroup") {
          if (
            propsList[0] === "dateStart" &&
            (!action.payload.newValue instanceof Date ||
              isNaN(action.payload.newValue))
          ) {
            newState = state.set("allocationGroup", {
              ...newState.allocationGroup,
              dateStart: undefined,
            });
          }
          if (propsList[0] === "title") {
            if (
              action.payload.newValue &&
              action.payload.newValue.trim() !== ""
            ) {
              newState = state.set("allocationGroup", {
                ...newState.allocationGroup,
                validationTaskTitleError: undefined,
              });
            } else {
              newState = state.set("allocationGroup", {
                ...newState.allocationGroup,
                validationTaskTitleError: "emptyTitle",
              });
            }
          }
        }
        return newState;
      } else {
        if (action.payload.propName === "title") {
          if (
            action.payload.newValue &&
            action.payload.newValue.trim() !== ""
          ) {
            return state
              .set("title", action.payload.newValue)
              .set("validationTitleError", null);
          } else {
            return state
              .set("title", action.payload.newValue)
              .set("validationTitleError", "emptyTitle");
          }
        } else if (action.payload.propName === "description") {
          if (
            action.payload.newValue &&
            action.payload.newValue.trim() !== ""
          ) {
            return state
              .set("description", action.payload.newValue)
              .set("validationDescriptionError", null);
          } else {
            return state
              .set("description", action.payload.newValue)
              .set("validationDescriptionError", "emptyDescription");
          }
        }
        return state
          .set(action.payload.propName, action.payload.newValue)
          .set("hasChanges", true);
      }
    }

    case "view.learningRoadmap.viewRoadmap.header.addCoauthor": {
      for (const i in state.coauthorsToRemove) {
        if (action.payload.id === state.coauthorsToRemove[i]) {
          let coauthorsToRemoveCopy = [...state.coauthorsToRemove].splice(i, 1);
          return state
            .set("coauthorsToRemove", coauthorsToRemoveCopy)
            .set("coauthors", [...state.coauthors, action.payload]);
        }
      }
      return state
        .set("coauthors", [...state.coauthors, action.payload])
        .set("coauthorsToAdd", [...state.coauthorsToAdd, action.payload]);
    }

    case "view.learningRoadmap.viewRoadmap.header.removeCoauthor": {
      for (const i in state.coauthorsToAdd) {
        if (action.payload === state.coauthorsToAdd[i].id) {
          let coauthorsToAddCopy = [...state.coauthorsToAdd];
          coauthorsToAddCopy.splice(i, 1);
          return state.set("coauthorsToAdd", coauthorsToAddCopy).set(
            "coauthors",
            state.coauthors.filter(
              (coauthor) => coauthor.id !== action.payload,
            ),
          );
        }
      }
      return state
        .set(
          "coauthors",
          state.coauthors.filter((coauthor) => coauthor.id !== action.payload),
        )
        .set("coauthorsToRemove", [...state.coauthorsToRemove, action.payload]);
    }

    case "view.learningRoadmap.viewRoadmap.editSteps.loadSteps.start": {
      return state.set("stepsFetchStatus", "loading");
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.loadSteps.success": {
      const stepsObj = state.steps.reduce((obj, current) => {
        obj[current.id] = current;
        return obj;
      }, {});
      action.payload.steps.map(
        (step) => (stepsObj[step.id] = { ...stepsObj[step.id], ...step }),
      );
      return state
        .set("stepsFetchStatus", "ready")
        .set("steps", Object.values(stepsObj))
        .set("stepsTimestamp", action.payload.timestamp);
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.loadSteps.error": {
      return state.set("stepsFetchStatus", "error");
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.createStep": {
      return state
        .set("steps", [
          ...state.steps,
          {
            text: "",
            type: "text",
          },
        ])
        .set("currentPosition", state.steps.length);
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.removeStep": {
      const arrCpy = [...state.steps];
      const newPos =
        action.payload === arrCpy.length - 1
          ? arrCpy.length - 2
          : action.payload;
      arrCpy.splice(action.payload, 1);
      return state.set("steps", arrCpy).set("currentPosition", newPos);
    }

    case "view.learningRoadmap.viewRoadmap.editSteps.moveStepBack": {
      const arrCpy = [...state.steps];
      const tmp = arrCpy[action.payload - 1];
      arrCpy[action.payload - 1] = arrCpy[action.payload];
      arrCpy[action.payload] = tmp;
      return state
        .set("steps", arrCpy)
        .set("currentPosition", state.currentPosition - 1);
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.moveStepForward": {
      const arrCpy = [...state.steps];
      const tmp = arrCpy[action.payload + 1];
      arrCpy[action.payload + 1] = arrCpy[action.payload];
      arrCpy[action.payload] = tmp;
      return state
        .set("steps", arrCpy)
        .set("currentPosition", state.currentPosition + 1);
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.updateStepText": {
      const arrCpy = [...state.steps];
      arrCpy[action.payload.position].text = action.payload.newText;
      if (action.payload.newText && action.payload.newText.trim() !== "") {
        delete arrCpy[action.payload.position].error;
      } else {
        arrCpy[action.payload.position].error = "emptyStep";
      }
      return state.set("steps", arrCpy);
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.toggleStepType": {
      const arrCpy = [...state.steps];
      if (arrCpy[action.payload].type === "question") {
        arrCpy[action.payload].type = "text";
      } else {
        arrCpy[action.payload].type = "question";
      }
      return state.set("steps", arrCpy);
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.selectStep": {
      return state.set("currentPosition", action.payload);
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.saveSteps.start": {
      return state.set("stepsSaveStatus", "loading");
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.saveSteps.success": {
      return state.set("stepsSaveStatus", "ready");
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.saveSteps.error": {
      return state.set("stepsSaveStatus", "error");
    }
    case "view.learningRoadmap.viewRoadmap.header.update.success": {
      return state.set("coauthorsToAdd", []).set("coauthorsToRemove", []);
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.saveSteps.validationError": {
      if (action.payload && action.payload.error === "emptyStep") {
        return state
          .set(
            "steps",
            state.steps.map((step, index) =>
              index === action.payload.stepPosition
                ? { ...step, error: "emptyStep" }
                : step,
            ),
          )
          .set("stepsSaveStatus", "error");
      }
      return state;
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.finishEditing": {
      return state
        .set("isEditing", false)
        .set("steps", [])
        .set("stepsFetchStatus", "none");
    }
    case "view.learningRoadmap.viewRoadmap.editSteps.undoFinishEditing": {
      return state.set("isEditing", true);
    }

    case "view.learningRoadmap.viewRoadmap.allocation.fetchAllocation.start": {
      return state.set("allocationFetchStatus", "loading");
    }
    case "view.learningRoadmap.viewRoadmap.allocation.fetchAllocation.success": {
      return state.withMutations((st) =>
        st
          .set("title", action.payload.roadmapData.title)
          .set("owner", action.payload.roadmapData.owner)
          .set("originalOwner", action.payload.roadmapData.originalOwner)
          .set("coauthors", action.payload.roadmapData.coauthors)
          .set("level", action.payload.roadmapData.level)
          .set("subject", action.payload.roadmapData.subject)
          .set("description", action.payload.roadmapData.description)
          .set("allocationState", action.payload.roadmapData.allocationState)
          .set("allocationGroup", {
            dateStart: action.payload.roadmapData.dateStart,
            dateEnd: action.payload.roadmapData.dateEnd,
          })
          .set("title", action.payload.roadmapData.title)
          .set("allocationFetchStatus", "ready")
          .set("steps", action.payload.stepsData),
      );
    }
    case "view.learningRoadmap.viewRoadmap.allocation.fetchAllocation.error": {
      return state.set("allocationFetchStatus", "error");
    }
    case "view.learningRoadmap.viewRoadmap.viewAllocation.updateStepAnswer": {
      return state.set("steps", [
        ...state.steps.map((step) =>
          step.id === action.payload.stepId
            ? { ...step, draft: action.payload.newAnswer, draftChanged: true }
            : step,
        ),
      ]);
    }
    case "view.learningRoadmap.viewRoadmap.viewAllocation.saveDraft.success": {
      return state.set("steps", [
        ...state.steps.map((step) => ({
          ...step,
          draftChanged: false,
        })),
      ]);
    }

    case "view.learningRoadmap.viewRoadmap.fetchRoadmapAllocation.start": {
      return state.set("allocationFetchStatus", "loading");
    }
    case "view.learningRoadmap.viewRoadmap.fetchRoadmapAllocation.success": {
      const stepsObj = state.steps.reduce((obj, current) => {
        obj[current.id] = current;
        return obj;
      }, {});
      action.payload.steps.map(
        (step) => (stepsObj[step.id] = { ...stepsObj[step.id], ...step }),
      );
      return state
        .set("allocationFetchStatus", "ready")
        .set("steps", Object.values(stepsObj))
        .set("studentName", action.payload.studentName)
        .set("allocationState", action.payload.allocationState);
    }
    case "view.learningRoadmap.viewRoadmap.fetchRoadmapAllocation.error": {
      return state.set("allocationFetchStatus", "error");
    }

    case "view.learningRoadmap.viewRoadmap.allocation.changeFeedback": {
      return state.set(
        "steps",
        state.steps.map((step) =>
          step.id === action.payload.stepId
            ? {
                ...step,
                feedbackUpdated: true,
                feedback: action.payload.newFeedback,
              }
            : step,
        ),
      );
    }

    case "view.learningRoadmap.viewRoadmap.allocation.changeAcceptedAnswer": {
      return state.set(
        "steps",
        state.steps.map((step) =>
          step.id === action.payload.stepId
            ? {
                ...step,
                feedbackUpdated: true,
                accepted: action.payload.accepted,
              }
            : step,
        ),
      );
    }

    case "view.learningRoadmap.viewRoadmap.editAllocationGroup.searchStudents.success": {
      const students = action.payload.reduce((obj, student) => {
        obj[student.id] = {
          id: student.id,
          name: student.name,
          classroom: student.classroom,
          selected: false,
        };
        return obj;
      }, {});
      const displayItems =
        state.editAllocationGroup.scope === "students"
          ? { displayItems: students }
          : {};
      return state.set("editAllocationGroup", {
        ...state.editAllocationGroup,
        students,
        ...displayItems,
      });
    }

    case "view.learningRoadmap.viewRoadmap.editAllocationGroup.searchClasses.success": {
      const classes = action.payload.reduce((obj, classs) => {
        obj[classs.id] = {
          id: classs.id,
          label: classs.label,
          schoolGrade: classs.schoolGrade,
          selected: false,
        };
        return obj;
      }, {});
      const displayItems =
        state.editAllocationGroup.scope === "class"
          ? { displayItems: classes }
          : {};
      return state.set("editAllocationGroup", {
        ...state.editAllocationGroup,
        classes,
        ...displayItems,
      });
    }

    case "view.learningRoadmap.viewRoadmap.allocation.toggleFinishAllocationDialog": {
      return state.set("isFinishAllocationDialogOpen", action.payload);
    }

    case "view.learningRoadmap.viewRoadmap.allocation.toggleSubmitForReviewDialog": {
      return state.set("isSubmitForReviewDialogOpen", action.payload);
    }

    case "view.learningRoadmap.viewRoadmap.allocation.toggleSendBackDialog": {
      return state.set("isSendBackDialogOpen", action.payload);
    }

    case "@@router/LOCATION_CHANGE": {
      if (
        /\/RoteiroDeAprendizagem\/\d+\/Tarefas\/Nova/.test(
          action.payload.location.pathname,
        )
      ) {
        return state
          .set("editAllocationGroup", {
            ...state.editAllocationGroup,
            toAdd: [],
            toRemove: [],
            scope: "students",
          })
          .set("allocationGroupFetchStatus", "none")
          .set("allocationGroup", {});
      }
      return state;
    }
    case "view.learningRoadmap.viewRoadmap.header.create.validationError": {
      if (action.payload === "emptyTitle") {
        return state.set("validationTitleError", "emptyTitle");
      } else if (action.payload === "emptyDescription") {
        return state.set("validationDescriptionError", "emptyDescription");
      }
      return state;
    }

    case "view.learningRoadmap.viewRoadmap.header.update.validationError": {
      if (action.payload === "emptyTitle") {
        return state.set("validationTitleError", "emptyTitle");
      } else if (action.payload === "emptyDescription") {
        return state.set("validationDescriptionError", "emptyDescription");
      }
      return state;
    }

    case "view.learningRoadmap.viewRoadmap.header.update.task.validationError": {
      if (action.payload === "emptyTaskTitle") {
        return state.set("allocationGroup", {
          ...state.allocationGroup,
          validationTaskTitleError: "emptyTaskTitle",
        });
      } else if (action.payload === "emptyTaskStart") {
        return state.set("allocationGroup", {
          ...state.allocationGroup,
          validationTaskStartError: "emptyTaskStart",
        });
      } else if (action.payload === "earlyTaskStart") {
        return state.set("allocationGroup", {
          ...state.allocationGroup,
          validationTaskStartError: "earlyTaskStart",
        });
      } else if (action.payload === "earlyTaskEnd") {
        return state.set("allocationGroup", {
          ...state.allocationGroup,
          validationTaskEndError: "earlyTaskEnd",
        });
      }
      return state;
    }

    case "view.learningRoadmap.viewRoadmap.editSteps.saveEditAllocation.validationError": {
      if (action.payload === "emptyTaskTitle") {
        return state.set("allocationGroup", {
          ...state.allocationGroup,
          validationTaskTitleError: "emptyTaskTitle",
        });
      } else if (action.payload === "emptyTaskStart") {
        return state.set("allocationGroup", {
          ...state.allocationGroup,
          validationTaskStartError: "emptyTaskStart",
        });
      } else if (action.payload === "earlyTaskStart") {
        return state.set("allocationGroup", {
          ...state.allocationGroup,
          validationTaskStartError: "earlyTaskStart",
        });
      } else if (action.payload === "earlyTaskEnd") {
        return state.set("allocationGroup", {
          ...state.allocationGroup,
          validationTaskEndError: "earlyTaskEnd",
        });
      }
      return state;
    }

    case "view.learningRoadmap.viewRoadmap.clearData": {
      return new ViewRoadmapRecord();
    }

    default:
      return state;
  }
};

ViewRoadmapReducer.defaultState = ViewRoadmapRecord;

export default ViewRoadmapReducer;
