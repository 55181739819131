import React from "react";

import Select from "react-select";
import classnames from "classnames";
import "./ClickSelect.scss";

/** Dropdown component. This component encapsulates the react-select component.
 * If needed, it can be extended or replaced. Now it's main purpose is to
 * encapsulate and set default values for the react-select component.
 */
const ClickSelect = props => (
  <Select
    className={classnames("ClickSelect", props.className)}
    classNamePrefix="ClickSelect"
    clearable={props.clearable ? props.clearable : false}
    searchable={props.searchable ? props.searchable : false}
    placeholder="Selecione..."
    {...props}
  />
);

export default ClickSelect;
