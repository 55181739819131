import ClickButton from "components/ClickButton";
import ClickSelect from "components/ClickSelect/ClickSelect";
import { api } from "core";
import React, { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import "./printable.scss";

const PrintPortal = ({ children }) => {
  return createPortal(children, document.getElementById("print-root"));
};

const LoginsPrintPage = ({ users: rawUsers }) => {
  const users = useMemo(
    () =>
      rawUsers.sort((a, b) => {
        const niveis = {
          Infantil: 1,
          "Fundamental Anos Iniciais": 2,
          "Fundamental Anos Finais": 3,
          Médio: 3,
        };
        const anos = {
          "Primeiro Ano": 1,
          "Segundo Ano": 2,
          "Terceiro Ano": 3,
          "Quarto Ano": 4,
          "Quinto Ano": 5,
          "Sexto Ano": 6,
          "Sétimo Ano": 7,
          "Oitavo Ano": 8,
          "Nono Ano": 9,
        };
        if (niveis[a.level] !== niveis[b.level]) {
          if (niveis[a.level] < niveis[b.level]) return -1;
          return 1;
        }
        if (anos[a.serie] !== anos[b.serie]) {
          if (anos[a.serie] < anos[b.serie]) return -1;
          return 1;
        }
        if (a.class !== b.class) {
          return a.class.localeCompare(b.class, "pt-br", {
            sensitivity: "base",
          });
        }
        return a.name.localeCompare(b.name, "pt-br", { sensitivity: "base" });
      }),
    [rawUsers],
  );
  const Page = ({ slice }) => {
    return slice.map((user) => {
      return (
        <div className="tag-wrapper" key={user.login}>
          <div className="tag">
            <div className="levelGrade">
              {`${user.level.replace("Fundamental", "")}`}
              {` - ${user.serie} - ${user.class}`}
            </div>
            <div className="site">www.clickideia.com.br</div>
            <div className="name">{user.name}</div>
            <div className="credentials">
              <div className="login">
                <h4>Login</h4>
                <p>{user.login}</p>
              </div>
              <div className="password">
                <h4>Senha</h4>
                <p>{user.password}</p>
              </div>
            </div>
            <div className="logo">
              <img
                src="/img/logo.png"
                alt="logo clickideia"
                role="presentation"
              />
            </div>
          </div>
        </div>
      );
    });
  };

  let n = 0;
  let step = 20;
  let page = [];

  while (users.slice(n, n + step).length > 0) {
    const slice = users.slice(n, n + step);
    page.push(
      <div className="page" key={`wrapper${n}`}>
        <Page slice={slice} />
      </div>,
    );
    n = n + step;
  }
  return <>{page}</>;
};

const PrintLogins = ({ schools }) => {
  const selectSchools = schools.map((school) => {
    return { value: school.id, label: school.label };
  });
  const selectUserTypes = [
    { label: "Educadores", value: "teachers" },
    { label: "Alunos", value: "students" },
  ];

  const [schoolId, setSchoolId] = useState();
  const [userType, setUserType] = useState();
  const [users, setUsers] = useState([]);
  const [printState, setPrintState] = useState(0);

  const selectSchoolId = (e) => setSchoolId(e.value);
  const selectuserType = (e) => setUserType(e.value);
  const back = () => setPrintState(0);

  const generateLogins = () => {
    if (schoolId === undefined || userType === undefined) {
      alert("Selecione uma escola e um tipo de usuário");
      return;
    }

    setPrintState(1);
    api.instance.get(`/getUsersLogin/${schoolId}/${userType}`).then((r) => {
      setUsers(r.data);
      setPrintState(2);
    });
  };

  if (printState === 0) {
    return (
      <>
        <p>Escola</p>
        <ClickSelect options={selectSchools} onChange={selectSchoolId} />
        <p>Tipo de usuário</p>
        <ClickSelect options={selectUserTypes} onChange={selectuserType} />
        <br />
        <button onClick={generateLogins}>Gerar logins</button>
      </>
    );
  } else if (printState === 1) {
    return <p>Baixando logins...</p>;
  } else {
    return (
      <>
        <p>
          Foram encontrados <strong>{users.length}</strong> usuários.
        </p>
        <ClickButton
          background="light"
          fill="simple"
          icon="print"
          aria-keyshortcuts="Ctrl+P"
          label="Imprimir"
          onClick={() => window.print()}
        />
        <PrintPortal>
          <LoginsPrintPage users={users} />
        </PrintPortal>
        <button onClick={back}>Voltar</button>
      </>
    );
  }
};

export default PrintLogins;
