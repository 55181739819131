import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { createSelector } from "reselect";

const getUserData = state => state.user;

const mapStateToProps = createSelector([getUserData], user => ({
  user
}));

const Container = ({ user, render: parentRender, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      user && user.school === null ? (
        <Redirect
          to={{ pathname: "/Login", state: { from: props.location } }}
        />
      ) : (
        parentRender && parentRender(props)
      )
    }
  />
);

export default connect(mapStateToProps)(Container);
