export { default as ClickCheckbox } from "./ClickCheckbox";
export { default as ClickCombobox } from "./ClickCombobox";
export { default as ClickDateInput } from "./ClickDateInput";
export { default as ClickDropdown } from "./ClickDropdown";
export { default as ClickFileInput } from "./ClickFileInput";
export { default as ClickImageInput } from "./ClickImageInput";
export { default as ClickMultiselectDropdown } from "./ClickMultiselectDropdown";
export { default as ClickNumberInput } from "./ClickNumberInput";
export { default as ClickRadio } from "./ClickRadio";
export { default as ClickRichText } from "./ClickRichText";
export { default as ClickTextInput } from "./ClickTextInput";
export { default as FormCheckbox } from "./FormCheckbox";
export { default as FormInput } from "./FormInput";
export { default as FormPanel } from "./FormPanel";

export const createEvent = (name, value, event) => {
  if (!event) event = { target: {} };
  return {
    ...event,
    target: {
      ...event.target,
      name,
      value,
    },
  };
};
