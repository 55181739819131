import { List, Record } from "immutable";

const LoginRecord = Record({
  username: "",
  password: "",
  schoolList: null,
  errorMessage: "",
  backTo: "/anosiniciais",
});

const LoginReducer = (state, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      if (action.payload.location.pathname !== "/Login") {
        return new LoginRecord();
      } else {
        if (action.payload.location.state) {
          return state.set("backTo", action.payload.location.state.from);
        } else {
          return state;
        }
      }
    }
    case "user.logout": {
      return new LoginRecord();
    }
    case "login.usernamechanged": {
      return state.set("username", action.payload);
    }
    case "login.passwordchanged": {
      return state.set("password", action.payload);
    }
    case "login.schoolsLoaded": {
      return state.set("schoolList", new List(action.payload));
    }
    case "login.errorMessage": {
      return state.set("errorMessage", action.payload);
    }
    default: {
      return state;
    }
  }
};

LoginReducer.defaultState = LoginRecord;

export default LoginReducer;
