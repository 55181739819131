import search from "components/Search/Search.reducer";
import { connectRouter } from "connected-react-router";
import { Record } from "immutable";
import { combineReducers } from "redux-immutable";
import view from "views/view.reducer";
import user from "./user.reducer";

const RootRecord = (history) =>
  Record({
    router: {
      location: history.location,
      action: history.action,
    },
    user: new user.defaultState(),
    view: new view.defaultState(),
    search: new search.defaultState(),
  });

const coreReducer = (history) =>
  combineReducers(
    {
      router: connectRouter(history),
      user,
      view,
      search,
    },
    RootRecord(history)
  );
export default coreReducer;
