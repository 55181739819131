import ClickButton from "components/ClickButton";
import styles from "./SocialMenu.module.scss";

const SocialMenu = () => {
  const styleProps = {
    size: "small",
    fill: "simple",
    background: "dark",
  };

  const buttons = [
    {
      label: "Facebook",
      icon: "facebook-f",
      link: "https://www.facebook.com/PortalClickideia/",
    },
    {
      label: "Instagram",
      icon: "instagram",
      link: "https://www.instagram.com/portalclickideia/",
    },
    {
      label: "LinkedIn",
      icon: "linkedin",
      link: "https://www.linkedin.com/company/clickideia/",
    },
    {
      label: "YouTube",
      icon: "youtube",
      link: "https://www.youtube.com/user/PortalClickideia?sub_confirmation=1",
    },
  ];

  return (
    <div className={styles.socialMenu}>
      {buttons.map(({ icon, ...buttonProps }, index) => (
        <ClickButton
          key={index}
          {...styleProps}
          {...buttonProps}
          target="_blank"
          icon={["fab", icon]}
          rel="noopener noreferrer"
        />
      ))}
    </div>
  );
};

export default SocialMenu;
