/**
 * @module ClickNumberInput
 */

import classNames from "classnames";
import { applyRef } from "core";
import {
  ErrorMessage as FormikErrorMessage,
  Field as FormikField,
} from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import ClickTooltip from "../ClickTooltip";
import styles from "./ClickNumberInput.module.scss";

/**
	* @description Component used in forms to render number inputs
	* @param {Object} props - All props of this Component
	* @param {string} props.id - DOM id of the number input element. Needed for acessibility.
	* @param {string} props.name - DOM name of the number input element. Needed if using withFormik.
	*
	* @param {string} [props.value] - Current value of the input element for controlled components.
	* @param {function} [props.onChange] - callback called when the user inputs any data.
	* @param {function} [props.onBlur] - callback called when the user focus leaves the input.
	*
	* @param {string} [props.label] - the label that should be rendered with this input
	* @param {string} [props.ariaLabel] - DOM aria-label to be used on the input element.
	* @param {string} [props.placeholder] - placeholder to be shown while the input is empty.
	* @param {string} [props.helpText] - Text with instructions of how to input the data for this input.
	* @param {string} [props.tooltip] - Text shown when the user hovers this element.

	* @param {string} [props.min] - Min value of number input.
	* @param {string} [props.max] - Max value of number input.
	* @param {string} [props.step] - Step size of number input.
	*
	* @param {string} [props.className] - CSS classes for this input container
	* @param {bool} [props.noStyle=false] - If true, renders the input without styling (as a <span>)
	* @param {bool} [props.dark=false] - If true, renders the input with light foreground, to be used in dark backgrounds
	*
	* @param {bool} [props.withFormik=false] - If true, use data and callbacks from formik to control this input.
	* @param {function} [props.validate] - Callback used to validate this form field: val => errorMessage.
	* @memberof module:ClickNumberInput
	*/
const ClickNumberInput = React.forwardRef(
  ({ name, validate, withFormik, ...otherProps }, ref) => {
    let inputElement;
    if (withFormik) {
      inputElement = (
        <div>
          <FormikField name={name} validate={validate}>
            {({ field /*, form */ }) => (
              <RenderInput {...otherProps} ref={ref} {...field} />
            )}
          </FormikField>
          <div className={styles.errorMessage}>
            <FormikErrorMessage name={name} />
          </div>
        </div>
      );
    } else {
      inputElement = <RenderInput name={name} {...otherProps} ref={ref} />;
    }
    return inputElement;
  },
);

const RenderInput = React.forwardRef(
  (
    {
      id,
      name,
      value,
      onChange,
      onBlur,
      onFocus,
      label,
      ariaLabel,
      placeholder,
      helpText,
      tooltip,
      min,
      max,
      step,
      className,
      noStyle,
      dark,
    },
    ref,
  ) => {
    const inputRef = useRef();

    let inputElement = (tooltipRef) => (
      <div className={styles.numberInputWrapper}>
        <input
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type="number"
          placeholder={placeholder}
          aria-label={ariaLabel}
          className={classNames(styles.numberInput, {
            [styles.noStyle]: noStyle === true,
          })}
          aria-describedby={!!helpText ? `${id}-helptext` : undefined}
          ref={(element) => {
            applyRef(element, inputRef);
            applyRef(element, ref);
            applyRef(element, tooltipRef);
          }}
          min={min}
          max={max}
          step={step}
        />
      </div>
    );

    if (!tooltip && !label && !!ariaLabel) {
      tooltip = { content: ariaLabel };
    }

    const inputElementWithTooltip = !!tooltip && (
      <ClickTooltip {...tooltip}>
        {(tooltipRef) => inputElement(tooltipRef)}
      </ClickTooltip>
    );

    return (
      <div
        className={classNames(styles.numberInputContainer, className, {
          [styles.dark]: dark,
        })}
      >
        {!!label && <label htmlFor={id}>{label}</label>}
        {!!helpText && (
          <p id={`${id}-helptext`} className={styles.helpText}>
            {helpText}
          </p>
        )}
        {tooltip ? inputElementWithTooltip : inputElement(undefined)}
      </div>
    );
  },
);

ClickNumberInput.defaultProps = {
  noStyle: false,
  dark: false,
  withFormik: false,
};

ClickNumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.any,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  tooltip: PropTypes.shape({
    placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    content: PropTypes.node,
  }),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  className: PropTypes.string,
  noStyle: PropTypes.bool.isRequired,
  dark: PropTypes.bool.isRequired,

  withFormik: PropTypes.bool.isRequired,
  validate: PropTypes.func,
};

export default ClickNumberInput;
