import XLSX from "xlsx";

/**
 * defaultData
 *
 * schools: string
 * levels: string
 * teacherTypes: string
 * teachers: string
 * teacherSchools: { teacher (id), school (id), levels ([] of ids), type (id) }
 * series: { level (id), label (string) }
 * classes: { serie (id), label (string) }
 * students: string
 * studentClasses: { student (id), school (id), class (id) }
 */
export const defaultData = {
  schools: {},
  levels: {},
  teacherTypes: {},
  teachers: {},
  teacherSchools: {},
  teacherEmails: {},
  series: {},
  classes: {},
  students: {},
  studentClasses: {},
  studentEmails: {},
};

export const defaultMetadata = {
  userType: "all",
  school: "all",
};

/**
 * dictionary
 *
 * Corrige algumas abreviações comuns usadas em cada um dos tipos
 * de dados.
 */
const dictionary = {
  schools: {},
  levels: {
    inf: "Infantil",
    f1: "Fundamental Anos Iniciais",
    f2: "Fundamental Anos Finais",
    m: "Médio",
  },
  teacherTypes: {
    professora: "Professor",
    coordenadora: "Coordenador",
    diretora: "Diretor",
  },
  teachers: {},
  teacherSchools: {},
  series: {
    1: "Primeiro Ano",
    2: "Segundo Ano",
    3: "Terceiro Ano",
    4: "Quarto Ano",
    5: "Quinto Ano",
    6: "Sexto Ano",
    7: "Sétimo Ano",
    8: "Oitavo Ano",
    9: "Nono Ano",
  },
  classes: {},
  students: {},
  studentClasses: {},
};

/**
 * filterValue
 * @param string dataSub
 * @param string | object value
 *
 * Coleta os valores crus e faz as correções iniciais
 * (trim, tradução, ...)
 */
const filterValue = (dataSub, value) => {
  const fixString = (dataSub, string) => {
    const newValue = string
      .trim()
      // Allow characters, numbers, spaces and "-"
      .replace(/[^A-Za-zÀ-ÿ0-9�\s-]/gi, "")
      // Remove double spaces
      .replace(/ +(?= )/g, "");

    const translated = dictionary[dataSub][newValue.toLowerCase()];

    return translated ? translated : newValue;
  };

  if (typeof value === "string") {
    return fixString(dataSub, value);
  } else if (typeof value === "object") {
    Object.keys(value).forEach((key) => {
      if (typeof value[key] === "string")
        value[key] = fixString(dataSub, value[key]);
    });
    return value;
  } else {
    return value;
  }
};

/**
 * validateData
 * @param string dataSub
 * @param string | object value
 *
 * Dá um throw caso o input não seja válido para o dataSub
 */
const validateData = {
  schools: (value) => {
    if (value === "" || typeof value !== "string")
      throw new Error("Há um erro nas escolas da planilha");
  },
  levels: (value) => {
    if (value !== "" && typeof value !== "string")
      throw new Error("Há um erro nos níveis da planilha");
  },
  teacherTypes: (value) => {
    if (value === "" || typeof value !== "string")
      throw new Error("Há um erro na função dos professore na planilha");
  },
  series: (value) => {
    if (value === "") throw new Error("Há um erro nas séries da planilha");
  },
  classes: (value) => {
    if (value === "") throw new Error("Há um erro nas turmas da planilha");
  },
};

/**
 * parseSSData
 * @param array studentsData
 * @param array teachersData
 *
 * Coleta todos os dados das abas "Alunos" e "Educadores" da
 * planilha padrão e retorna um objeto do tipo defaultData com
 * os dados parseados e linkados por ids como em uma base de dados
 */
const parseSSData = (studentsData, teachersData, schoolMetadata) => {
  let data = defaultData;
  let index = {
    schools: 0,
    levels: 0,
    teacherTypes: 0,
    teachers: 0,
    teacherSchools: 0,
    teacherEmails: 0,
    series: 0,
    classes: 0,
    students: 0,
    studentClasses: 0,
    studentEmails: 0,
  };

  const getKey = (obj, match) => {
    if (typeof match === "object" && match !== null)
      return Object.keys(obj).find(
        (key) => JSON.stringify(obj[key]) === JSON.stringify(match),
      );

    return Object.keys(obj).find((key) => obj[key] === match);
  };

  const getDataKey = (dataSub, value, canBeEmpty = false) => {
    if (!value || value === "") {
      if (canBeEmpty) return;

      const error = "Preencha todos os campos da planilha";
      alert(error);
      throw error;
    }

    const filteredValue = filterValue(dataSub, value);
    validateData[dataSub](value);
    const id = getKey(data[dataSub], filteredValue);

    if (!id) {
      data[dataSub][++index[dataSub]] = filteredValue;
      return index[dataSub];
    }

    return parseInt(id);
  };

  studentsData.forEach((user) => {
    const school = schoolMetadata === "all" ? user["Escola"] : schoolMetadata;
    const schoolId = getDataKey("schools", school);
    const levelId = getDataKey("levels", user["Nível"]);
    const serieId = getDataKey("series", {
      level: levelId,
      label: user["Ano"].toString(),
    });
    const classId = getDataKey("classes", {
      school: schoolId,
      serie: serieId,
      label: String(user["Turma"]),
    });

    data.students[++index.students] = filterValue(
      "students",
      user["Nome completo"],
    );

    data.studentClasses[++index.studentClasses] = {
      student: index.students,
      class: classId,
    };

    /**
     * @todo - validar email
     */
    data.studentEmails[++index.studentEmails] = user["E-mail"]
      ? user["E-mail"].trim()
      : null;
  });

  teachersData.forEach((user) => {
    const school = schoolMetadata === "all" ? user["Escola"] : schoolMetadata;
    const schoolId = getDataKey("schools", school);
    const levelId1 = getDataKey("levels", user["Nível"]);
    const levelId2 = getDataKey("levels", user["Nível 2"], true);
    const levelId3 = getDataKey("levels", user["Nível 3"], true);
    const typeId = getDataKey("teacherTypes", user["Cargo"]);

    data.teachers[++index.teachers] = filterValue(
      "teachers",
      user["Nome completo"],
    );

    data.teacherSchools[++index.teacherSchools] = {
      teacher: index.teachers,
      school: schoolId,
      levels: [levelId1, levelId2, levelId3].filter((a) => a),
      type: typeId,
    };

    /**
     * @todo - validar email
     */
    data.teacherEmails[++index.teacherEmails] = user["E-mail"]
      ? user["E-mail"].trim()
      : null;
  });

  return data;
};

/**
 * wbFile
 * Usa a biblioteca XLSX para fazer a leitura do arquivo
 */
const wbFile = (bstr, fileType) => {
  return XLSX.read(bstr, {
    type: fileType,
    bookVBA: true,
  });
};

/**
 * getMetadata
 * Disponibiliza os dados parseados da aba "Metadados" da planilha
 */
export const getMetadata = (bstr, fileType) => {
  const wb = wbFile(bstr, fileType);
  const metaDataSheet = wb.Sheets["Metadados"];

  return {
    userType: metaDataSheet["B1"].v,
    school: metaDataSheet["B2"].v,
  };
};

/**
 * getParsedData
 * Disponibiliza os dados parseados das abas "Alunos" e
 * "Educadores" da planilha.
 * "all" : sobe os dois
 * "students" : apenas "Alunos"
 * "teachers" : apenas "Educadores"
 */
export const getParsedData = (bstr, fileType, userType, school) => {
  const wb = wbFile(bstr, fileType);
  const studentsData =
    userType === "all" || userType === "Alunos"
      ? XLSX.utils.sheet_to_json(wb.Sheets["Alunos"])
      : [];
  const teachersData =
    userType === "all" || userType === "Educadores"
      ? XLSX.utils.sheet_to_json(wb.Sheets["Educadores"])
      : [];

  return parseSSData(studentsData, teachersData, school);
};
