import React, { useEffect, useState, useContext } from "react";
import { api } from "core";
import { UserContext } from "views/User.context";

export const UserManagementContext = React.createContext();

const UserManagementProvider = ({ children }) => {
  const { institution, type } = useContext(UserContext);
  const [projects, setProjects] = useState([]);

  const reloadProjects = (onSuccess = () => {}) => {
    if (institution !== null && type !== "Clickideia") {
      api.instance.get("/projects").then(r => {
        setProjects(r.data);
        onSuccess();
      });
    }
  };

  useEffect(() => reloadProjects(), [institution, type]);

  return (
    <UserManagementContext.Provider value={{ projects, reloadProjects }}>
      {children}
    </UserManagementContext.Provider>
  );
};

export default UserManagementProvider;
