import React from "react";
import "./FormCheckbox.scss";

const FormCheckbox = ({ id, toggleSwitch, children, onChange, checked }) => (
  <label htmlFor={id} className="FormCheckbox">
    <input id={id} onChange={onChange} type="checkbox" checked={checked} />
    {!!toggleSwitch && <div className="FormCheckboxSwitch" />}
    {children}
  </label>
);

FormCheckbox.defaultProps = {
  toggleSwitch: false,
  checked: false
};

export default FormCheckbox;
