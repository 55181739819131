import { createContext, useContext, useState, useEffect } from "react";
import api from "core/api";
import { UserContext } from "contexts/User.context";

export const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const { school } = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);

  useEffect(
    // fetch notifications
    () =>
      !!school &&
      api
        .getUserNotifications(school)
        .then((res) => setNotifications(res.data))
        .catch((error) => console.error(error)),
    [school],
  );

  return (
    <NotificationsContext.Provider value={notifications}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error(
      "useNotifications must be used within NotificationsProvider",
    );
  }

  return context;
};

export default NotificationsProvider;
