import ClickModal from "components/ClickModal";
import { AuthContext } from "contexts/Auth.context.js";
import React, { useContext } from "react";

const LogoutModal = ({ setIsModalOpen }) => {
  const { logout } = useContext(AuthContext);

  return (
    <ClickModal
      buttons={[
        {
          children: "Cancelar",
          fill: "ghost",
          onClick: () => setIsModalOpen(false),
        },
        {
          children: "Sair",
          onClick: () => {
            setIsModalOpen(false);
            logout();
          },
        },
      ]}
      onClose={() => setIsModalOpen(false)}
    >
      Deseja realmente sair? Não será possível acessar as funcionalidades
      offline deslogado.
    </ClickModal>
  );
};

export default LogoutModal;
