import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import View from "./Login.view";

import * as actions from "./Login.actions";

const getUserData = state => state.user;
const getUiData = state => state.view.login;

const mapStateToProps = createSelector(
  [getUserData, getUiData],
  (user, ui) => ({
    selectedSchool: user.school,
    username: ui.username,
    password: ui.password,
    schoolList: ui.schoolList !== null && ui.schoolList.toJS(),
    errorMessage: ui.errorMessage
  })
);

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(View);
