import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ClickLink from "components/ClickLink";
import { useMenuButton, useMenuList } from "../../hooks";
import styles from "./Submenu.module.scss";

const SubmenuItem = ({ itemProps, submenuProps }) => {
  const { label, link, onClick, submenu, index } = itemProps;
  const { selectedItemIndex, setSelectedItemIndex, className } =
    submenuProps || {};
  const { buttonProps, popperProps } = useMenuButton(
    index,
    selectedItemIndex,
    setSelectedItemIndex,
    "right-end",
    true,
  );

  return (
    <li key={index} role="none">
      {link ? (
        <ClickLink link={link}>{label}</ClickLink>
      ) : onClick ? (
        <button type="button" onClick={onClick}>
          {label}
        </button>
      ) : (
        !!submenu && (
          <>
            <button {...buttonProps}>
              <div>{label}</div>
              <div className={styles.icon}>
                <FontAwesomeIcon icon="chevron-right" />
              </div>
            </button>
            <Submenu
              {...{
                ...popperProps,
                isSelected: selectedItemIndex === index,
                items: submenu,
                label,
                className,
              }}
            />
          </>
        )
      )}
    </li>
  );
};

const Submenu = ({
  setPopperElement,
  setArrowElement,
  popperStyles,
  attributes,
  items,
  label,
  isSelected,
  children,
  className,
}) => {
  const { listProps, selectedItemIndex, setSelectedItemIndex } = useMenuList({
    label,
  });

  return (
    <div
      ref={setPopperElement}
      style={popperStyles.popper}
      className={classnames("fadeIn", styles.submenu, className, {
        [styles.hidden]: !isSelected,
        animated: isSelected,
      })}
      {...attributes.popper}
    >
      <div
        ref={setArrowElement}
        className={styles.submenuArrow}
        style={popperStyles.arrow}
      />
      {children}
      <ul {...listProps}>
        {items.map((item, index) => (
          <SubmenuItem
            key={index}
            itemProps={{ ...item, index }}
            submenuProps={
              !item.submenu
                ? undefined
                : { selectedItemIndex, setSelectedItemIndex, className }
            }
          />
        ))}
      </ul>
    </div>
  );
};

export default Submenu;
