import { Record } from "immutable";

const SearchRecord = Record({
  lastPath: {},
  searchStatus: "none",
  totalPages: null,
  totalResults: null,
  filterToApply: {},
  filterExpanded: false,
  data: null
});

/** Reducer for Search component.
 * Search format:{
 *   searchStatus: "none" || "loading" || "success" || "failed",
 *   totalPages: 10,
 *   totalResults: 158,
 *   data:[{prop: value,...}]
 * }
 */
const SearchReducer = (state, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      if (
        state.lastPath.pathname !== action.payload.pathname ||
        state.lastPath.search !== action.payload.search
      )
        return new SearchRecord({ lastPath: action.payload });
      else return state;
    case "search.start":
      return state.merge({
        searchStatus: "loading"
      });
    case "search.success":
      const data = new Map();
      for (const entry of action.payload.data) {
        entry.deleteStatus = "none";
        data.set(entry.id, entry);
      }
      return state.merge({
        searchStatus: "success",
        totalPages: action.payload.totalPages,
        totalResults: action.payload.totalResults,
        data
      });
    case "search.error":
      return state.merge({
        searchStatus: "failed"
      });
    case "search.filterExpand":
      return state.set("filterExpanded", action.payload);
    case "search.changeFilterToApply":
      return state.set("filterToApply", {
        ...state.filterToApply,
        ...action.payload
      });
    case "search.clearFilters":
      return state.set("filterToApply", {});

    case "search.deleting":
      return state.set(
        "data",
        new Map(
          state.data.set(action.payload, {
            ...state.data.get(action.payload),
            deleteStatus: "deleting"
          })
        )
      );
    case "search.deleted":
      return state.set(
        "data",
        new Map(
          state.data.set(action.payload, {
            ...state.data.get(action.payload),
            deleteStatus: "deleted"
          })
        )
      );
    case "search.cancelDelete":
      return state.set(
        "data",
        new Map(
          state.data.set(action.payload, {
            ...state.data.get(action.payload),
            deleteStatus: "none"
          })
        )
      );

    case "search.recovering":
      return state.set(
        "data",
        new Map(
          state.data.set(action.payload, {
            ...state.data.get(action.payload),
            deleteStatus: "recovering"
          })
        )
      );
    case "search.recovered":
      return state.set(
        "data",
        new Map(
          state.data.set(action.payload, {
            ...state.data.get(action.payload),
            deleteStatus: "recovered"
          })
        )
      );
    case "search.cancelRecover":
      return state.set(
        "data",
        new Map(
          state.data.set(action.payload, {
            ...state.data.get(action.payload),
            deleteStatus: "deleted"
          })
        )
      );

    default:
      return state;
  }
};

SearchReducer.defaultState = SearchRecord;

export default SearchReducer;
