import classnames from "classnames";
import ClickContainer from "components/ClickContainer";
import { ThemeContext } from "contexts/Theme.context";
import { useContext } from "react";
import styles from "./Menu.module.scss";
import Navigation from "./Navigation";
import SearchBar from "./SearchBar";
import SocialMenu from "./SocialMenu";
import UserMenu from "./UserMenu";
import NotificationsMenu from "./NotificationsMenu";

const Menu = () => {
  const { collapsed } = useContext(ThemeContext);

  return (
    <ClickContainer className={styles.menuWrapper}>
      <section
        className={classnames(styles.menu, { [styles.collapsed]: collapsed })}
      >
        <SocialMenu />
        <SearchBar />
        <NotificationsMenu />
        <UserMenu />
        <Navigation />
      </section>
    </ClickContainer>
  );
};

export default Menu;
