/**
 * @module ClickRadio
 */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ClickRadio.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "focus-visible";
import ClickTooltip from "components/ClickTooltip";
import {
  Field as FormikField,
  ErrorMessage as FormikErrorMessage
} from "formik";

/**
 * @description Component used in forms to render set of radios options
 * @param {Object} props - All props of this Component
 * @param {string} props.id - Id elemment fo the fieldSet.
 * @param {string} props.name - DOM name for group the options.
 * @param {string} [props.label] - the label that should be rendered for the fieldset
 * @param {string} [props.ariaLabel] - the aria-label that labels the fieldset
 * @param {string} [props.className] - CSS classes for the fieldset
 * @param {string} [props.helpText] - Text with instructions of how to input the data for this input.
 * @param {bool} [props.isButton=false] - Defines which rendered style is chosen: button or standard radio options
 * @param {string} [props.selected] - Defines which option is selected
 * @param {bool} [props.dark=false] - If true, renders the input with light foreground, to be used in dark backgrounds
 * @param {bool} [props.withFormik=false] - If true, use data and callbacks from formik to control this input.
 *
 * @param {function} [props.onChange] - callback called when the user inputs any data.
 * @param {function} [props.onBlur] - callback called when the user focus leaves the input.
 *
 * @param {Array.<Object>} [props.options] - array of options props
 * @param {strin} [props.options.label] - Text whic labels an option.
 * @param {string|number} [props.options.value] - Value of an option.
 * @param {string} [props.options.icon] - String which defines a fontAwesome to be rendered.
 * @param {string} [props.options.ariaLabel] - aria-label value for each option.
 * @param {Object} [props.options.tooltip] - ClickTooltip component props.
 *
 * @param {function} [props.validate] - Callback used to validate this form field: val => errorMessage.
 * @memberof module:ClickRadio
 */

const ClickRadio = forwardRef(
  ({ name, withFormik, onChange, ...otherProps }, ref) => {
    if (!withFormik) {
      return (
        <RenderClickRadio
          name={name}
          onChange={onChange}
          {...otherProps}
          ref={ref}
        />
      );
    }
    return (
      <div>
        <FormikField
          name={name}
          render={({ field /* form */ }) => {
            return (
              <RenderClickRadio
                {...otherProps}
                ref={ref}
                {...field}
                selected={field.value}
                onChange={(evt) => {
                  field.onChange(evt);
                  field.onBlur(evt);
                  onChange && onChange(evt);
                }}
              />
            );
          }}
        />
        <div className={styles.errorMessage}>
          <FormikErrorMessage name={name} />
        </div>
      </div>
    );
  }
);
const OptionLabel = forwardRef(
  ({ icon, label, ariaLabel, labelFor, ...props }, ref) => {
    return !!icon ? (
      <label
        htmlFor={labelFor}
        aria-label={ariaLabel}
        className={classnames(styles.icon)}
        {...props}
        ref={ref}
      >
        <FontAwesomeIcon icon={icon} />
      </label>
    ) : (
      <label htmlFor={labelFor} aria-label={ariaLabel} {...props} ref={ref}>
        {label}
      </label>
    );
  }
);
const RenderClickRadio = forwardRef(
  (
    {
      id,
      name,
      label,
      selected,
      defaultSelected,
      ariaLabel,
      options,
      onChange,
      onBlur,
      isButton,
      className,
      helpText,
      dark
    },
    ref
  ) => (
    <fieldset
      id={id}
      className={classnames(
        styles.radioGroup,
        { [styles.isButton]: isButton },
        { [styles.dark]: dark },
        className
      )}
      aria-label={ariaLabel}
      ref={ref}
    >
      {!!label && <legend>{label}</legend>}
      {!!helpText && (
        <p id={`${id}-helptext`} className={classnames(styles.helpText)}>
          {helpText}
        </p>
      )}
      <div className={styles.inputListWrap}>
        {options.map(({ label, value, icon, tooltip, ariaLabel }, i) => (
          <div
            key={`${id}-${i}-option`}
            className={classnames(styles.inputLabel)}
          >
            <input
              id={`${id}-${i}-option`}
              key={`${id}-${i}-option`}
              type="radio"
              name={name}
              value={value}
              checked={selected === undefined ? undefined : value === selected}
              defaultChecked={
                defaultSelected === undefined
                  ? undefined
                  : value === defaultSelected
              }
              onChange={onChange}
              onBlur={onBlur}
            />
            {/* Tooltip conditional render */}
            {!!tooltip || !!icon ? (
              <ClickTooltip
                content={ariaLabel}
                placement={!isButton ? "right" : undefined}
                {...tooltip}
              >
                {(tooltipRef) => (
                  <OptionLabel
                    label={label}
                    icon={icon}
                    ariaLabel={ariaLabel}
                    labelFor={`${id}-${i}-option`}
                    key={`${id}-${i}-label`}
                    ref={tooltipRef}
                  />
                )}
              </ClickTooltip>
            ) : (
              <OptionLabel
                label={label}
                icon={icon}
                ariaLabel={ariaLabel}
                labelFor={`${id}-${i}-option`}
                key={`${id}-${i}-label`}
              />
            )}
          </div>
        ))}
      </div>
    </fieldset>
  )
);

ClickRadio.defaultProps = {
  isButton: false,
  dark: false,
  withFormik: false
};

ClickRadio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dark: PropTypes.bool.isRequired,
  isButton: PropTypes.bool,
  className: PropTypes.string,
  helpText: PropTypes.string,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: iconType,
      ariaLabel: ariaLabelType,
      tooltip: PropTypes.shape({
        placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
        content: PropTypes.node
      })
    })
  ).isRequired
};

/* Custom PropTypes validators */
function iconType(props, _, componentName) {
  if (!props.label && !props.icon) {
    return new Error(
      `Missing prop. The ${componentName}'s option must have either a label or a icon`
    );
  } else if (!!props.label && !!props.icon) {
    return new Error(
      `Missing prop. The ${componentName}'s option cannot have either a label and a icon at same time`
    );
  } else if (!!props.icon && typeof props.icon !== "string")
    return new Error(
      `Invalid prop value. The ${componentName}'s option icon must be string`
    );
  return null;
}
function ariaLabelType(props, _, componentName) {
  if (!props.label && !props.ariaLabel)
    return new Error(
      `Missing prop. The ${componentName}'s option needs either label, ariaLabel`
    );
  else if (!!props.ariaLabel && typeof props.ariaLabel !== "string")
    return new Error(
      `Invalid prop value. The ${componentName}'s option ariaLabel must be string`
    );
  return null;
}

export default ClickRadio;
