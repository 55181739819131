import ClickButton, { ClickButtonGroup } from "components/ClickButton";
import ClickHeading from "components/ClickHeading";
import { api } from "core";
import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "views/Breadcrumb.context";
import { UserManagementContext } from "views/UserManagement/UserManagement.context";
import BulkTemplate from "./BulkTemplate/";
import ProjectBulkUpdate from "./BulkUpload/ProjectBulkUpdate";
import ProjectBulkUpdateProvider from "./BulkUpload/ProjectBulkUpdate.context";
import PrintLogins from "./PrintLogins/";

const PrintLoginsAction = ({ schools, visible }) => {
  return (
    <div style={visible ? { display: "block" } : { display: "none" }}>
      <hr />
      <h3>Baixar senhas</h3>
      <PrintLogins schools={schools} />
      <hr />
    </div>
  );
};

const BulkTemplateAction = ({ projectId, schools, visible }) => {
  return (
    <div style={visible ? { display: "block" } : { display: "none" }}>
      <hr />
      <h3>Baixar modelo do arquivo</h3>
      <BulkTemplate projectId={projectId} schools={schools} />
      <hr />
    </div>
  );
};

const Project = (props) => {
  const projects = useContext(UserManagementContext).projects.reduce(
    // Transform an array into an object with id as its index
    (obj, cur) => ({ ...obj, [cur.id]: cur }),
    {},
  );
  const projectId = props.projectId;
  const project = projects[projectId];
  const projectName = project === undefined ? "Carregando..." : project.nome;

  const [schools, setSchools] = useState([]);
  const [fetchSchools, setFetchSchools] = useState(false);
  const [printLoginsVisible, setPrintLoginsVisible] = useState(false);
  const [bulkTemplateVisible, setBulkTemplateVisible] = useState(false);

  useEffect(() => {
    if (!fetchSchools) {
      setFetchSchools(false);
      api.instance.get(`/project/${projectId}/schools`).then((r) => {
        setSchools(r.data);
      });
    }
  }, [fetchSchools, projectId]);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  useEffect(() => {
    setBreadcrumb([
      {
        label: "Gestão de Usuários",
        url: "/GestaoDeUsuarios",
      },
      {
        label: "Projetos",
        url: "/GestaoDeUsuarios/Projetos",
      },
      {
        label: projectName,
        url: `/GestaoDeUsuarios/Projetos/${projectId}`,
        current: true,
      },
    ]);
  }, [projectId, projectName, setBreadcrumb]);

  const toggleLogin = () => {
    setPrintLoginsVisible(!printLoginsVisible);
    setBulkTemplateVisible(false);
  };
  const toggleTemplate = () => {
    setPrintLoginsVisible(false);
    setBulkTemplateVisible(!bulkTemplateVisible);
  };

  return (
    <>
      <ClickHeading tag="h2">
        Gestão de <span>Usuários</span>
      </ClickHeading>
      <h1>{projectName}</h1>

      <ClickButtonGroup>
        <ClickButton fill="ghost" icon="key" onClick={toggleLogin}>
          Baixar senhas
        </ClickButton>
        <ClickButton icon={["fas", "file-excel"]} onClick={toggleTemplate}>
          Baixar modelo
        </ClickButton>
      </ClickButtonGroup>

      <PrintLoginsAction schools={schools} visible={printLoginsVisible} />
      <BulkTemplateAction
        projectId={projectId}
        schools={schools}
        visible={bulkTemplateVisible}
      />

      <ProjectBulkUpdateProvider projectId={projectId}>
        <ProjectBulkUpdate />
      </ProjectBulkUpdateProvider>
    </>
  );
};

export default Project;
