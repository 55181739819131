import React, { useState, useContext } from "react";
import { api } from "core";
import { UserManagementContext } from "views/UserManagement/UserManagement.context";

const DefaultLogins = ({ credentials }) => {
  if (!credentials) return "";

  return (
    <div
      style={{
        border: "2px solid #005a8f",
        borderRadius: "4px",
        padding: "12px",
        marginTop: "20px"
      }}
    >
      <p>
        <strong>Logins criados automaticamente:</strong>
      </p>
      <p>Anote esses logins pois eles não serão mostrados novamente</p>
      <br />
      <table border="1">
        <thead>
          <tr>
            <th>Login</th>
            <th>Senha</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(credentials).map(key => (
            <tr key={key}>
              <td>{credentials[key].username}</td>
              <td>{credentials[key].password}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Form = ({ visible }) => {
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [projectId, setProjectId] = useState();
  const [credentials, setCredentials] = useState();
  const [creating, setCreating] = useState(false);
  const { reloadProjects } = useContext(UserManagementContext);

  const changeName = e => setName(e.target.value);
  const changeCnpj = e => setCnpj(e.target.value);

  if (!visible) return "";

  const create = () => {
    if (!name || !cnpj) return;

    const confirm = window.confirm(`Você deseja criar a instituição ${name}?`);
    if (!confirm) return;

    setCreating(true);

    api.instance
      .post(`project`, { name, cnpj })
      .then(r => {
        reloadProjects();
        setProjectId(r.data.projectId);
        setCredentials(r.data.usersCredentials);
      })
      .catch(err => {
        alert(
          `Ocorreu um erro ao criar o novo projeto. Entre em contato com a TI. ${err}`
        );
        setCreating(false);
      });
  };

  if (projectId) return <DefaultLogins credentials={credentials} />;

  return (
    <>
      <hr />
      {creating ? (
        "O projeto está sendo criado..."
      ) : (
        <>
          <h3>Criar novo projeto</h3>
          <br />
          <p>Nome do projeto</p>
          <input value={name} onChange={changeName} />
          <p>CNPJ</p>
          <input value={cnpj} onChange={changeCnpj} />
          <br />
          <br />
          <button onClick={create}>Criar</button>
        </>
      )}
      <hr />
    </>
  );
};

export default Form;
