import { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(null);
  const [mainBackground, setMainBackground] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [contextValue, setContextValue] = useState({});

  useEffect(() => {
    setContextValue({
      theme,
      setTheme,
      mainBackground,
      setMainBackground,
      collapsed,
      setCollapsed,
    });
  }, [collapsed, mainBackground, theme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
