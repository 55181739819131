import worker from "workerize-loader!workers/fuzzy"; // eslint-disable-line import/no-webpack-loader-syntax

const fuzzysearch = (ssSet, compareSet, skipSameId) => {
  const fuzzyWorker = worker();
  return [
    fuzzyWorker.run(ssSet, compareSet, skipSameId),
    () => {
      fuzzyWorker.terminate();
    }
  ];
};

export default fuzzysearch;
