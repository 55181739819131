import React, { createContext, useCallback, useEffect, useState } from "react";
import { useMedia } from "react-use";

export const A11yContext = createContext();

const A11yProvider = ({ children }) => {
  const [values, setValues] = useState({
    nightMode: false,
    reducedMotion: false,
  });
  const [contextValue, setContextValue] = useState({
    toggleNightMode: () => {},
    toggleReducedMotion: () => {},
  });

  const prefersDarkColorscheme = useMedia("(prefers-color-scheme: dark)");
  const prefersReducedMotion = useMedia(
    "screen and (prefers-reduced-motion: reduce)",
  );

  const toggleNightMode = useCallback(() => {
    const value = !JSON.parse(
      localStorage.nightMode || prefersDarkColorscheme.toString(),
    );
    localStorage.nightMode = value;
    setValues((values) => ({ ...values, nightMode: value }));
  }, [prefersDarkColorscheme]);

  const toggleReducedMotion = useCallback(() => {
    const value = !JSON.parse(
      localStorage.reducedMotion || prefersReducedMotion.toString(),
    );
    localStorage.reducedMotion = value;
    setValues((values) => ({ ...values, reducedMotion: value }));
  }, [prefersReducedMotion]);

  useEffect(() => {
    const updateValues = () => {
      setValues({
        nightMode: JSON.parse(
          localStorage.nightMode || prefersDarkColorscheme.toString(),
        ),
        reducedMotion: JSON.parse(
          localStorage.reducedMotion || prefersReducedMotion.toString(),
        ),
      });
    };
    updateValues();
    window.addEventListener("storage", updateValues);
    return () => window.removeEventListener("storage", updateValues);
  }, [prefersDarkColorscheme, prefersReducedMotion]);

  useEffect(() => {
    setContextValue({
      ...values,
      toggleNightMode,
      toggleReducedMotion,
    });
  }, [values, toggleNightMode, toggleReducedMotion]);

  return (
    <A11yContext.Provider value={contextValue}>{children}</A11yContext.Provider>
  );
};

export default A11yProvider;
