import ClickButton from "components/ClickButton";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Menubar from "./Menubar";
import styles from "./Navigation.module.scss";

const Navigation = () => {
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [location]);

  return (
    <nav className={styles.navigation}>
      <ClickButton
        className={styles.toggleNavigation}
        fill="simple"
        background="dark"
        size="big"
        label={`${isExpanded ? "Esconder" : "Mostrar"} menu`}
        tooltip={{ placement: "right" }}
        icon={isExpanded ? "times" : "bars"}
        aria-expanded={isExpanded}
        aria-controls="menubar"
        onClick={() => setIsExpanded((expanded) => !expanded)}
      />
      <Menubar />
    </nav>
  );
};

export default Navigation;
