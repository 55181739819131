import React, { useContext } from "react";
import { ProjectBulkUpdateContext } from "./ProjectBulkUpdate.context";
import FileUploader from "./FileUploader";
import ProjectBulkUpdateWizard from "./ProjectBulkUpdateWizard";

const ProjectBulkUpdate = () => {
  const { dataIsLoaded, safeMode, toggleSafeMode } = useContext(
    ProjectBulkUpdateContext
  );
  const confirmToggleSafeMode = () => {
    const safeModeWarning =
      "Ao desativar o modo seguro, as mudanças serão feitas no banco de dados. Confirma?";
    if (safeMode && window.confirm(safeModeWarning)) {
      toggleSafeMode();
    }
  };

  return dataIsLoaded ? (
    <ProjectBulkUpdateWizard />
  ) : (
    <>
      <div>
        <h3>Subir arquivo para atualizar usuários em massa</h3>
        {safeMode ? (
          <span style={{ color: "green" }}>
            <strong>Seguro</strong> - as alterações não serão feitas no banco de
            dados
          </span>
        ) : (
          <span style={{ color: "red" }}>
            <strong>Cuidado!</strong> - as alterações serão feitas no banco de
            dados
          </span>
        )}
        {" - "}
        <button onClick={confirmToggleSafeMode}>
          {safeMode ? "Desativar" : "Ativar"}
        </button>
      </div>
      <br />
      <FileUploader />
    </>
  );
};

export default ProjectBulkUpdate;
