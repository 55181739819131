import { List, Record } from "immutable";

const UserRecord = Record({
  id: null,
  name: null,
  type: null,
  role: null,
  oldLevel: null,
  institution: null,
  picture: null,
  permissions: null,
  notifications: null,
  school: null,
  schoolTeachers: null,
  token: null,
  refresh_token: null,
  refreshIntervalId: null,
  defaultSubjects: new List(),
  isLoading: true,
});

const compileSubjects = (subjects) => {
  const ret = {};
  for (const level of subjects) {
    for (const subject of level.subjects) {
      ret[subject.abbreviation.toLowerCase()] = {
        value: subject.abbreviation.toLowerCase(),
        label: subject.label,
        id: subject.id,
      };
    }
  }
  return Object.values(ret);
};

const UserReducer = (state, action) => {
  switch (action.type) {
    case "user.isLoading":
      return state.set("isLoading", true);
    case "user.infoLoaded":
      return state.withMutations((mutable) =>
        mutable
          .set("id", action.payload.user.id)
          .set("name", action.payload.user.name)
          .set("type", action.payload.user.type)
          .set("oldLevel", action.payload.user.oldLevel)
          .set("institution", action.payload.user.institution)
          .set("picture", action.payload.user.picture)
          .set("permissions", new List(action.payload.permissions))
          .set("notifications", new List(action.payload.notifications))
          .set("isLoading", false),
      );
    case "user.tokenAcquired":
      return state
        .set("token", action.payload.access_token)
        .set("refresh_token", action.payload.refresh_token)
        .set("refreshIntervalId", action.payload.intervalId);
    case "user.schoolSelected":
      localStorage.setItem("user.school", action.payload.school);
      return state.set("school", action.payload.school);
    case "user.logout":
      return new UserRecord();
    case "user.defaultSubjectsLoaded":
      return state.set(
        "defaultSubjects",
        new List(compileSubjects(action.payload)),
      );
    case "user.schoolTeachers.success":
      return state.set("schoolTeachers", action.payload);
    default:
      return state;
  }
};

UserReducer.defaultState = UserRecord;

export default UserReducer;
