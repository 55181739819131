import { api } from "core";
import { replace } from "connected-react-router";
import {
  tokenAcquired,
  selectSchool as selectUserSchool
} from "core/user.actions";
import { FeedbackContext } from "components/Feedback";

export const typeUsername = username => ({
  type: "login.usernamechanged",
  payload: username
});
export const typePassword = password => ({
  type: "login.passwordchanged",
  payload: password
});

export const login = (username, password) => dispatch => {
  dispatch({
    type: "login.errorMessage",
    payload: ""
  });
  if (
    !username ||
    username.trim() === "" ||
    !password ||
    password.trim() === ""
  ) {
    FeedbackContext.addFeedback(
      `Preencha os campos de usuário e senha.`,
      "error"
    );
    return dispatch({
      type: "login.errorMessage",
      payload: "Preencha os campos de usuário e senha."
    });
  }
  return api
    .login(username, password)
    .then(loginResponse => {
      dispatch(
        tokenAcquired(
          loginResponse.data.access_token,
          loginResponse.data.refresh_token
        )
      );
      return api.getSchoolList().then(response => {
        // TODO: when this user has no school?
        if (response.data.length === 1) {
          dispatch(selectSchool(response.data[0].id));
        } else {
          dispatch({
            type: "login.schoolsLoaded",
            payload: response.data
          });
        }
      });
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        FeedbackContext.addFeedback(`Login ou senha inválidos.`, "error");
        return dispatch({
          type: "login.errorMessage",
          payload: "Login ou senha inválidos."
        });
      }
    });
};

export const selectSchool = schoolId => (dispatch, getState) => {
  dispatch(selectUserSchool(schoolId));
  dispatch(replace(getState().view.login.backTo));
};
