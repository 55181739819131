import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbContext } from "views/Breadcrumb.context";
import { UserManagementContext } from "views/UserManagement/UserManagement.context";
import CreateProject from "./CreateProject/";
import DeactivateProject from "./DeactivateProject/";

const ProjectsList = ({ projects }) => {
  return projects.map((project) => {
    const href = `/GestaoDeUsuarios/Projetos/${project.id}`;
    const desc = project.nome === undefined ? "Carregando..." : project.nome;

    return (
      <tr key={project.id}>
        <td>
          <Link to={href} style={{ textDecoration: "underline" }}>
            {desc}
          </Link>
        </td>
        <td>{project.educadores.toLocaleString("pt-BR")}</td>
        <td>{project.funcionarios.toLocaleString("pt-BR")}</td>
        <td>{project.alunos.toLocaleString("pt-BR")}</td>
        <td>
          <DeactivateProject project={project} />
        </td>
      </tr>
    );
  });
};

const Projects = () => {
  const { projects } = useContext(UserManagementContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      return;
    }

    setBreadcrumb([
      {
        label: "Gestão de Usuários",
        url: "/GestaoDeUsuarios",
      },
      {
        label: "Projetos",
        url: "/GestaoDeUsuarios/Projetos",
        current: true,
      },
    ]);
  }, [loaded, setBreadcrumb]);

  return (
    <>
      <h1 className="title">Gestão de projetos</h1>
      <CreateProject />
      <br />
      <br />
      {projects.length === 0 ? (
        <p>Carregando...</p>
      ) : (
        <table border="1">
          <thead>
            <tr>
              <th>Nome do projeto</th>
              <th>Educadores</th>
              <th>Funcionários</th>
              <th>Alunos</th>
              <th>Desativar projeto</th>
            </tr>
          </thead>
          <tbody>
            <ProjectsList projects={projects} />
          </tbody>
        </table>
      )}
    </>
  );
};

export default Projects;
