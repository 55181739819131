import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { useNetworkState } from "react-use";
import styles from "./SystemStatus.module.scss";

const SystemStatus = () => {
  const { online } = useNetworkState();
  const [status, setStatus] = useState(online ? "online" : "reconnecting");

  useEffect(() => {
    let reconnectionTimeout = false;
    if (status === "reconnecting") {
      reconnectionTimeout = setTimeout(
        () => setStatus(navigator.onLine ? "backonline" : "offline"),
        5000,
      );
    } else if (status === "backonline") {
      reconnectionTimeout = setTimeout(() => setStatus("online"), 5000);
    }
    return () => reconnectionTimeout && clearTimeout(reconnectionTimeout);
  }, [status]);

  useEffect(() => {
    if (online && status !== "online") {
      setStatus("backonline");
    } else if (!online && status !== "offline") {
      setStatus("reconnecting");
    }
  }, [online, status]);

  let element;

  switch (status) {
    case "reconnecting": {
      element = (
        <>
          <FontAwesomeIcon icon="spinner" spin />
          <span>Tentando reconexão...</span>
        </>
      );
      break;
    }
    case "offline": {
      element = (
        <>
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon="wifi" style={{ opacity: "0.5" }} />
            <FontAwesomeIcon icon="ban" transform="shrink-6 down-4 right-4" />
          </span>
          <span>
            Você está offline. Alguns recursos podem não funcionar corretamente.
          </span>
        </>
      );
      break;
    }
    case "backonline": {
      element = (
        <>
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon="wifi" style={{ opacity: "0.5" }} />
            <FontAwesomeIcon icon="check" transform="shrink-6 down-4 right-3" />
          </span>
          <span>Reconectado com sucesso!</span>
        </>
      );
      break;
    }
    default: {
      element = null;
    }
  }

  return (
    status !== "online" && (
      <div className={styles.systemStatusWrap}>
        <div
          className={classnames(styles.systemStatus, {
            [styles.danger]: status === "offline",
            [styles.success]: status === "backonline",
          })}
        >
          {element}
        </div>
        <div
          className={classnames(styles.systemStatus, {
            [styles.danger]: status === "offline",
            [styles.success]: status === "backonline",
          })}
        >
          {element}
        </div>
      </div>
    )
  );
};

export default SystemStatus;
