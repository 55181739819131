/**
 * @module ClickTagColor
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./ClickTagColor.module.scss";

/**
 * @description Colored tag that represents a subject in Portal, but may be used as a coloured tag
 * @param {Object} props
 * @param {node} props.children
 * @param {string} props.color
 * @param {string} props.colorClass
 * @param {string} props.title
 * @memberof module:ClickTagColor
 */
const ClickTagColor = ({ children, color, colorClass, title }) => (
  <span
    className={classNames(styles.tagcolor, styles[colorClass])}
    style={color && { background: color }}
    title={title}
  >
    {children}
  </span>
);

ClickTagColor.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  colorClass: PropTypes.string,
  title: PropTypes.string
};

export default ClickTagColor;
