import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClickButton from "components/ClickButton";
import ClickContainer from "components/ClickContainer";
import { ClickCheckbox } from "components/Form";
import { A11yContext } from "contexts/A11y.context";
import { useContext } from "react";
import { useMenuButton, useMenuList } from "../../hooks";
import styles from "./A11yMenu.module.scss";

const A11yModal = ({ popperProps }) => {
  const {
    nightMode,
    toggleNightMode,
    reducedMotion,
    toggleReducedMotion,
  } = useContext(A11yContext);
  const { setPopperElement, popperStyles, attributes } = popperProps;

  const buttons = [
    {
      id: "theme",
      label: "Tema escuro",
      action: toggleNightMode,
      initialState: () => nightMode,
    },
    {
      id: "reducedMotion",
      label: "Reduzir animações",
      action: toggleReducedMotion,
      initialState: () => reducedMotion,
    },
  ];

  return (
    <ul
      role="menu"
      aria-label="Opções de acessibilidade"
      className={styles.a11yModal}
      ref={setPopperElement}
      style={popperStyles.popper}
      {...attributes.popper}
    >
      {buttons.map(({ id, label, action, initialState }) => (
        <li role="menuitem" key={id}>
          <ClickCheckbox
            isSwitch
            dark
            name={id}
            id={id}
            ariaLabel={label}
            label={label}
            className={styles.checkbox}
            onChange={action}
            defaultChecked={initialState()}
          />
        </li>
      ))}
    </ul>
  );
};

const A11yMenu = () => {
  const { listProps, selectedItemIndex, setSelectedItemIndex } = useMenuList({
    label: "Acessibilidade",
  });
  const thisIndex = 1;
  const { buttonProps, popperProps } = useMenuButton(
    thisIndex,
    selectedItemIndex,
    setSelectedItemIndex,
    "bottom-start",
    false,
    [9, 3],
  );
  return (
    <div {...listProps} className={styles.a11yMenu}>
      <ClickContainer>
        <div>
          <ClickButton
            size="small"
            fill="simple"
            background="dark"
            icon="universal-access"
            className={styles.a11yButton}
            {...buttonProps}
          >
            <span>Opções de acessibilidade</span>
            <FontAwesomeIcon
              className={styles.menuIndicator}
              icon="chevron-down"
            />
          </ClickButton>
        </div>
        {selectedItemIndex === thisIndex && (
          <A11yModal popperProps={popperProps} />
        )}
      </ClickContainer>
    </div>
  );
};

export default A11yMenu;
