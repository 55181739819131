import { Field, Form, Formik, FormikActions } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { toast } from "react-toastify";

import styles from "./styles/SendRecoveryEmail.module.scss";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "contexts/Theme.context";
import ClickHeading from "components/ClickHeading/ClickHeading";
import ClickButton from "components/ClickButton/ClickButton";
import { instance } from "core/api";
import { AxiosError } from "axios";
import { history } from "core";
import ClickLink from "components/ClickLink/ClickLink";
import { UserContext } from "contexts/User.context";
import { Redirect } from "react-router-dom";

const RecoveryEmailSchema = z.object({
  email: z
    .string({ required_error: "Este campo é obrigatório" })
    .email("Por favor, insira um E-mail válido"),
  username: z.string({ required_error: "Este campo é obrigatório" }),
});

type RecoveryEmailFormData = z.infer<typeof RecoveryEmailSchema>;

export function SendRecoveryEmail(): JSX.Element {
  const { setMainBackground } = useContext(ThemeContext);
  const [emailHasSent, setEmailHasSent] = useState(false);

  // Handle authenticated user
  const { id } = useContext<{ id: string }>(UserContext);

  useEffect(() => {
    if (id) history.push("/");
  }, [id]);

  useEffect(() => {
    if (setMainBackground) setMainBackground(false);
  }, [setMainBackground]);

  async function handleSubmit(
    data: RecoveryEmailFormData,
    actions: FormikActions<RecoveryEmailFormData>,
  ) {
    /** @todo chamar o endpoint do backend */

    try {
      const response = await instance.post("/password/reset-link", {
        username: data.username,
        email: data.email,
      });

      if (response.status !== 200)
        toast("Algo inexperado aconteceu", {
          type: "warning",
        });

      toast(
        "Você receberá um email com as instruções para redefinir sua senha",
        { type: "success" },
      );

      setEmailHasSent(true);
      // history.push("/redefinir-minha-senha");
    } catch (error: any) {
      if (error.response?.message) {
        toast(error.response.message, {
          type: "error",
        });
        return;
      }

      toast("Não foi possível redefinir sua senha", {
        type: "error",
      });
    }

    actions.setSubmitting(false);
  }

  return (
    <div className={styles.container}>
      {emailHasSent ? (
        <div className={styles.resetPasswordContainer}>
          <ClickHeading tag="h1">
            Redefina sua <span>senha</span>
          </ClickHeading>

          <p style={{ textAlign: "center" }}>
            Você receberá um email com as instruções para redefinir sua senha.
          </p>

          <ClickLink className={styles.loginLink} link="/Login">
            Login
          </ClickLink>
        </div>
      ) : (
        <div className={styles.resetPasswordContainer}>
          <ClickHeading tag="h1">
            Redefina sua <span>senha</span>
          </ClickHeading>

          <span className={styles.instructions}>
            Informe o e-mail associado à sua conta para receber um link de
            redefinição de senha.
          </span>

          <Formik
            initialValues={{ email: "", username: "" }}
            validationSchema={toFormikValidationSchema(RecoveryEmailSchema)}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => {
              return (
                <Form className={styles.form}>
                  <div className={styles.inputGroup}>
                    <label className={styles.inputLabel} htmlFor="username">
                      Login
                    </label>
                    {errors.username && (
                      <span className={styles.error}>{errors.username}</span>
                    )}
                    <Field
                      className={styles.input}
                      name="username"
                      id="username"
                    />
                  </div>

                  <div className={styles.inputGroup}>
                    <label className={styles.inputLabel} htmlFor="email">
                      E-mail
                    </label>
                    {errors.email && (
                      <span className={styles.error}>{errors.email}</span>
                    )}
                    <Field className={styles.input} name="email" id="email" />
                  </div>

                  <ClickButton
                    className={styles.submitButton}
                    color="success"
                    // icon="sign-in-alt"
                    submit={true}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Enviar
                  </ClickButton>

                  <ClickLink className={styles.loginLink} link="/Login">
                    Login
                  </ClickLink>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}
