import { queryToObj } from "core";
import { useEffect } from "react";
const { useLocation } = require("react-router-dom");

const Search = () => {
  const { search } = useLocation();
  const { searchtext } = queryToObj(search);

  useEffect(() => {
    if (searchtext) {
      window.location.replace(
        `${
          process.env.REACT_APP_PORTAL_URL
        }/portal/busca/index.php?t=${encodeURIComponent(searchtext)}`
      );
    }
  }, [searchtext]);

  if (!searchtext) return "Não foi possível realizar a busca";
  return "Carregando...";
};

export default Search;
