import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./ClickButtonGroup.module.scss";

/**
 * @description Wraps n ClickButton components for correct spacing styling between buttons and around itself
 * @param {Object} props - All props of this Component
 * @param {Array<element>} props.children - All children of this Component
 * @param {string} [props.className] - Classes to forward
 * @param {"row" | "column"} [props.direction="row"] - Buttons direction
 * @param {"start" | "end"} [props.align="start"] - Buttons vertical alignment
 * @param {"start" | "center" | "end" | "space-around" | "space-between"} [props.justify=""] - Buttons horizontal alignment
 *
 * @todo Type check children to allow only ClickButton
 * @memberof module:ClickButton
 */
const ClickButtonGroup = ({ children, className, direction, align, justify }) =>
  !!children && (
    <div
      className={classnames(
        className,
        styles.buttonGroup,
        styles[direction],
        styles[`align${align}`],
        styles[`justify${justify}`],
      )}
    >
      {children}
    </div>
  );

ClickButtonGroup.defaultProps = {
  direction: "row",
  align: "start",
};

ClickButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  direction: PropTypes.oneOf(["row", "column"]).isRequired,
  align: PropTypes.oneOf(["start", "end"]).isRequired,
  justify: PropTypes.oneOf([
    "start",
    "end",
    "center",
    "space-around",
    "space-between",
  ]),
};

export default ClickButtonGroup;
